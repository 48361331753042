/* eslint-disable react/no-array-index-key */
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import bytesToSize from 'src/utils/bytesToSize';
import ImageCrop from './ImageCrop';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    outline: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '90px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  uploadButton: {
    marginTop: '5px',
    marginRight: theme.spacing(1)
  }
}));

function FilesDropzone({
  className,
  field,
  header,
  formikProps,
  saveButton,
  customHandleSave,
  setValue = null,
  accept = 'image/*',
  maxSize = 1024 * 1000 * 50,
  isCrop = false,
  maxHeight = 45,
  maxWidth = 200,
  defaultCropHeight = 30,
  defaultCropWidth = 200,
  ...rest
}) {
  const classes = useStyles();
  const [file, setFile] = useState();
  const [imgSrc, setImgSrc] = useState();

  const handleDrop = useCallback(acceptedFile => {
    if (!!file === false) {
      setFile(acceptedFile[0]);
      if (isCrop) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setImgSrc(reader.result?.toString() || '')
        );
        reader.readAsDataURL(acceptedFile[0]);
      } else {
        handleSave(acceptedFile[0]);
      }
    }
  }, []);

  const handleSave = useCallback(
    acceptedFile => {
      if (customHandleSave) customHandleSave(acceptedFile);
      if (formikProps) {
        // eslint-disable-next-line no-unused-expressions
        formikProps?.setFieldValue(field, acceptedFile);
      } else {
        setValue?.(field, acceptedFile);
      }
    },
    [formikProps, setValue]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    rejectedFiles
  } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept,
    maxSize
  });

  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {!!header && header}
      {file && (
        <>
          <PerfectScrollbar
            options={{ suppressScrollX: true }}
            style={{ height: 'unset' }}
          >
            <List className={classes.list}>
              <ListItem>
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText
                  primary={file?.name}
                  primaryTypographyProps={{
                    variant: 'h5',
                    color: 'textPrimary'
                  }}
                  secondary={bytesToSize(file?.size)}
                />
              </ListItem>
            </List>
          </PerfectScrollbar>
        </>
      )}
      <Box display="flex" flexDirection="row" pb={2} gap={2}>
        {file && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.uploadButton}
            onClick={() => {
              setFile(null);
              setImgSrc(null);
              handleSave(null);
            }}
          >
            Remove
          </Button>
        )}
        <div
          className={clsx({
            [classes.dropZone]: true,
            [classes.dragActive]: isDragActive
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Button
            variant="contained"
            color="secondary"
            className={classes.uploadButton}
          >
            Upload
          </Button>
          {isFileTooLarge && (
            <div className="text-danger mt-2">
              {`File is too large: ${bytesToSize(
                rejectedFiles[0].size
              )} Max Size:${bytesToSize(maxSize)}`}
            </div>
          )}
        </div>
      </Box>

      {isCrop && Boolean(imgSrc) && (
        <ImageCrop
          imgSrc={imgSrc}
          handleSave={handleSave}
          aspect={16 / 10}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          defaultCropHeight={defaultCropHeight}
          defaultCropWidth={defaultCropWidth}
        />
      )}
      {!!saveButton && <Divider />}

      {!!saveButton && saveButton}
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
  formikProps: PropTypes.any.isRequired,
  saveButton: PropTypes.any,
  customHandleSave: PropTypes.any,
  isCrop: PropTypes.bool,
  header: PropTypes.string
};

export default FilesDropzone;
