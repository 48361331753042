import { Box, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NProgress from 'nprogress';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}));

function LoadingScreen() {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box width={400} maxWidth={'100%'}>
        <LinearProgress />
      </Box>
    </div>
  );
}

export default LoadingScreen;
