export default {
  sign_up: 'Zarejestruj się',
  create_x_account: 'Utwórz konto {name}.',
  have_an_account: 'Masz już konto?',
  back_to_home: 'Powrót do strony głównej',
  terms: 'warunki',
  back: 'Wstecz',
  disagree: 'Nie zgadzam się',
  i_agree: 'Zgadzam się',
  i_have_read_the: 'Przeczytałem',
  terms_and_conditions: 'Regulamin',
  create_account: 'Utwórz konto',
  general_error: 'Błąd ogólny',
  sign_in: 'Zaloguj się',
  sign_in_on_the_internal_platform: 'Zaloguj się do zarządzania afiliacją',
  create_new_account: 'Utwórz nowe konto',
  forgot_password: 'Zapomniałeś hasła',
  advertisers: 'Reklamodawcy',
  advertiser: 'Reklamodawca',
  home: 'Strona główna',
  dashboard: 'Panel',
  management: 'Zarządzanie',
  export: 'Eksport',
  import: 'Import',
  all_advertisers: 'Wszyscy reklamodawcy',
  new_advertiser: 'Nowy reklamodawca',
  add_advertiser: 'Dodaj reklamodawcę',
  settings_updated_successfully: 'Ustawienia zostały pomyślnie zaktualizowane',
  save_changes: 'Zapisz zmiany',
  save: 'Zapisz',
  change_email: 'Zmień e-mail',
  email_address: 'Adres e-mail',
  confirm_email_address: 'Potwierdź adres e-mail',
  last_name: 'Nazwisko',
  first_name: 'Imię',
  profile: 'Profil',
  change_password: 'Zmień hasło',
  password_confirmation: 'Potwierdzenie hasła',
  password: 'Hasło',
  min_pass_length_message: 'Wpisałeś nieprawidłowe hasło. Użyj 10-20 znaków.',
  password_required: 'Hasło jest wymagane',
  enter_valid_email: 'Musi być poprawny adres e-mail',
  email_required: 'Adres e-mail jest wymagany',
  invalid_password_message: 'Nieprawidłowe hasło, wpisz poprawne hasło',
  invalid_format: 'Nieprawidłowy format, postępuj zgodnie z przykładem',
  invalid_email_message: 'Nieprawidłowy adres e-mail',
  log_in: 'Zaloguj się',
  first_name_validation:
    'Imię może zawierać tylko litery (a-z) i musi mieć od 2 do 20 znaków.',
  last_name_validation:
    'Nazwisko może zawierać tylko litery (a-z) i musi mieć od 2 do 20 znaków.',
  submit: 'Zatwierdź',
  emails_must_match: 'Oba adresy e-mail muszą być takie same',
  campaign: 'Kampania/Oferta',
  campaigns: 'Kampanie/Oferty',
  all_campaigns: 'Wszystkie kampanie',
  new_campaign: 'Nowa kampania',
  add_campaign: 'Dodaj kampanię',
  all: 'Wszystko',
  title: 'Tytuł',
  description: 'Opis',
  status: 'Status',
  fileStatus: 'Status pliku',
  privacy: 'Prywatność',
  privacy_policy: 'Polityka prywatności',
  active: 'Aktywny',
  disable: 'Wyłącz',
  disabled: 'Wyłączony',
  paused: 'Wstrzymany',
  public: 'Publiczny',
  pre_moderation: 'Wstępna moderacja',
  public_auto_approve: 'Publiczny automatycznie zatwierdzany',
  private: 'Prywatny',
  no_results_found: 'Nie znaleziono wyników',
  display_registration_popup: 'Wyświetl wiadomość aktywacyjną w popupie',
  display_registration_popup_helper:
    'Włącz tę opcję, aby wyświetlać popup z wiadomością aktywacyjną zamiast zwykłego komunikatu.',
  this_field_is_required: 'To pole jest wymagane',
  actions: 'Akcje',
  general: 'Ogólne',
  tracking: 'Śledzenie',
  traffic_source: 'Źródło ruchu',
  traffic_sources: 'Źródła ruchu',
  currency: 'Waluta',
  features: 'Funkcje',
  featured: 'Wyróżnione',
  start_date: 'Data rozpoczęcia',
  end_date: 'Data zakończenia',
  hold_time: 'Czas wstrzymania',
  successfully_updated: 'Pomyślnie zaktualizowano',
  successfully_created: 'Pomyślnie utworzono',
  last_update_newest_first: 'Ostatnia aktualizacja (najnowsze pierwsze)',
  last_update: 'Ostatnia aktualizacja',
  last_update_oldest_first: 'Ostatnia aktualizacja (najstarsze pierwsze)',
  total_orders_high_to_low:
    'Łączna liczba zamówień (od najwyższej do najniższej)',
  total_orders_low_to_high:
    'Łączna liczba zamówień (od najniższej do najwyższej)',
  delete: 'Usuń',
  edit: 'Edytuj',
  name: 'Nazwa',
  website: 'Strona internetowa',
  contact_person: 'Osoba kontaktowa',
  im_or_skype: 'IM / Skype',
  full_name: 'Pełne imię i nazwisko',
  address: 'Adres',
  address1: 'Adres 1',
  address2: 'Adres 2',
  postcode: 'Kod pocztowy',
  allowed_ips: 'Dozwolone IP',
  disallowed_ips: 'Zabronione IP',
  api_allowed_ips: 'Dozwolone IP API',
  manager_allowed_ips: 'Dozwolone IP menedżera',
  note: 'Notatka',
  secure_postback_code: 'Bezpieczny kod postback',
  vat_code: 'Kod VAT',
  zipcode: 'Kod pocztowy',
  true: 'Prawda',
  false: 'Fałsz',
  update_advertiser: 'Aktualizuj reklamodawcę',
  create_advertiser: 'Utwórz reklamodawcę',
  edit_advertiser: 'Edytuj reklamodawcę',
  update_campaign: 'Aktualizuj kampanię',
  create_campaign: 'Utwórz kampanię',
  settings: 'Ustawienia',
  create_traffic_source: 'Utwórz źródło ruchu',
  update_traffic_source: 'Aktualizuj źródło ruchu',
  edit_traffic_source: 'Edytuj źródło ruchu',
  add_traffic_source: 'Dodaj źródło ruchu',
  create: 'Utwórz',
  update: 'Aktualizuj',
  traffic_source_created: 'Źródło ruchu utworzone',
  new_company_traffic_source: 'Nowe źródło ruchu dla firmy',
  new_traffic_source: 'Nowe źródło ruchu',
  edit_campaign: 'Edytuj kampanię',
  tracking_url: 'URL śledzenia',
  preview_url: 'URL podglądu',
  redirect_type: 'Typ przekierowania',
  traffic_back_url: 'URL ruchu zwrotnego',
  no_advertisers_found: 'Nie znaleziono reklamodawców',
  no_campaign_create_advertiser:
    'Aby utworzyć kampanię, najpierw utwórz reklamodawcę',
  create_traffic_source_first:
    'Aby utworzyć kampanię, najpierw musisz mieć źródło ruchu',
  discounted_prices: 'Obniżone ceny',
  email_verified: 'Email zweryfikowany',
  unique_ip_only: 'Wymagany unikalny adres IP',
  one_of_ips_invalid:
    'Nieprawidłowy format adresu IP: Wprowadź poprawny adres IP w formacie (np. 192.168.1.1). Upewnij się, że każda sekcja to liczba od 0 do 255 i jest oddzielona kropkami.',
  s2s_postback: 'S2S (Postback)',
  probabilistic_attribution: 'Probabilistyczna atrybucja',
  image: 'Obraz',
  js: 'JavaScript',
  iframe: 'Iframe',
  next: 'Dalej',
  ip_is_invalid: 'IP jest nieprawidłowy.',
  payout: 'Wypłata',
  payouts: 'Wypłaty',
  revenue: 'Przychód',
  goal_value: 'Wartość celu',
  goal_title: 'Tytuł celu',
  payment_type: 'Typ płatności',
  countries: 'Kraje',
  invalid: 'Nieprawidłowy',
  general_settings_title: 'Tytuł ustawień ogólnych',
  general_settings_start_date: 'Data rozpoczęcia ustawień ogólnych',
  general_settings_end_date: 'Data zakończenia ustawień ogólnych',
  general_settings_hold_time: 'Czas oczekiwania ustawień ogólnych',
  tracking_settings_integration_s2s_postback:
    'Integracja ustawień śledzenia S2S Postback',
  tracking_settings_traffic_back_url: 'Ustawienia śledzenia - Traffic Back URL',
  tracking_settings_preview_url: 'Ustawienia śledzenia - URL podglądu',
  tracking_settings_tracking_url: 'Ustawienia śledzenia - URL śledzenia',
  payouts_settings_goal_title: 'Ustawienia wypłat - Tytuł celu',
  payouts_settings_goal_value: 'Ustawienia wypłat - Wartość celu',
  payouts_settings_revenue: 'Ustawienia wypłat - Przychód',
  payouts_settings_payouts: 'Ustawienia wypłat',
  field: 'Pole',
  ip_should_be_unique: 'IP powinno być unikalne',
  required: 'Wymagane',
  field_invalid: 'Pole jest nieprawidłowe',
  general_payout: 'Ogólna wypłata',
  personal_payout: 'Osobista wypłata',
  valid: 'Prawidłowy',
  resource_name_unavailable: 'Nazwa zasobu jest niedostępna',
  cancel: 'Anuluj',
  end_date_is_invalid: 'Data zakończenia jest nieprawidłowa',
  affiliates: 'Afilianci',
  affiliate: 'Afiliant',
  click_session_life_span: 'Czas życia sesji kliknięcia',
  security: 'Bezpieczeństwo',
  password_confirm: 'Potwierdzenie hasła',
  current_password: 'Aktualne hasło',
  name_unavailable: 'Nazwa niedostępna',
  statistics: 'Wydajność',
  payouts_soon: 'Wypłaty (Wkrótce)',
  conversions_soon: 'Konwersje (Wkrótce)',
  affiliates_soon: 'Afilianci (Wkrótce)',
  campaign_stats: 'Raporty',
  campaign_id: 'Identyfikator kampanii',
  campaign_name: 'Nazwa kampanii',
  hosts: 'Hosty',
  hits: 'Odsłony',
  approved: 'Zatwierdzony',
  approve_affiliate: 'Zatwierdź afilianta',
  reject_affiliate: 'Odrzuć afilianta',
  disapproved: 'Odrzucony',
  pending: 'Oczekujące',
  declined: 'Odrzucone',
  decline: 'Odrzuć',
  cr: 'CR',
  epc: 'EPC',
  traffic: 'Ruch',
  conversions: 'Konwersje',
  finances: 'Finanse',
  filters: 'Filtry',
  hold: 'Wstrzymane',
  total: 'Łącznie',
  qty: 'Ilość',
  null: 'NULL',
  trafficback: 'Traffic Back',
  earnings: 'Zarobki',
  ecpm: 'ECPM',
  ecpc: 'ECPC',
  clear_filters: 'Wyczyść filtry',
  not_selected: 'Nie wybrano',
  filter: 'Filtr',
  conversion_id: 'ID konwersji',
  comment: 'Komentarz',
  user_agent: 'User Agent',
  utm_source: 'Źródło UTM',
  utm_medium: 'Medium UTM',
  utm_content: 'Treść UTM',
  sub_id: 'Sub ID',
  sub_id_text: 'Tekst Sub ID',
  sub_1: 'sub1',
  sub_2: 'sub2',
  sub_3: 'sub3',
  sub_4: 'sub4',
  sub_5: 'sub5',
  sub_6: 'sub6',
  sub_7: 'sub7',
  sub_8: 'sub8',
  sub1: 'Sub 1',
  sub2: 'Sub 2',
  sub3: 'Sub 3',
  sub4: 'Sub 4',
  sub5: 'Sub 5',
  sub6: 'Sub 6',
  sub7: 'Sub 7',
  sub8: 'Sub 8',
  goal_name: 'Nazwa celu',
  time_period: 'Okres czasu',
  income: 'Dochód',
  device: 'Urządzenie',
  geography_ip: 'Geografia / IP',
  amount: 'Kwota',
  date: 'Data',
  today: 'DZISIAJ',
  yesterday: 'WCZORAJ',
  last_week: 'OSTATNI TYDZIEŃ',
  last_two_weeks: 'OSTATNIE 2 TYGODNIE',
  last_month: 'OSTATNI MIESIĄC',
  this_month: 'TEN MIESIĄC',
  this_year: 'TEN ROK',
  all_time: 'CAŁY CZAS',
  last_three_months: 'OSTATNIE 3 MIESIĄCE',
  last_six_months: 'OSTATNIE 6 MIESIĘCY',
  last_year: 'OSTATNI ROK',
  custom: 'DOWOLNY',
  conversion_stats: 'Statystyki konwersji',
  create_conversion: 'Utwórz konwersję',
  update_conversion: 'Zaktualizuj konwersję',
  edit_conversion: 'Edytuj konwersję',
  all_conversions: 'Wszystkie konwersje',
  add_conversion: 'Dodaj konwersję',
  conversion_created: 'Konwersja utworzona',
  conversion_updated: 'Konwersja zaktualizowana',
  currently_unavailable: 'Obecnie niedostępne',
  creation_date: 'Data utworzenia',
  all_payouts: 'Wszystkie wypłaty',
  add_payout: 'Dodaj wypłatę',
  add: 'Dodaj',
  create_payout: 'Utwórz wypłatę',
  denied: 'Odrzucone',
  number: 'Numer',
  created_at: 'Utworzono',
  approved_and_paid: 'Zatwierdzone i opłacone',
  order_id: 'ID zamówienia',
  include_affiliate: 'Uwzględnij afilianta',
  amount_to_be_approved: 'Kwota do zatwierdzenia',
  commission_to_be_approved: 'Prowizja do zatwierdzenia',
  edit_payout: 'Edytuj wypłatę',
  advertiser_name: 'Nazwa reklamodawcy',
  total_amount: 'Łączna kwota',
  campaign_start_date: 'Data rozpoczęcia kampanii',
  campaign_end_date: 'Data zakończenia kampanii',
  update_payout: 'Zaktualizuj wypłatę',
  total_commission: 'Łączna prowizja',
  download_report: 'Pobierz raport',
  file_uploaded_successfully: 'Plik przesłano pomyślnie',
  download_latest_report: 'Pobierz przesłany raport',
  download_original_report: 'Pobierz oryginalny raport',
  payout_updated: 'Wypłata zaktualizowana',
  include_commission: 'Uwzględnij prowizję',
  no_report_attached: 'Brak dołączonego raportu',
  report_is_valid: 'Raport jest poprawny',
  view_payout: 'Zobacz wypłatę',
  affiliates_requests: 'Żądania afiliantów',
  requests: 'Żądania',
  approve: 'Zatwierdź',
  affiliate_payouts: 'Wypłaty afiliantów',
  save_and_next: 'Zapisz i dalej',
  available_to_payout: 'Dostępne do wypłaty',
  request_payout: 'Poproś o wypłatę',
  commission: 'Prowizja',
  affiliate_commission: 'Prowizja afilianta',
  see_all: 'Zobacz wszystkie',
  clicks: 'Kliknięcia',
  more_details: 'Więcej szczegółów',
  email: 'Email',
  emails: 'E-maile',
  affiliate_website: 'Strona afilianta',
  disapprove: 'Odrzuć',
  change_to_approved: 'Zmień na zatwierdzone',
  change_to_declined: 'Zmień na odrzucone',
  contact_details: 'Dane kontaktowe',
  login_as_affiliate: 'Zaloguj się jako afiliant',
  login: 'Zaloguj się',
  add_affiliate: 'Dodaj afilianta',
  create_affiliate: 'Utwórz afilianta',
  edit_affiliate: 'Edytuj afilianta',
  try_again_later: 'Spróbuj ponownie później',
  added_affiliate: 'Dodano afilianta',
  affiliate_management: 'Zarządzanie afiliantami',
  merchant_commission: 'Prowizja',
  phone_number: 'Numer telefonu',
  affiliate_updated: 'Afiliant zaktualizowany',
  affiliate_created: 'Afiliant utworzony',
  merchant_management: 'Zarządzanie',
  user_management: 'Zarządzanie użytkownikami',
  identity: 'Tożsamość',
  smtp: 'SMTP',
  edit_merchant: 'Edytuj menedżera użytkowników',
  merchant_updated: 'Menedżer użytkowników zaktualizowany',
  add_merchant: 'Dodaj menedżera użytkowników',
  create_merchant: 'Utwórz menedżera użytkowników',
  company_name: 'Nazwa firmy',
  company_name_for_invoice: 'Nazwa firmy (do faktury)',
  update_merchant: 'Menedżer zaktualizowany',
  campaign_logo: 'Logo kampanii',
  io_document: 'Dokument IO',
  select_logo: 'Wybierz logo',
  select_io_document: 'Wybierz dokument IO',
  img: 'Obraz',
  picture_unavailable: 'Obraz niedostępny',
  logo: 'Logo',
  id: 'ID',
  select_campaign: 'Wybierz kampanię',
  js_landing_page: 'Javascript Landing Page',
  js_thank_you: 'Javascript Thank You',
  js_landing_page_text:
    'Reklamodawca powinien umieścić ten kod po otwierającym tagu <body> na stronie docelowej. Należy dodać parametr &click_id={click_id} do adresu URL śledzenia',
  js_thank_you_text:
    'Reklamodawca powinien umieścić ten kod po otwierającym tagu <body> na stronie sukcesu "Dziękujemy"',
  end_date_before_start_date:
    'Data zakończenia jest wcześniejsza niż data rozpoczęcia',
  login_email: 'E-mail logowania',
  login_password: 'Hasło logowania',
  server: 'Serwer',
  port: 'Port',
  protocol: 'Protokół',
  smtp_and_ssl: 'SMTP + SSL',
  smtp_and_tls: 'SMTP + TLS',
  created: 'Utworzono',
  updated: 'Zaktualizowano',
  forgot_password_message:
    'Wpisz swój adres e-mail poniżej, aby zresetować hasło.',
  search_affiliate: 'Szukaj afilianta',
  search_advertisers: 'Szukaj reklamodawców',
  search_campaigns: 'Szukaj kampanii',
  search_payouts: 'Szukaj wypłat',
  search_conversions: 'Szukaj konwersji',
  search_campaign_request: 'Szukaj żądań kampanii',
  custom_domain: 'Niestandardowa domena SSL (dla afiliantów)',
  branding: 'Branding',
  request_details: 'Szczegóły żądania',
  search_traffic_source: 'Szukaj źródła ruchu',
  performance_chart: 'Wydajność',
  last_conversions: 'Ostatnie konwersje',
  users_key: 'Użytkownicy',
  last_30_days: 'OSTATNIE 30 DNI',
  reports: 'Raporty',
  no_name: 'Brak nazwy',
  mlm_tree: 'Drzewo MLM',
  documents: 'Dokumenty',
  search: 'Szukaj',
  SUB1: 'SUB1',
  SUB2: 'SUB2',
  SUB3: 'SUB3',
  SUB4: 'SUB4',
  SUB5: 'SUB5',
  SUB6: 'SUB6',
  SUB7: 'SUB7',
  SUB8: 'SUB8',
  top_campaigns: 'Najlepsze kampanie',
  Paused: 'Wstrzymane',
  Disabled: 'Wyłączone',
  logout: 'Wyloguj się',
  Active: 'Aktywny',
  click_session_days: 'Dni sesji kliknięć',
  click_session_hours: 'Godziny sesji kliknięć',
  statuses: 'Statusy',
  enter_new_password: 'Wprowadź nowe hasło',
  download: 'Pobierz',
  not_active: 'Nieaktywny',
  activate: 'Aktywuj',
  test_email: 'Wyślij testowy e-mail',
  alias: 'Alias',
  smtp_connection_failed_change_details:
    'Połączenie SMTP nie powiodło się, rozważ zmianę ustawień.',
  send_test_mail: 'Testowy e-mail wysłany',
  smtp_connected_successfully: 'Połączenie SMTP pomyślnie nawiązane',
  mail: 'Poczta',
  header: 'Nagłówek',
  footer: 'Stopka',
  attribution: 'Atrybucja',
  first_click: 'Pierwsze kliknięcie',
  last_click: 'Ostatnie kliknięcie',
  earn_per_click: 'Zarabiaj za kliknięcie',
  click_rate: 'Wskaźnik kliknięć',
  campaign_details: 'Szczegóły kampanii',
  disallowed: 'Zabronione',
  preview: 'Podgląd',
  hint: 'Wskazówka',
  coupon: 'Kupon',
  coupons: 'Zarządzanie kuponami',
  add_coupon: 'Dodaj kupon',
  cname_missing_add_to_dns_manager:
    'Brak CNAME, dodaj CNAME do swojego menedżera DNS.',
  new_affiliate_needs_activation: 'Weryfikacja adresu e-mail nowego afilianta',
  new_affiliate_needs_manual_activation:
    'Nowy afiliant wymaga ręcznej aktywacji',
  change_manager: 'Zmień menedżera',
  manager: 'Menedżer',
  default_merchant: 'Domyślny menedżer',
  merchant: 'Menedżer',
  need_help: 'Potrzebujesz pomocy?',
  check_our_docs: 'Sprawdź naszą dokumentację',
  documentation: 'Dokumentacja',
  reset_password: 'Zresetuj hasło',
  invitation: 'Zaproszenie',
  password_changed: 'Hasło zostało zmienione',
  enter_email: 'Wprowadź e-mail',
  industry: 'Branża',
  and: 'i',
  available_macros: 'Dostępne makra',
  custom_fields: 'Pola niestandardowe rejestracji',
  add_custom_field: 'Dodaj pole niestandardowe',
  search_custom_field: 'Szukaj pola niestandardowego',
  edit_custom_field: 'Edytuj pole niestandardowe',
  create_custom_field: 'Utwórz pole niestandardowe',
  update_custom_field: 'Zaktualizuj pole niestandardowe',
  add_custom_doc: 'Dodaj niestandardowy dokument',
  search_custom_doc: 'Szukaj niestandardowego dokumentu',
  edit_custom_doc: 'Edytuj niestandardowy dokument',
  create_custom_doc: 'Utwórz niestandardowy dokument',
  update_custom_doc: 'Zaktualizuj niestandardowy dokument',
  custom_field_name: 'Nazwa pola niestandardowego',
  not_required: 'Nie wymagane',
  field_name_is_already_used: 'Nazwa pola jest już w użyciu',
  are_you_sure: 'Jesteś pewny?',
  are_you_sure_masspay:
    'Czy na pewno chcesz kontynuować MAS PAY? \nPamiętaj, że płatność zostanie dokonana przez API i jest niezmienna.',
  are_you_sure_you_want_to_delete: 'Czy na pewno chcesz usunąć?',
  are_you_sure_you_want_to_delete_hard:
    'Czy na pewno chcesz trwale usunąć tego użytkownika? Wszystkie prywatne informacje zostaną trwale usunięte',
  are_you_sure_you_want_to_restore: 'Czy na pewno chcesz przywrócić klienta?',
  are_you_sure_you_want_to_delete_all:
    'Czy na pewno chcesz usunąć wszystkie elementy?',
  text_area: 'Obszar tekstowy',
  check_box: 'Pole wyboru',
  input: 'Pole wejściowe',
  type: 'Typ',
  field_type: 'Typ pola',
  default_manager: 'Domyślny menedżer',
  brand_logo: 'Logo marki',
  reached_max_campaigns_limit: 'Osiągnięto maksymalny limit kampanii',
  billing: 'Fakturowanie',
  payments: 'Płatności',
  plan: 'Plan',
  paypal: 'Paypal',
  wire_transfer: 'Przelew bankowy',
  payout_source: 'Źródło wypłaty',
  price: 'Cena',
  transaction_id: 'ID transakcji',
  view_invoice: 'Zobacz fakturę',
  upgrade: 'Ulepsz',
  work_email: 'Email służbowy',
  message: 'Wiadomość',
  contact_us: 'Skontaktuj się z nami',
  we_will_contact_u_soon: 'Skontaktujemy się z Tobą wkrótce!',
  terms_and_conditions_and_privacy_policy: 'Regulamin i Polityka Prywatności',
  current_email: 'Aktualny Email',
  please_update_your_plan: 'Proszę zaktualizować swój plan',
  here: 'tutaj',
  custom_domain_updated: 'Niestandardowa domena zaktualizowana',
  your_email: 'Twój Email',
  allowed: 'Dozwolone',
  created_merchant: 'Menadżer utworzony',
  affiliate_company: 'Firma afiliacyjna',
  linked_to_payout: 'Połączono z wypłatą',
  subject: 'Temat',
  has_payout: 'Ma wypłatę',
  yes: 'Tak',
  no: 'Nie',
  select: 'Wybierz',
  view_conversions: 'Zobacz konwersje',
  search_conversions_by_campaign_affiliate_or_payout_or_click_id:
    'Szukaj po identyfikatorze kliknięcia, nazwie kampanii lub nazwie firmy afiliacyjnej.',
  search_conversions_by_campaign_affiliate_or_payout:
    'Szukaj po nazwie kampanii lub nazwie firmy afiliacyjnej.',
  traffic_back_explanation:
    'Klient zostanie przekierowany na "Traffic Back URL" w przypadku niepowodzenia lub jeśli kampania zostanie wyłączona.',
  goal_value_explanation_row_1:
    "Wartość 'Cel' umożliwia płatność za zdarzenia lub kategorie.",
  goal_value_explanation_row_2:
    "Wartość 'Cel' musi być zwrócona przez Twój kod/postback.",
  goal_value_explanation_row_3:
    "Więcej informacji <a href='https://help.tracknow.io/knowledge-base/create-goals/'>Kliknij tutaj</a>",
  test_smtp_email: 'Testowy Email SMTP',
  campaign_terms: 'Warunki Kampanii',
  new_email: 'Nowy Email',
  payment_details: 'Szczegóły płatności',
  test: 'Test',
  test_conversion: 'Testowa konwersja',
  advertiser_details: 'Szczegóły reklamodawcy',
  request_date: 'Data wniosku',
  reason: 'Powód',
  affiliate_request: 'Wniosek afiliacyjny',
  the_reason_will_be_visible_to_the_affiliate:
    'Powód będzie widoczny dla afilianta',
  affiliate_registration_url: 'URL rejestracji afilianta',
  copy: 'Kopiuj',
  copied_to_clipboard: 'Skopiowano do schowka',
  minimum_withdrawal_amount: 'Minimalna kwota wypłaty',
  payment_methods: 'Metody płatności',
  search_payment_method: 'Szukaj metody płatności',
  create_payment_method: 'Utwórz metodę płatności',
  edit_payment_method: 'Edytuj metodę płatności',
  payment_method_created: 'Metoda płatności utworzona',
  payment_method: 'Metoda płatności',
  conversion_statuses_explanation:
    "Aby utworzyć wypłatę do excela i zmienić statusy konwersji w zbiorczej aktualizacji, wybierz reklamodawcę i daty, a następnie kliknij 'UTWÓRZ WYPŁATĘ.'",
  powered_by_tracknow: 'Zasilane przez Tracknow',
  change_email_confirmation_email_sent:
    'Wysłano e-mail z potwierdzeniem na Twój obecny email',
  check_your_data: 'Błąd',
  are_you_sure_you_want_to_create_a_payout:
    'Czy na pewno chcesz utworzyć wypłatę?',
  create_payout_confirmation: 'Potwierdzenie utworzenia wypłaty',
  payout_statuses_explanation:
    'Statusy wypłat: Zatwierdzone - wypłata dostępna do zapłaty. Oczekujące - proces wypłaty wciąż trwa. Odrzucone - wypłata została odrzucona z określonego powodu.',
  payout_created: 'Wypłata utworzona',
  excel_statuses_explanation:
    'Statusy Excel: Zmień statusy w pliku Excel i załaduj go na serwer. \n\n' +
    '  Proszę użyć następujących statusów w Excelu:\n' +
    '  ZATWIERDZONE - prowizja będzie dostępna dla afilianta. \n' +
    '  OCZEKUJĄCE - konwersja zostanie zmieniona na status oczekujący. \n' +
    '  ODRZUCONO - konwersja zostanie usunięta z salda oczekującego/dostępnego.\n' +
    '\n' +
    '\n' +
    'Proszę pamiętać, że status wypłaty nie wpływa na statusy konwersji, jeśli chcesz zmienić statusy konwersji, stwórz nową wypłatę z ekranu konwersji.\n' +
    '\n' +
    '* Proszę pamiętać, że statusy konwersji będą zmieniane tylko wtedy, gdy status wypłaty zostanie zmieniony na Zatwierdzone i plik Excel zostanie załadowany.\n' +
    '\n' +
    '* Aby zmienić statusy konwersji, proszę załadować plik Excel.',
  system_id: 'ID systemu',
  conversion_sys_id: 'ID systemu konwersji',
  tax: 'Podatek',
  number_must_be_between: 'Numer musi być pomiędzy {min} a {max}',
  ready: 'Gotowy',
  edit_statuses: 'Edytuj statusy',
  apply_changes_to: 'Zastosuj zmiany statusów do ({count})',
  failed: 'Niepowodzenie',
  processing: 'W trakcie przetwarzania',
  affiliate_payout: 'Wypłata afilianta',
  permissions: 'Uprawnienia',
  read_permissions: 'Uprawnienia do odczytu',
  write_permissions: 'Uprawnienia do zapisu',
  contact_us_email: 'Email kontaktowy',
  core_affiliates_only: 'Wyświetl tylko MLM (root)',
  contact_us_email_helper:
    'Formularz "Kontakt z nami" afilianta wyśle email na ten adres. Jeśli to pole jest puste, email zostanie wysłany do menedżera.',
  both_permissions: 'Uprawnienia do odczytu i zapisu',
  enter_new_password_reset:
    'Wprowadź swoje nowe hasło poniżej, aby zresetować swoje hasło.',
  referer: 'Referer',
  coupon_code: 'Kod kuponu',
  country: 'Kraj',
  please_enable_smtp_first: 'Proszę najpierw włączyć SMTP',
  scale: 'Skala',
  rotate: 'Obróć',
  crop: 'Przytnij',
  time_format: 'Format czasu',
  time_zone: 'Strefa czasowa',
  billing_description:
    'Poznaj stronę rozliczeń platformy Tracknow dla afiliantów. Rozpocznij swoją podróż marketingową za darmo dzięki naszemu darmowemu planowi. W miarę rozwoju przejdź na plany Team lub Enterprise, odblokowując zaawansowane funkcje. Dostępne elastyczne opcje płatności. Dołącz do nas już dziś i uwolnij moc Tracknow!',
  personal_settings: 'Ustawienia personalne',
  personal: 'Osobiste',
  company_settings: 'Ustawienia firmy',
  day: 'Dzień',
  year: 'Rok',
  month: 'Miesiąc',
  group_by: 'Grupuj według',
  deep_link: 'Link bezpośredni',
  tracking_params: 'Parametry śledzenia',
  order_amount: 'Kwota zamówienia',
  unlimited_sales: 'Nielimitowane sprzedaże',
  sales: 'Sprzedaż',
  up_to_x_affiliates: 'Do {0} afiliantów',
  ssl_setup: 'Konfiguracja SSL',
  dedicated_smtp: 'Dedykowane SMTP',
  custom_commissions: 'Dedykowane prowizje',
  custom_commission_affiliate: 'Dedykowane prowizje afiliantów',
  email_support: 'Wsparcie emailowe',
  no_limit_campaigns: 'Nielimitowane kampanie',
  no_limit_affiliates: 'Nielimitowani afilianci',
  email_and_chat_support: 'Wsparcie emailowe i czat',
  dedicated_account_manager: 'Dedykowany menedżer konta',
  coupon_offline: 'Śledzenie kuponów offline',
  invoices: 'Faktury',
  next_subscription_fee_due: 'Następna opłata subskrypcyjna: ',
  you_wiil_return_to_the_free_tier_at:
    'Twoja subskrypcja została anulowana. Skontaktuj się z naszym wsparciem pod adresem support@tracknow.io',
  are_you_sure_you_want_to_reset_cname:
    'Czy na pewno chcesz zresetować domenę niestandardową?',
  reset_cname: 'Zresetuj domenę niestandardową',
  subscription_details: 'Szczegóły subskrypcji:',
  max_x_capmaigns: '{0} domeny/oferty',
  unlimited_campaigns: 'Nielimitowane domeny/oferty',
  tax_not_included: 'Cena nie zawiera VAT',
  email_for_invoice: 'Email do faktury:',
  plans_and_pricing: 'Plany i ceny',
  reset: 'Resetuj',
  your_membership_tier: 'Twój poziom subskrypcji:',
  click_here: 'Kliknij tutaj',
  advertiser_should_add_the:
    'Reklamodawca powinien dodać następujące parametry do URL: order_id, amount, goal, dla innych opcji śledzenia proszę',
  for_a_tutorial_on_how_to_add_postback:
    'Aby uzyskać samouczek na temat dodawania postbacku, proszę',
  payout_settings: 'Ustawienia wypłaty',
  brand_colors: 'Kolory marki',
  custom_script: 'Niestandardowy skrypt',
  getting_started: 'Rozpocznij',
  done: 'Zrobione',
  deleted_successfully: 'Usunięto pomyślnie',
  creatives: 'Kreacje',
  add_new: 'Dodaj nowy',
  create_a_campaign: 'Utwórz kampanię',
  adding_an_affiliate: 'Dodawanie afilianta',
  affiliate_dashboard: 'Panel afilianta',
  feel_free_to_contact_us: 'W razie potrzeby skontaktuj się z nami',
  campaign_management: 'Zarządzanie kampanią',
  add_new_crative: 'Dodaj nową kreację',
  are_you_sure_you_want_to_delete_this_creative: 'Usuń',
  delete_notify: 'Czy na pewno chcesz usunąć tę kreację?',
  delete_all: 'Usuń wszystkie',
  delete_all_creatives_notify:
    'Czy na pewno chcesz usunąć wszystkie kreacje kampanii?',
  creative: 'Kreacja',
  mlm_commission: 'Prowizja polecenia',
  mlm_level2_commission: 'Prowizja 2. poziomu polecenia',
  mlm_level3_commission: 'Prowizja 3. poziomu polecenia',
  mlm_level4_commission: 'Prowizja 4. poziomu polecenia',
  mlm_level5_commission: 'Prowizja 5. poziomu polecenia',
  allow_mlm: 'Zezwól na MLM (Marketing Wielopoziomowy)',
  apply_mlm: 'Zastosuj MLM (Marketing Wielopoziomowy)',
  mlm: 'Marketing Wielopoziomowy (MLM)',
  mlm_percent: 'Procent MLM',
  allow_mlm_level_x: 'Zezwól na MLM poziom {x}',
  mlm_level_x_percent: 'Procent MLM poziom {x}',
  mlm_level_x_commission: 'Prowizja {x} poziomu polecenia',
  referrer: 'Polecający',
  default_campaign: 'Domyślna kampania',
  conversion_tracking_setup: 'Ustawienia śledzenia konwersji',
  news: 'Aktualności i biuletyn',
  content: 'Treść',
  add_news: 'Dodaj aktualności',
  edit_news: 'Edytuj aktualności',
  inactive: 'Nieaktywne',
  draft: 'Szkic',
  none: 'Brak',
  left: 'Lewo',
  right: 'Prawo',
  custom_currency_placement: 'Niestandardowe umiejscowienie waluty',
  custom_currency_symbol: 'Niestandardowy symbol waluty',
  invalid_url: 'Nieprawidłowy URL',
  categories_tracking: 'Śledzenie kategorii',
  phone_support: 'Wsparcie telefoniczne',
  chat_support: 'Wsparcie na czacie',
  unlimited_banners: 'Nielimitowane Kreacje',
  include_in_automation: 'Uwzględnij w automatyzacji',
  exclude_in_automation: 'Wyklucz z automatyzacji',
  impressions: 'Wrażenia (CPM)',
  cpm_commissions: 'Prowizje CPM',
  conversion_commissions: 'Prowizje za konwersje',
  ppc_commissions: 'Prowizje PPC',
  support_app_conversions: 'Wsparcie konwersji APP (Android/IOS)',
  support_app_helper:
    'W przypadku, gdy kliknięcie i piksel konwersji pochodzą z różnych przeglądarek agentów.',
  requested_commission: 'Żądana prowizja',
  manage: 'Zarządzaj',
  select_a_payout: 'Wybierz wypłatę',
  select_a_campaign: 'Wybierz kampanię',
  use_general_capmaign_settings: 'Użyj ogólnych ustawień kampanii',
  add_user_to_campaign: 'Przypisz afilianta do kampanii',
  payout_groups: 'Grupy wypłat',
  commissions: 'Prowizje',
  affiliate_details: 'Szczegóły afilianta',
  creative_empty_desc: 'Brak kreacji.',
  country_code_required: 'Kod kraju jest wymagany podczas rejestracji',
  custom_docs: 'Niestandardowe dokumenty',
  APPROVED: 'Zatwierdzone',
  REJECTED: 'Odrzucone',
  PENDING: 'Oczekujące',
  DECLINED: 'Odrzucone',
  NEEDS_ATTENTION: 'Wymaga uwagi',
  INCOMPLETE: 'Niekompletne',
  EXPIRED: 'Wygasło',
  update_status: 'Zaktualizuj status',
  options: 'Opcje',
  select_helper:
    'Oddziel wartości select przecinkiem. Przykład: Mężczyzna,Kobieta.',
  select_files: 'Wybierz pliki',
  drop_files_here_or_click_browse_through_your_machine:
    'Upuść pliki tutaj lub kliknij, aby przeglądać pliki w swoim urządzeniu',
  remove_all: 'Usuń wszystkie',
  upload_files: 'Prześlij pliki',
  no_documents_required: 'Brak wymaganych dokumentów',
  admin_status_changed_email: 'Status administratora zmieniony',
  admin_status_approved_email: 'Status administratora zatwierdzony',
  when_company_requires_documents_upload_here:
    'Kiedy firma wymaga dokumentów, musisz je tutaj przesłać',
  single_campaign_mode: 'Tryb jednej kampanii',
  single_campaign_mode_off: 'Wszystkie kampanie są dostępne.',
  single_campaign_mode_on:
    'Dostęp ograniczony do domyślnych kampanii (ustawionych przez firmę lub użytkownika).',
  country_grouping: 'Grupa krajów',
  grouping: 'Grupowanie',
  create_grouping: 'Utwórz grupę',
  symbols: 'Symbole',
  symbol_grouping: 'Grupa symboli',
  create_symbol: 'Utwórz symbol',
  default_status: 'Domyślny status',
  payout_type: 'Typ wypłaty',
  confirmation: 'Potwierdzenie',
  copy_short_url: 'Kopiuj krótki link',
  generate_short_url: 'Generuj krótki link',
  short_url: 'Krótki link',
  after_activation:
    'Witamy (E-mail zostanie wysłany tylko, gdy tekst w treści będzie istnieć)',
  users: 'Użytkownicy',
  forex: 'Raporty analityczne',
  add_user: 'Dodaj użytkownika',
  edit_user: 'Edytuj użytkownika',
  first_deposit_date: 'Data pierwszej wpłaty',
  user_id: 'ID użytkownika',
  lead_id: 'ID leada',
  balance: 'Bilans',
  volume: 'Wolumen',
  lastAffiliateGroupChangeDate: 'Data ostatniej zmiany grupy afilianta',
  firstDepositDate: 'Data pierwszej wpłaty',
  account_id: 'ID konta',
  account_currency: 'Waluta konta',
  add_account: 'Dodaj konto',
  edit_account: 'Edytuj konto',
  add_transaction: 'Dodaj transakcję',
  edit_transaction: 'Edytuj transakcję',
  accounts: 'Konta',
  account: 'Konto',
  forex_account: 'Konto Forex',
  profit: 'Zysk',
  symbol: 'Symbol',
  transactions: 'Transakcje',
  transaction_date: 'Data transakcji',
  open_price: 'Cena otwarcia',
  close_price: 'Cena zamknięcia',
  show_symbols: 'Pokaż symbole',
  qualified: 'Zakwalifikowany',
  leverage: 'Dźwignia',
  user: 'Użytkownik',
  symbol_payout_modal_title: 'Dodaj cenę dla symbolu',
  symbol_payout_modal_desc: 'Dodaj cenę dla symbolu',
  position_count: 'Liczba pozycji',
  net_deposit: 'Netto wpłata/linia bazowa',
  ask_price: 'Cena ask',
  bid_price: 'Cena bid',
  transaction_type: 'Typ transakcji',
  broker_commission: 'Prowizja brokera',
  revshare_type: 'Typ Revshare',
  currency_price: 'Cena waluty',
  converted_volume: 'Przekształcony wolumen',
  more_options: 'Więcej opcji',
  financial_users: 'Użytkownicy finansowi',
  financial_accounts: 'Konta finansowe',
  first_time_deposit: 'Pierwsza wpłata',
  new_country_grouping: 'Nowa grupa krajów',
  new_symbol_grouping: 'Nowa grupa symboli',
  currency_change_title: 'Alert: Potwierdzenie zmiany waluty',
  currency_change_desc:
    'Zaraz zmienisz walutę swojej firmy. Zauważ, że zmieni to wartość waluty konwersji i może wpłynąć na ustawienia konta.',
  tax_id: 'NIP',
  affiliate_settings: 'Ustawienia afilianta',
  hideOrderId: 'Ukryj ID zamówienia (Afiliant)',
  hideAmount: 'Ukryj kwotę (Afiliant)',
  allow_ip_explain:
    'Dozwolone będą tylko postbacki z tych adresów IP, chyba że zostaną dodane do Zablokowanych adresów IP (Biała lista IP). Adresy IP powinny być oddzielone przecinkami. Przykład: 127.0.0.1, 192.17.17.1. Pozostaw puste, aby zezwolić na wszystkie adresy IP.',
  disallow_ip_explain:
    'Wszystkie postbacki z tych adresów IP będą zablokowane (Czarna lista IP). Adresy IP powinny być oddzielone przecinkami. Przykład: 127.0.0.1, 192.17.17.1. Pozostaw puste, aby zezwolić na wszystkie adresy IP.',
  api_allow_ip_explain:
    'Dozwolone będą tylko żądania API z tych adresów IP (Biała lista IP). Adresy IP powinny być oddzielone przecinkami. Przykład: 127.0.0.1, 192.17.17.1. Pozostaw puste, aby zezwolić na wszystkie adresy IP.',
  managers_allow_ip_explain:
    'Dozwolone będą tylko logowania menedżerów z tych adresów IP (Biała lista IP). Adresy IP powinny być oddzielone przecinkami. Przykład: 127.0.0.1, 192.17.17.1. Pozostaw puste, aby zezwolić na wszystkie adresy IP.',
  setup_included: 'W zestawie',
  affiliate_platform_name: 'Nazwa platformy afiliacyjnej',
  assign_personal_payouts: 'Przypisz wypłaty osobiste',
  add_notes: 'Dodaj notatki',
  author: 'Autor',
  new_note: 'Nowa notatka',
  net_deposits: 'Netto wpłaty',
  notes: 'Notatki',
  referrals: 'Polecenia',
  screens: 'Ekrany',
  fields: 'Pola',
  financialAccountId: 'ID Konta',
  financialTransactionsProfit: 'Zysk z transakcji',
  financialTransactionsId: 'ID Transakcji',
  financialUserBalance: 'Saldo użytkownika',
  view: 'Widok',
  generate_qr_code: 'Generuj kod QR',
  qr_code: 'Kod QR',
  search_by_user_id_or_lead_id: 'Wyszukaj po ID użytkownika lub ID leadu',
  postback_stats: 'Postback',
  success: 'Sukces',
  fail_reason: 'Powód niepowodzenia',
  click_id: 'ID kliknięcia',
  logs: 'Logi',
  ip: 'IP',
  parameters: 'Parametry',
  top_affiliates: 'Top afilianci',
  disallowed_countries: 'Zabronione kraje',
  changes: 'Zmiany',
  compare: 'Porównaj',
  entity_id: 'ID jednostki',
  entity_class: 'Klasa jednostki',
  activity_type: 'Typ aktywności',
  audit: 'Audyt',
  info: 'Informacje',
  company: 'Firma',
  individual: 'Indywidualny',
  requireAffiliateLegalStatus: 'Wymagany status prawny afilianta',
  requireAffiliate2FA: 'Wymagana weryfikacja dwuetapowa afilianta',
  requireCompanyName: 'Wymagana nazwa firmy',
  please_verify_you_are_not_a_robot:
    'Proszę zweryfikować, że nie jesteś robotem',
  please_fill_captcha: 'Proszę wypełnić poniższy captcha:',
  two_factor_auth_dialog_title: 'Żądanie weryfikacji dwuetapowej',
  enable: 'Włącz',
  two_factor_auth_dialog_description:
    'Użytkownik włączył weryfikację dwuetapową (2FA) dla dodatkowego bezpieczeństwa. Aby kontynuować, wprowadź jednorazowy kod (OTP)',
  '2fa': 'Weryfikacja dwuetapowa (2FA)',
  '2fa_active': '2FA aktywne',
  enter_6_digit_code: 'Wprowadź 6-cyfrowy kod',
  INVALID_2FA_TOKEN: 'Nieprawidłowy token 2FA',
  MISSING_2FA_TOKEN: 'Brak tokenu 2FA',
  payout_automation: 'Automatyzacja wypłat',
  add_payout_automation: 'Dodaj automatyzację wypłat',
  automation_type: 'Typ automatyzacji',
  original_payout_setting: 'Oryginalne ustawienie wypłat',
  campaign_payout_automation_tiers: 'Poziomy automatyzacji wypłat kampanii',
  payout_tiers: 'Poziomy wypłat',
  tier_threshold: 'Próg poziomu',
  threshold: 'Próg',
  campaign_payout_automation_title:
    'Automatyczne dostosowania wypłat afiliantów',
  campaign_payout_automation_subtitle:
    'Wprowadziliśmy zaawansowaną funkcję optymalizacji wypłat afiliantów na podstawie wyników. Oto jak to działa:',
  campaign_payout_automation_desc_evaluation:
    '<strong>Ocena wyników:</strong> Na początku każdego miesiąca system oblicza wyniki afiliantów na podstawie kluczowych wskaźników, takich jak kwota konwersji, wolumen i liczba konwersji.',
  campaign_payout_automation_desc_performance_tiers:
    '<strong>Poziomy wyników z progami:</strong> Afilianci są przypisani do określonych poziomów na podstawie swoich wyników w stosunku do wcześniej zdefiniowanych progów.',
  campaign_payout_automation_desc_rewarding_performance:
    '<strong>Dostosowania wypłat:</strong> Afilianci, którzy przekroczą cele z poprzedniego miesiąca, zostaną automatycznie awansowani do wyższego poziomu wypłat.',
  campaign_payout_automation_desc_personalized_payouts:
    '<strong>Bonusy dla afiliantów:</strong> Afilianci zarabiają bonusy, spełniając określone progi. Jeśli w ramach jednej automatyzacji dostępnych jest kilka bonusów, system przyznaje ten związany z najwyższym osiągniętym progiem. Kwota bonusu jest określona w ustawieniach bonusów wypłat.',
  are_you_sure_you_want_to_run_this_automation:
    'Czy na pewno chcesz uruchomić tę automatyzację?',
  run: 'Uruchom',
  last_run_date: 'Data ostatniego uruchomienia',
  financial_stats: 'Statystyki',
  deposits: 'Depozyty',
  withdrawals: 'Wypłaty',
  withdrawals_amount: 'Kwota wypłat',
  client_details: 'Szczegóły klienta',
  latest_clients: 'Najnowsi klienci',
  registrations: 'Rejestracje',
  advanced_tracking: 'Zaawansowane śledzenie',
  NO_PERMISSION:
    'Nie masz uprawnień do wykonania tej operacji. Skontaktuj się z administratorem.',
  financial_transactions: 'Transakcje',
  click_logs: 'Logi kliknięć',
  weight: 'Waga',
  weight_helper:
    'Wartość Wagi reprezentuje prawdopodobieństwo; im wyższa wartość, tym większa szansa na wyświetlenie. Ta funkcja jest używana w testach A/B.',
  direct_link: 'Bezpośredni Link',
  direct_link_title: 'Bezpośredni Link (wymaga konfiguracji)',
  direct_link_subtitle:
    'Reklamodawca powinien umieścić ten kod po otwierającym tagu <body> na stronie docelowej.',
  direct_link_helper:
    'Włącz tę opcję, aby zachować niezmieniony URL śledzenia dla lepszego SEO. Zauważ, że w przypadku Bezpośredniego Linku, zaawansowane funkcje śledzenia, takie jak makra, będą niedostępne. Aby zapewnić dokładne zbieranie danych, należy wdrożyć mały skrypt na swojej stronie internetowej. Możesz swobodnie przełączać się między Bezpośrednim Linkiem a tradycyjnymi metodami w zależności od preferencji śledzenia. Jeśli potrzebujesz pomocy, nasz zespół wsparcia jest gotów, aby Ci pomóc.',
  dont_show_again: 'Pomiń i nie pokazuj ponownie',
  dont_show_again_desc:
    'Wybierając tę opcję, sekcja "Rozpoczęcie pracy" zostanie ukryta w menu, zapewniając bardziej uporządkowane doświadczenie użytkownika. Ważne jest, abyś wiedział, że wszystkie ustawienia możesz łatwo dostosować, odwiedzając stronę Ustawienia w dowolnym momencie, co pozwoli Ci spersonalizować doświadczenie zgodnie z Twoimi preferencjami. Jeśli masz jakiekolwiek pytania lub potrzebujesz pomocy, skontaktuj się z naszym dedykowanym zespołem wsparcia. Twoje zadowolenie jest dla nas ważne, jesteśmy tutaj, aby Ci pomóc.',
  campaign_advanced_tracking_title:
    'Wprowadzenie naszych zaawansowanych funkcji',
  campaign_advanced_tracking_subtitle:
    'Zaawansowane śledzenie i testowanie A/B. Dowiedz się, jak te funkcje mogą Ci pomóc:',
  campaign_advanced_tracking_geo:
    '<strong>Zaawansowane śledzenie strategii geotargetowania</strong> - pozwala automatycznie kierować użytkowników z określonych krajów na dedykowane strony, maksymalizując celowanie w odpowiednią grupę odbiorców. Instrukcje konfiguracji znajdziesz <a href="https://help.tracknow.io/knowledge-base/advanced-tracking/" target="_blank">tutaj</a>.',
  campaign_advanced_tracking_ab:
    '<strong>Testowanie A/B</strong> - podejmuj decyzje na podstawie danych, porównując wydajność różnych stron internetowych, różnych URL lub elementów przez testy A/B, aby zidentyfikować najlepiej działającą wersję. Instrukcje konfiguracji znajdziesz <a href="https://help.tracknow.io/knowledge-base/advanced-tracking-a-b-testing/" target="_blank">tutaj</a>.',
  yearly: 'Rocznie',
  monthly: 'Miesięcznie',
  x_off: '{discount}% zniżki',
  everything_in: 'Wszystko w planie {previousPlan} i:',
  includes: 'Zawiera:',
  compare_plans: 'Porównaj plany',
  purchase_date: 'Data zakupu',
  invalidPasswordMessage:
    'Hasło musi zawierać przynajmniej jeden znak alfabetyczny, jedną cyfrę, jeden znak specjalny z poniższego zbioru (!@#$%^&*()_+) i mieć od 10 do 20 znaków długości.',
  download_sample_CSV: 'Pobierz przykładowy plik CSV',
  import_affiliates: 'Importuj partnerów',
  import_affiliates_desc:
    'Zarządzanie danymi bez wysiłku: Prześlij plik CSV, łatwo edytuj pola (kliknij dwukrotnie) i wyślij z obsługą błędów dla płynnego doświadczenia',
  payout_desc: 'Niestandardowy tekst wypłaty',
  payout_desc_helper:
    'Jeśli niestandardowy tekst wypłaty jest pusty, wyświetlimy wypłatę na podstawie ustawień',
  total_paid: 'Łączna wypłata',
  payout_request_created: 'Żądanie wypłaty utworzone',
  platform: 'Platforma',
  username: 'Nazwa użytkownika',
  social: 'Społecznościowe',
  social_profiles: 'Profile społecznościowe',
  video: 'Wideo',
  carousel: 'Karuzela',
  story: 'Historia',
  refresh_social: 'Odśwież profile społecznościowe',
  campaign_social_tracking_title:
    'Przedstawiamy naszą innowacyjną unikalną funkcję śledzenia mediów społecznościowych',
  campaign_social_tracking_subtitle:
    'Nasza najnowsza funkcja oferuje unikalne rozwiązanie dla firm, które współpracują z influencerami, umożliwiając im:',
  campaign_social_tracking_monitor:
    'Monitorowanie i analizowanie wydajności wszystkich influencerów powiązanych z Twoją marką na Instagramie i TikToku, wszystko w jednym centralnym miejscu.',
  campaign_social_tracking_manage:
    'Łatwe zarządzanie wynagrodzeniem influencerów przez wypłatę za post, historię lub polubienie, usprawniając proces zarówno dla influencerów, jak i marek.',
  followers: 'Obserwujący',
  influencers: 'Influencerzy',
  posts: 'Posty',
  stories: 'Historie',
  comments: 'Komentarze',
  likes: 'Polubienia',
  views: 'Wyświetlenia',
  load_more: 'Załaduj więcej',
  social_tracking: 'Śledzenie mediów społecznościowych',
  manage_all_affiliates: 'Zarządzaj wszystkimi partnerami',
  http_method: 'Metoda Http',
  url: 'URL',
  webhook: 'Webhook',
  webhook_type: 'Typ webhooka',
  webhooks: 'Webhooki',
  webhook_logs: 'Logi webhooków',
  please_add_a_payment_method:
    'Partner nie skonfigurował jeszcze metody płatności',
  balance_sheet: 'Raport bilansu',
  balance_sheet_dates: 'Raport bilansu (daty)',
  paid_payouts: 'Wypłacone wypłaty',
  pending_payouts: 'Oczekujące wypłaty',
  pending_manual: 'Oczekujące dostosowanie',
  approved_manual: 'Zatwierdzone dostosowanie',
  assign_affiliates: 'Przypisz partnerów',
  create_affiliate_url: 'Utwórz URL partnera',
  brand_fav_icon: 'Ikona marki',
  custom_id: 'Niestandardowy ID',
  custom_id_helper:
    'Niestandardowy identyfikator użytkownika służący do unikalnej identyfikacji użytkowników w zewnętrznych systemach śledzenia i analizy. Ten identyfikator jest używany jako makro w parametrach śledzenia, umożliwiając personalizowaną identyfikację użytkowników w linkach',
  become_an_advertiser: 'Zostań reklamodawcą',
  become_an_advertiser_desc:
    'Proszę wypełnić formularz poniżej, aby złożyć prośbę o dołączenie do naszej platformy jako reklamodawca',
  become_an_advertiser_res:
    'Dziękujemy za zgłoszenie zapytania o zostanie reklamodawcą. Po zatwierdzeniu, otrzymasz dane logowania na email',
  advertiser_registrations: 'Rejestracje reklamodawców',
  allow_affiliate_register: 'Zezwól na rejestrację partnerów',
  allow_advertiser_register: 'Zezwól na rejestrację reklamodawców',
  advertiser_user_management: 'Zarządzanie użytkownikami reklamodawcy',
  advertiser_login: 'Logowanie reklamodawcy',
  advertiser_user_login_desc:
    'Proszę pamiętać, że każda akcja podjęta po zalogowaniu jest wyłącznie odpowiedzialnością użytkownika i wpłynie na konto reklamodawcy.',
  edit_advertiser_user: 'Edytuj użytkownika reklamodawcy',
  advertiser_user_updated: 'Użytkownik reklamodawcy zaktualizowany',
  add_advertiser_user: 'Dodaj użytkownika reklamodawcy',
  create_advertiser_user: 'Utwórz użytkownika reklamodawcy',
  custom_merchant_domain:
    'Domena SSL niestandardowego menedżera (dla reklamodawców)',
  login_as_advertiser: 'Zaloguj się jako reklamodawca',
  login_as_admin: 'Zaloguj się jako administrator',
  adv_registration_url: 'URL rejestracji reklamodawcy',
  admin: 'Administrator',
  mlm_settings: 'Ustawienia Multi Level Marketingu (MLM)',
  registration_settings: 'Ustawienia rejestracji',
  edit_users: 'Edytuj użytkowników',
  company_setting_alert_title: 'Potwierdzenie zmiany ustawień',
  company_setting_alert_desc:
    'Klikając "OK", potwierdzasz, że nadchodzące zmiany będą miały wpływ na ustawienia firmy',
  block_user: 'Zablokuj użytkownika',
  blocked: 'Zablokowany',
  unblock_user: 'Odblokuj użytkownika',
  include_deleted_affiliates: 'Uwzględnij usuniętych partnerów',
  additional_filters: 'Dodatkowe filtry',
  import_items: 'Importuj przedmioty',
  quantity: 'Ilość',
  total_price: 'Cena całkowita',
  cart: 'Koszyk',
  add_row: 'Dodaj wiersz',
  custom_view: 'Niestandardowy widok',
  save_view: 'Zapisz bieżący widok',
  update_view: 'Aktualizuj widok',
  create_view: 'Utwórz widok',
  deleted: 'Usunięty',
  export_mass_pay_csv: 'Eksportuj masowy plik CSV',
  bulk_payment_wise: 'Masowa płatność Wise',
  mass_pay_paypal: 'Masowa płatność PayPal',
  mass_pay_paypal_action: 'Kliknij tutaj, aby zapłacić za pomocą MASS PAY',
  trade_length: 'Czas trwania transakcji',
  open_time: 'Czas otwarcia',
  trade_length_helper:
    'Czas trwania transakcji oznacza minimalny czas wymagany do kwalifikowania się do prowizji.',
  import_creatives: 'Importuj kreacje',
  total_earned: 'Całkowite zarobki',
  customMlmEnabled: 'Włącz niestandardowy MLM',
  custom_mlm: 'Niestandardowy MLM',
  mlm_fixed: 'Użyj stałej prowizji',
  js_landing_page_head: 'Nagłówek strony docelowej Javascript',
  js_stripe_payment_links: 'Lokalizacja linków płatności Stripe w js',
  js_stripe_link_page:
    'Reklamodawca powinien umieścić kod na stronie linków płatności. Zmień `initPaymentLink("stripePaymentLinkId")` na ID twojego elementu',
  add_stripe_webhook:
    'Dodaj URL webhooka do Stripe i subskrybuj zdarzenie `checkout.session.completed`.',
  stripe_webhook: 'Webhook Stripe',
  generate_api_key: 'Wygeneruj klucz API',
  expiration_date: 'Data wygaśnięcia',
  admin_override: 'Ominięcie przez administratora',
  affiliate_doc: 'Dokument partnera',
  payment_approved_email: 'Email zatwierdzenia płatności',
  payment_declined_email: 'Email odmowy płatności',
  body: 'Treść',
  blocked_only: 'Tylko zablokowani',
  unblocked_only: 'Tylko odblokowani',
  deleted_only: 'Tylko usunięci',
  active_only: 'Tylko aktywni',
  display_all: 'Wyświetl wszystkie',
  advertiser_status_approved: 'Status reklamodawcy zatwierdzony',
  email_config_activation: 'Aktywacja',
  email_config_reset_password: 'Resetowanie hasła',
  email_config_changed_email: 'Email zmieniony',
  email_config_welcome:
    'Witamy (Email zostanie wysłany tylko, gdy tekst wiadomości istnieje)',
  email_config_request_to_join_campaign_approved:
    'Prośba o dołączenie do kampanii zatwierdzona',
  email_config_request_to_join_campaign_declined:
    'Prośba o dołączenie do kampanii odrzucona',
  email_config_admin_status_approved: 'Status administratora zatwierdzony',
  email_config_admin_status_changed: 'Status administratora zmieniony',
  email_config_payment_request_approved: 'Prośba o płatność zatwierdzona',
  email_config_payment_request_declined: 'Prośba o płatność odrzucona',
  email_config_new_mlm_reg:
    'Nowa rejestracja MLM (Email zostanie wysłany tylko, gdy tekst wiadomości istnieje)',
  email_config_new_reg_lead:
    'Nowy lead rejestracyjny (Email zostanie wysłany tylko, gdy tekst wiadomości istnieje)',
  email_config_validation_message: 'Email musi zawierać następujące makra: {0}',
  hard_delete: 'Trwale usunięcie',
  approved_mlm_level_x: 'Zatwierdzony poziom MLM {0}',
  pending_mlm_level_x: 'Oczekujący poziom MLM {0}',
  level: 'Poziom',
  approved_commission: 'Zatwierdzona prowizja',
  pending_commission: 'Oczekująca prowizja',
  restore: 'Przywróć',
  free_trial_ending: 'Kończenie okresu próbnego {0}',
  affiliate_promotional_material: 'Zgoda na materiały promocyjne',
  agree_to_promotional_material: 'Materiały promocyjne',
  agree_to_receive_marketing_material:
    'Zgadzam się na otrzymywanie materiałów marketingowych',
  privacy_page_message:
    'Proszę pamiętać, że wyrażenie zgody na następujące warunki i politykę prywatności jest obowiązkowe, aby być zgodnym z GDPR. Możesz wycofać swoją zgodę w dowolnym momencie. Jednakże, zostaniesz automatycznie wylogowany z systemu.',
  marketing_material: 'Materiały marketingowe',
  i_have_read_and_agreed: 'Przeczytałem i zgadzam się z',
  confirmation_required: 'Wymagana potwierdzenie',
  by_unchecking_you_will_not_have_access_to_the_system:
    'Odznaczenie pola „Warunki użytkowania” lub „Polityka prywatności” spowoduje brak dostępu do systemu. Aby kontynuować, proszę wprowadzić swoje hasło.',
  delete_my_account: 'Usuń moje konto',
  delete_my_account_confirmation:
    'Klikając „Potwierdź”, trwale usuniesz swoje konto i wszystkie powiązane dane. Ta operacja jest nieodwracalna.',
  custom_status: 'Niestandardowy status',
  integrations: 'Integracje',
  edit_tracking_domain: 'Edytuj domenę śledzenia',
  add_tracking_domain: 'Dodaj domenę śledzenia',
  tracking_domain: 'Domena śledzenia',
  tracking_domains: 'Domeny śledzenia',
  default_tracking_domain: 'Domyślna domena śledzenia',
  set_up: 'Skonfiguruj',
  advertiser_features: 'Funkcje reklamodawcy',
  automation: 'Automatyzacja',
  roles_and_permissions: 'Role i uprawnienia',
  invoicing_system: 'System fakturowania',
  seo: 'SEO',
  seo_index: 'Zaindeksowane',
  affiliate_emails: 'Emaile afiliacyjne',
  customize: 'Dostosuj',
  send_manager_alert_on_new_affiliate_payout:
    'Wyślij alert emailowy do menedżera o nowej wypłacie afilianta',
  send_manager_alert_on_new_affiliate:
    'Wyślij alert emailowy do menedżera o nowej rejestracji afilianta',
  profile_pic: 'Zdjęcie profilowe',
  skype: 'Skype',
  skype_username: 'Nazwa użytkownika Skype',
  linkedin: 'Linkedin',
  linkedin_profile: 'Link do profilu LinkedIn',
  whatsapp: 'Whatsapp',
  contact_info: 'Informacje kontaktowe',
  contact_info_desc:
    'Szczegóły kontaktowe będą wyświetlane twoim afiliantom. Puste pola pozostaną ukryte.',
  count: 'Konwersje',
  top_countries: 'Najlepsze kraje',
  custom_statuses: 'Niestandardowe statusy',
  add_custom_status: 'Dodaj niestandardowy status',
  add_category: 'Dodaj kategorię',
  edit_category: 'Edytuj kategorię',
  edit_custom_status: 'Edytuj niestandardowy status',
  top_symbols: 'Najlepsze symbole',
  display_conversion_personal_info: 'Wyświetl informacje osobiste konwersji',
  display_conversion_personal_info_helper:
    'Wyświetl email, imię, nazwisko i numer telefonu',
  primary_color: 'Kolor główny',
  secondary_color: 'Kolor dodatkowy',
  dark_mode: 'Tryb ciemny',
  light_mode: 'Tryb jasny',
  free_mode: 'Tryb bezpłatny',
  use_a_semicolon:
    'Użyj średnika, aby dodać wiele par walutowych, na przykład: AUDCAD;EURUSD',
  new_symbols_will_be_added:
    'Nowe symbole będą automatycznie dodane z pipem 4 (można to później zmienić)',
  group_name: 'Nazwa grupy',
  send_as_newsletter: 'Wyślij jako newsletter',
  translations: 'Tłumaczenia',
  edit_translation: 'Edytuj tłumaczenie',
  platform_translation_management: 'Zarządzanie tłumaczeniami',
  platform_translation_desc:
    'Zarządzaj i dostosuj tłumaczenia. Kliknij dwukrotnie dowolną komórkę, aby nadpisać tłumaczenia dla określonych języków. Pamiętaj, że zmiany są Twoją odpowiedzialnością i mogą wpłynąć na doświadczenie użytkownika. Upewnij się, że zastępniki (np. {placeholder} lub [1]) pozostaną niezmienione, aby dane były wyświetlane poprawnie.',
  sent: 'Wysłane',
  send_test_email: 'Wyślij testowy email',
  send_as_newsletter_desc:
    'Ten newsletter zostanie wysłany do wszystkich aktywnych afiliantów (jeśli kampania jest wybrana, tylko afilianci w kampanii otrzymają email). Ta akcja jest nieodwracalna i działa tylko wtedy, gdy firma ma skonfigurowany SMTP lub Alias Amazon SES.',
  send_as_newsletter_desc_test:
    'Ten newsletter zostanie wysłany na Twój email. Ta akcja jest nieodwracalna i działa tylko wtedy, gdy firma ma skonfigurowany SMTP lub Alias Amazon SES.',
  ip_cooldown: 'Okres chłodzenia IP',
  ip_cooldown_helper:
    'Okres oczekiwania pomiędzy każdą konwersją dla danego adresu IP.',
  instant: 'Brak chłodzenia',
  one_hour: '1 godzina',
  one_day: '1 dzień',
  one_week: '1 tydzień',
  one_month: '1 miesiąc',
  one_year: '1 rok',
  display_accessibility_menu: 'Wyświetl menu dostępności',
  display_deposits_and_withdrawals: 'Domyślnie wyświetlaj depozyty i wypłaty',
  show_deposits: 'Depozyty',
  show_net_deposits: 'Depozyty netto',
  show_withdrawals: 'Wypłaty',
  api_keys: 'Klucze API',
  max_commission: 'Maksymalna prowizja',
  affiliate_display_options: 'Opcje wyświetlania afiliantów',
  block_filter: 'Filtr blokad',
  min_deposit: 'Minimalny depozyt',
  max_deposit: 'Maksymalny depozyt',
  ftd_only: 'FTD',
  contact_platform: 'Messenger',
  lifetime_affiliates: 'Afilianci na całe życie',
  customer_id: 'Identyfikator klienta',
  new_payout_group: 'Nowa grupa wypłat',
  payout_grouping: 'Grupowanie wypłat',
  payout_grouping_desc:
    'Grupy wypłat są dedykowane do szybkiego i efektywnego zarządzania przydzielaniem wypłat',
  select_the_countries_informational:
    'Wybierz kraje, które chcesz uwzględnić lub wykluczyć w swoim dashboardzie wyszukiwania afiliantów. Wybór ten jest tylko informacyjny i pomoże afiliantowi znaleźć odpowiednią ofertę.',
  mlm_type: 'Rodzaj MLM',
  brand_banner: 'Baner marki',
  ftd_amount: 'Kwota FTD',
  ftd_exclusive: 'Tylko FTD',
  ftd_exclude: 'Wyklucz FTD',
  displayCustomIdPicker: 'Wyświetl wybór niestandardowego ID',
  add_to_dashboard: 'Dodaj do dashboardu',
  remove_from_dashboard: 'Usuń z dashboardu',
  edit_new_creative: 'Edytuj nową kreatywność',
  click_funnel_webhook: 'Webhook Clickfunnel',
  add_clickfunnel_webhook:
    'Dodaj URL webhooka do klasycznych webhooków Clickfunnel i subskrybuj zdarzenie `purchase_created`. Pamiętaj! Konieczne jest dodanie parametru ?aff_sub={click_id} do URL śledzenia.',
  remove_affiliate_withdrawal_request: 'Usuń żądanie wypłaty afilianta',
  remove_withdrawal_info_helper:
    'Po włączeniu tej opcji, powiązany przycisk żądania wypłaty zostanie usunięty z portalu afilianta.',
  leads: 'Leady',
  lead_created: 'Lead utworzony',
  lead_updated: 'Lead zaktualizowany',
  add_lead: 'Dodaj lead',
  create_lead: 'Utwórz lead',
  edit_lead: 'Edytuj lead',
  NEW: 'Nowy',
  CONTACTED: 'Skontaktowany',
  QUALIFIED: 'Zakwalifikowany',
  CONVERTED: 'Przekształcony',
  CLOSED: 'Zamknięty',
  UNQUALIFIED: 'Niezakwalifikowany',
  holdPeriod: 'Okres oczekiwania',
  holdPeriod_helper:
    'Nasz system aktualizuje konwersje na podstawie Twoich ustawień co około 15 minut. Wszystkie konwersje, które osiągną okres oczekiwania, zostaną automatycznie zaktualizowane na "ZAAPROBOWANE".',
  campaign_name_clone: 'Potwierdź klonowanie {campaignTitle}',
  clone: 'Klonuj',
  caps: 'Limity',
  cap_type: 'Typ limitu',
  priority: 'Priorytet',
  priority_helper:
    'Wyższy priorytet oznacza wyższe miejsce w filtrze limitu, gdy żadna kampania nie jest wysyłana',
  timeframe: 'Ramka czasowa',
  value: 'Wartość',
  campaign_clone_message:
    'Zaraz sklonujesz tę kampanię. Proszę zwrócić uwagę na następujące punkty: \n \u200B \n1. Afilianci nie będą klonowani. \n2. Tylko ustawienia związane z śledzeniem, konfiguracją ogólną i wypłatami będą kopiowane.\n3. Sklonowana kampania będzie wstrzymana. \n \u200B \n Czy na pewno chcesz kontynuować klonowanie?',
  campaign_payout_clone_message:
    'Zaraz sklonujesz tę wypłatę. \nProszę potwierdzić, aby kontynuować proces klonowania.',
  CONVERSION_COUNT_desc:
    'Ogranicza liczbę konwersji (np. sprzedaży), które afiliant może wygenerować w określonym okresie.',
  CONVERSION_COMMISSION_SUM_desc:
    'Ustala maksymalną całkowitą wypłatę, którą afiliant może zarobić w prowizjach.',
  LEAD_desc:
    'Kontroluje liczbę potencjalnych leadów, które afiliant może dostarczyć.',
  CONVERSION_COUNT: 'Konwersje',
  CONVERSION_COMMISSION_SUM: 'Suma prowizji afilianta',
  LEAD: 'Leady',
  HOUR: 'Godzina',
  DAY: 'Dzień',
  WEEK: 'Tydzień',
  MONTH: 'Miesiąc',
  ALL: 'Wszystko',
  CONVERSION_CREATED: 'Konwersja utworzona',
  CONVERSION_UPDATED: 'Konwersja zaktualizowana',
  LEAD_CREATED: 'Lead utworzony',
  LEAD_UPDATED: 'Lead zaktualizowany',
  unique_headerKey: 'Każdy nagłówek musi mieć unikalny klucz',
  headers: 'Nagłówki',
  header_key: 'Klucz nagłówka',
  header_value: 'Wartość nagłówka',
  status_code: 'Kod statusu',
  add_header: 'Dodaj nagłówek',
  campaign_cpas_subtitle: 'Dostępne typy limitów:',
  campaign_cpas_subtitle2:
    'Afilianci z wyższym priorytetem mogą wysyłać lub dostarczać więcej konwersji, leadów lub kliknięć, aż osiągną swój limit. Po osiągnięciu limitu dodatkowe leady, konwersje lub kliknięcia będą odrzucane. Zapewnia to, że najlepsi afilianci mogą maksymalizować swój potencjał, jednocześnie kontrolując koszty i jakość dla klientów.',
  campaign_cpas_disclamer:
    'Proszę pamiętać, że resetowanie i obliczanie tych limitów jest synchronizowane globalnie z czasem UTC, a nie dostosowane do lokalnej strefy czasowej klienta lub afilianta.',
  campaign_cpas_title:
    'Funkcja limitów z priorytetami pozwala na zarządzanie konwersjami, leadami i kliknięciami na podstawie ustalonych limitów (caps) i przypisanych priorytetów. Ta funkcja umożliwia efektywne przyciąganie konwersji, kliknięć i leadów z priorytetem, jednocześnie zapobiegając przekroczeniu budżetu.',
  allow_api_keys_by_default: 'Zezwól na klucze API domyślnie',
  allow_leads_by_default: 'Zezwól na leady domyślnie',
  allow_api_keys_helper: 'Ta zmiana nie wpłynie na istniejących afiliantów',
  automatically: 'Automatycznie',
  by_affiliate_request: 'Na żądanie afilianta',
  choose_how_to_handle_payouts:
    'Wybierz sposób obsługi wypłat. Automatycznie lub na żądanie afilianta.',
  period_type: 'Typ okresu',
  day_of_month: 'Dzień miesiąca',
  day_of_week: 'Dzień tygodnia',
  allow_custom_amount_helper:
    'Włącz tę opcję, aby umożliwić afiliantom ręczne wpisywanie kwoty wypłaty.',
  allow_attachment_helper:
    'Włącz tę opcję, aby umożliwić dołączanie załączników do wniosków o wypłatę.',
  allow_enter_amount: 'Zezwól na wpisanie kwoty',
  allow_an_attachment: 'Zezwól na załącznik',
  payme: 'Karta kredytowa',
  update_payment_method: 'Zaktualizuj metodę płatności',
  add_payment_method: 'Dodaj metodę płatności',
  form_editor: 'Edytor formularzy',
  form_preview: 'Podgląd formularza',
  lifetime_lifecycle: 'Cykl życia na całe życie',
  lifetime_lifecycle_helper:
    'Dodaje datę wygaśnięcia dla nowych afiliantów na całe życie. Zmiana ta dotyczy tylko nowych afiliantów, którzy zostaną utworzeni po wdrożeniu tej polityki.',
  has_expiration_date: 'Ma datę wygaśnięcia',
  subscriptionMessage:
    'Na Twoje konto zostanie naliczona opłata cykliczna w wysokości {price}.',
  subscriptionMessage2:
    '* Ta opłata będzie pobierana, dopóki nie zdecydujesz o anulowaniu subskrypcji.',
  invoice_details: 'Szczegóły faktury',
  deepUrlParams: 'Parametry zapytania URL',
  deepUrlParams_helper:
    'Parametry zapytania, które zostaną dołączone do głębokiego URL, dodane do linku śledzenia. Na przykład: "is_deep_link=1&click_id={click_id}". Uwaga, makra są dozwolone.',
  tiers_migration: 'Poziomy',
  bonus: 'Bonus',
  threshold_reward_type: 'Typ nagrody',
  campaign_payout_automation_bonuses: 'Automatyczne wypłaty kampanii - Bonusy',
  recurring_bonus: 'Bonus cykliczny',
  recurring_bonus_helper_text:
    'Po włączeniu bonus jest obliczany na podstawie ramy czasowej. Po wyłączeniu jest to jednorazowy bonus na afilianta.',
  estimated_original_price: 'Szacowana cena oryginalna',
  conversion_rate: 'Wskaźnik konwersji',
  deposits_count: 'Dep.',
  registrations_count: 'Rejestr.',
  deposits_amount: 'Kwota depozytu',
  igaming_reports: 'Raporty IGaming',
  clicks_to_registrations: 'CTR',
  registrations_to_deposits: 'Rejestr. do Dep.',
  clicks_to_deposits: 'Kliknięcia do Dep.',
  fraud_score: 'Wynik oszustwa',
  send_credentials_by_mail: 'Wyślij dane logowania na e-mail',
  import_conversions: 'Importuj konwersje',
  referer_id: 'Identyfikator referera',
  sendWebhook: 'Wyślij Webhook',
  resend_webhook: 'Wyślij ponownie Webhook',
  in_progress: 'W trakcie',
  mass_pay_tipalti: 'Płatność masowa Tipalti',
  calculate_tax: 'Oblicz podatek',
  add_current_page: 'Dodaj bieżącą stronę',
  add_shortcut: 'Dodaj skrót',
  search_icons: 'Wyszukaj ikony',
  edit_shortcut: 'Edytuj skrót',
  latest_leads: 'Najnowsze leady',
  shortcut_url_helper:
    'Użyj linków wewnętrznych (/app/...), zewnętrznych linków lub pozostaw bieżący URL.',
  shortcuts_helper: 'Dodaj skróty do szybkiego dostępu',
  featured_helper: 'Ta opcja utrzyma kampanię na górze innych kampanii.',
  calculate_tax_helper: 'Ta opcja zastosuje obniżenie podatku do kwoty.',
  postback: 'Postback',
  custom_ref_id: 'Niestandardowy identyfikator referera',
  import_symbols: 'Importuj symbole',
  update_conversions: 'Aktualizuj konwersje',
  if_commission_not_specified:
    'Jeśli nie określono, prowizje będą automatycznie dostosowane w zależności od kwoty.',
  copy_campaign_reg_link: 'Link rejestracyjny kampanii',
  campaign_reg_description:
    'Zauważ, że afilianci zarejestrowani za pomocą linku rejestracyjnego kampanii będą automatycznie przypisani do kampanii w trybie pojedynczej kampanii.',
  should_affiliates_change:
    'Czy chcesz zastosować zmianę IB do wszystkich kont w ramach klienta?',
  reset_logo: 'Resetuj logo',
  pending_approvals: 'Oczekujące zatwierdzenia',
  what_is_your_business_type: 'Jaki jest Twój typ działalności?',
  request_an_account: 'Zażądaj konta',
  join_requests: 'Wnioski o dołączenie',
  min_amount: 'Minimalna kwota',
  min_amount_helper: 'Minimalna kwota wymagana do aktywacji płatności',
  view_more: 'Zobacz więcej',
  click_date: 'Data kliknięcia',
  email_username: 'E-mail (Nazwa użytkownika opcjonalna)',
  email_userName_helper:
    'Tylko e-mail może być użyty do logowania się do portalu afiliacyjnego',
  wechat: 'WeChat',
  wechat_id: 'Identyfikator WeChat',
  last_trade_date: 'Data ostatniej transakcji',
  positive_carry: 'Pozytywne przeniesienie',
  ngr: 'NGR',
  positive_carry_helper:
    'Pozytywne przeniesienie automatycznie dostosuje każdy ujemny bilans do zera, pozytywny bilans wypłat pozostanie niezmieniony.',
  stats: 'Podgląd',
  field_name_html_override: 'Nadpisanie wartości HTML',
  brand_profit: 'Zysk marki',
  hide_short_link: 'Ukryj krótki link (Afiliant)',
  save_all_pixels: 'Zapisz wszystkie piksele (Nierekomendowane)',
  save_all_pixels_helper:
    '(Nierekomendowane) Pozwala na zapisanie konwersji na każdym pikselu lub postbacku, niezależnie od tego, czy jest przypisany do afilianta. Oznacza to, że konwersje będą zapisywane, nawet jeśli nie można ich przypisać do konkretnego afilianta.',
  campaignMustMatch: 'Dopasowanie kampanii',
  campaignMustMatch_helper:
    'Po włączeniu tej opcji, system przypisze konwersję do kampanii dopiero po kliknięciu klienta w URL kampanii afilianta.',
  useCouponOverTracking: 'Nadpisanie kuponu',
  useCouponOverTracking_helper:
    'Kody kuponów mają pierwszeństwo przed śledzeniem afiliacyjnym. Konwersje będą przypisane do afilianta powiązanego z kuponem, a nie do afilianta śledzącego.',
  allowAffiliatesToSetGoal: 'Zezwól na nadpisanie celu',
  allowAffiliatesToSetGoal_helper:
    'Po włączeniu, wartość celu może być nadpisana przez URL śledzenia w celu przypisania do niego konkretnej wypłaty.',
  campaign_categories: 'Kategorie Kampanii',
  categories: 'Kategorie',
  default_language_label: 'Domyślny język',
  select_default_language: 'Wybierz domyślny język',
  preferred_languages_label: 'Preferowane języki',
  affiliate_portal_languages: 'Języki platformy afiliacyjnej',
  default_language_helper:
    'Będzie to domyślny język dla nowych użytkowników, gdy po raz pierwszy wejdą na portal. Pojawi się również jako pierwsza opcja w rozwijanym menu wyboru języka.',
  preferred_languages_helper:
    'Wybierz języki, które będą dostępne w Twoim portalu. Jeśli pozostawisz to puste, będą dostępne wszystkie obsługiwane języki. Uwaga: jeśli wybrany zostanie tylko jeden język, użytkownicy nie będą mieli opcji zmiany języka w portalu.',
  affiliate_group: 'Grupa afiliacyjna',
  add_affiliate_group: 'Dodaj grupę afiliacyjną',
  edit_affiliate_group: 'Edytuj grupę afiliacyjną',
  custom_group_by: 'Niestandardowy',
  allow_balance_by_default: 'Zezwól na saldo domyślnie',
  allow_leverage_by_default: 'Zezwól na dźwignię domyślnie',
  allow_profit_by_default: 'Zezwól na zysk domyślnie',
  default_settings: 'Domyślne ustawienia uprawnień',
  tax_percent: 'Procent podatku',
  confirm: 'Potwierdź',
  ok: 'OK',
  enable_revision: 'Włącz rewizję',
  revision: 'Rewizja',
  revision_helper:
    'Procent konwersji, które będą oznaczone do rewizji. Te konwersje nie będą widoczne dla afiliantów ani nie będą odzwierciedlone w ich saldach, dopóki nie zostaną sprawdzone i zaktualizowane przez menedżera.',
  revision_percentage: 'Procent rewizji',
  landing_page: 'Strona docelowa',
  default_landing_page: 'Domyślna strona docelowa',
  total_commission_with_tax: 'Całkowita prowizja z podatkiem',
  affiliate_email_preferences: 'Preferencje e-mail afilianta',
  receive_new_registration_lead_email: 'Nowa rejestracja leada',
  receive_new_mlm_registration_email: 'Nowa rejestracja MLM',
  affiliate_tax_percent_helper:
    'Podatek może być dodatni lub ujemny. Jeśli dodatni, kwota podatku zostanie dodana do żądania wypłaty afilianta, jeśli ujemny, zostanie odjęta od żądania.',
  receive_updates:
    'Zgadzam się na otrzymywanie aktualizacji dotyczących produktu, nowości i specjalnych ofert',
  available_balance: 'Dostępne saldo',
  affiliate_id: 'ID afilianta',
  campaign_tracking_tools: 'Narzędzia śledzenia kampanii',
  campaign_setup: 'Ustawienia/Integracja kampanii',
  affiliate_assignment: 'Przypisanie afilianta',
  min_revision_fraud_score: 'Minimalny wynik oszustwa rewizji',
  min_revision_fraud_score_helper:
    'Konwersje z wynikiem oszustwa równym lub wyższym niż ten status będą oznaczone statusem rewizji.',
  min_denied_fraud_score: 'Minimalny wynik oszustwa odmowy',
  min_denied_fraud_score_helper:
    'Konwersje z wynikiem oszustwa równym lub wyższym niż ten status będą oznaczone statusem odmowy.',
  disable_2fa: 'Wyłącz 2FA',
  login_hero_title1: 'Dostosowane rozwiązanie',
  login_hero_subtitle1:
    'Nasz produkt dostosowuje się do potrzeb Twojej marki, zaktualizuj swoje rozwiązanie afiliacyjne już dziś.',
  login_hero_title2: 'Zaawansowane zasady prowizji',
  login_hero_subtitle2:
    'Dostosowane prowizje według afilianta, grup, poziomów i celów.',
  login_hero_title3: 'Świetne doświadczenie użytkownika',
  login_hero_subtitle3:
    'Zaprojektowany i markowy interfejs oraz wysoce konfigurowalny.',
  login_hero_title4: 'Innowacyjna funkcjonalność',
  login_hero_subtitle4:
    'Zaawansowane funkcje, które wyznaczają nowe standardy, MLM, AntyFraud, Automatyzacja wypłat i więcej!',
  top_campaign_payouts: 'Najlepsze wypłaty kampanii',
  campaign_default_currency: 'Domyślna waluta kampanii',
  campaign_default_currency_helper:
    'Wybierz domyślną walutę dla tej kampanii. Kwoty otrzymane za pomocą postbacku będą początkowo interpretowane w tej walucie, a następnie przeliczone na domyślną walutę firmy, chyba że w parametrach postbacku zostanie określona inna waluta. Pozostaw puste, aby użyć domyślnej waluty firmy.',
  company_grid_control: 'Kontrola układu firmy',
  company_grid_control_helper: `Włącz tę opcję, aby scentralizować układ DataGrid dla wszystkich afiliantów.
Uwaga: Wyłączenie tej opcji przywróci możliwość zarządzania układami siatki przez afiliantów.`,
  company_grid_control_helper2: `Po włączeniu:
** Menedżer może zalogować się jako domyślny afiliant (Afiliant 1), aby skonfigurować widoczność kolumn i ich kolejność.
** Ta konfiguracja będzie miała zastosowanie do wszystkich afiliantów, zapewniając spójny układ siatki na całej platformie.
** Afilianci nie będą mogli dostosować swoich własnych ustawień DataGrid.
Uwaga: Wyłączenie tej opcji przywróci możliwość zarządzania układami siatki przez afiliantów.`,
  affiliate_managed_groups: 'Grupy zarządzane przez afiliantów',
  affiliate_managed_groups_helper: `Kontroluje dostęp menedżera do afiliantów:
Grupy zarządzane przez afiliantów: Ogranicza dostęp do afiliantów w wybranych grupach.
  * Jeśli określono grupy, menedżer może zarządzać tylko afiliantami w tych grupach.
  * Jeśli pozostawione puste, dostęp zależy od ustawienia „Zarządzaj wszystkimi afiliantami”.
Zarządzanie wszystkimi afiliantami:
  * Jeśli włączone (prawda), menedżer może uzyskać dostęp do wszystkich afiliantów w wybranych grupach. 
    Jeśli nie określono grup, menedżer ma dostęp do wszystkich afiliantów w systemie.
  * Jeśli wyłączone (fałsz), menedżer może uzyskać dostęp tylko do afiliantów, którymi bezpośrednio zarządza w wybranych grupach.
    Jeśli nie określono grup, dostęp jest ograniczony do afiliantów, którymi menedżer bezpośrednio zarządza.`,
  balance_dates: 'Daty sald',
  payout_dates: 'Daty wypłat',
  total_pending: 'Całkowita oczekująca kwota',
  total_available: 'Całkowita dostępna kwota',
  requireInviteForRegistration: 'Wymagaj zaproszenia do rejestracji',
  requireInviteForRegistration_helper:
    'Tylko afilianci, którzy używają URL do zaproszeń MLM, mogą się zarejestrować. Funkcja MLM musi być aktywowana, aby URL zaproszenia MLM był widoczny dla afiliantów.'
};
