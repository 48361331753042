import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import { Centrifuge } from 'centrifuge';
import { useSelector } from 'react-redux';
import { companySelector, userSelector } from '../selectors/selectors';
import authService from '../services/authService';
import { getCurrenUserSessionToken } from 'src/actions/configActions';

const SocketContext = createContext(null);
let centrifugeInstance = null; // Singleton instance

export const useSocket = () => {
  return useContext(SocketContext);
};

const MAX_RETRIES = 3;
export const SocketProvider = ({ children }) => {
  const [lastMessage, setLastMessage] = useState(null);
  const oid = useSelector(companySelector)?.oid;
  const userSerialOid = useSelector(userSelector)?.userSerialOid;
  const subscriptionRef = useRef(null);
  const listenersMap = useRef({});
  const reconnectAttempts = useRef(0);
  let token = authService.getAccessToken();
  const roomId = `${userSerialOid}_${oid}`;

  const handleReconnect = () => {
    if (reconnectAttempts.current >= MAX_RETRIES) {
      console.error('Max reconnection attempts reached');
      return;
    }

    reconnectAttempts.current += 1;

    setTimeout(() => {
      centrifugeInstance.connect();
    }, 1000);
  };

  async function getToken() {
    if (!authService?.isValidToken(authService.getAccessToken())) {
      token = await authService.refreshToken(token);
    }
    return token;
  }

  useEffect(() => {
    if (!token || !roomId || !userSerialOid || !oid) return;

    if (!centrifugeInstance) {
      centrifugeInstance = new Centrifuge(process.env.REACT_APP_WEBSOCKET_URL, {
        token,
        getToken: getToken
      });

      centrifugeInstance.on('connected', () => {
        // console.log('Connected to WebSocket');
      });

      centrifugeInstance.on('disconnected', () => {
        console.warn('Disconnected from WebSocket, attempting to reconnect');
        handleReconnect();
      });

      centrifugeInstance.connect();
    }

    if (!subscriptionRef.current) {
      let subscription;
      try {
        subscription = centrifugeInstance.newSubscription(roomId);
      } catch (e) {
        console.error(e);
        subscription = centrifugeInstance?.getSubscription(roomId);
        if (!subscription) {
          console.log('could not get old connection');
          return;
        }
      }

      subscription.on('publication', ctx => {
        const { event, data } = ctx.data;
        const eventId = event?.id;
        setLastMessage({
          eventId,
          data
        });

        if (listenersMap.current[eventId]) {
          listenersMap.current[eventId].forEach(callback => {
            callback(ctx);
          });
        }
      });

      subscription.subscribe();
      subscriptionRef.current = subscription;
    }

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null;
      }
      listenersMap.current = {};
    };
  }, [token, roomId]);

  const useEventSubscription = initialEventId => {
    const [eventId, setEventId] = useState(initialEventId);
    const [eventMessage, setEventMessage] = useState(null);

    useEffect(() => {
      if (!eventId) return;
      const handleMessage = data => {
        setEventMessage(data);
      };

      if (!listenersMap.current[eventId]) {
        listenersMap.current[eventId] = [];
      }
      listenersMap.current[eventId].push(handleMessage);

      return () => {
        listenersMap.current[eventId] = listenersMap.current[eventId].filter(
          listener => listener !== handleMessage
        );

        if (listenersMap.current[eventId].length === 0) {
          delete listenersMap.current[eventId];
        }
      };
    }, [eventId]);

    return {
      eventMessage,
      setEventId
    };
  };

  return (
    <SocketContext.Provider
      value={{
        lastMessage,
        useEventSubscription
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
