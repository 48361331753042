const serverPrefix = '/api';
const managerPrefix = '/manager';
const socialPrefix = serverPrefix + '/social';
const companyPrefix = '/company';
const countriesGroupingPrefix = '/country-groupings';
const symbolGroupingPrefix = '/symbol-groupings';
const forexPrefix = serverPrefix + managerPrefix + '/forex';
const settingsPrefix = '/settings';
const customStatusesUrl =
  `${serverPrefix}${managerPrefix}${companyPrefix}` + '/custom-statuses/';
const mlmSettingsUrl = `${serverPrefix}${managerPrefix}${companyPrefix}${settingsPrefix}/mlm`;
const campaignCategories = `${serverPrefix}${managerPrefix}/campaigns/categories`;
const userCategories = `${serverPrefix}${managerPrefix}/affiliates/categories`;
const ApiPaths = {
  adminLogin: '/admin-login',
  platformTranslations: `${serverPrefix}/translation-keys/platform`,

  datagrid: {
    root: `${serverPrefix}/datagrid/state`,
    id: id => `${serverPrefix}/datagrid/state/${id}`
  },
  twoFactor: '/2fa',
  lifetimeAffiliates: 'lifetime',
  audit: `${serverPrefix}/audit`,
  clientData: '/client-data',
  shortUrl: `${serverPrefix}/url-shortener`,
  termsOfService: `${serverPrefix}${managerPrefix}/terms-of-service`,
  configActions: {
    config: `${serverPrefix}/config`,
    companyInitialData: `${serverPrefix}/company/initial-data`,
    domainInitialData: `${serverPrefix}/initial-data`,
    companyNotifications: `${serverPrefix}${managerPrefix}/company/notifications`,
    integrations: {
      root: `${serverPrefix}/integrations`,
      byId: id => `${serverPrefix}/integrations/${id}`,
      oAuth: id => `${serverPrefix}/integrations/${id}/oAuth`
    }
  },
  notes: {
    get: uid => `${serverPrefix}${managerPrefix}/user-management/${uid}/notes`
  },
  blockUser: uid =>
    `${serverPrefix}${managerPrefix}/user-management/${uid}/block`,
  userCustomMlm: uid =>
    `${serverPrefix}${managerPrefix}/user-management/${uid}/mlm`,

  permissions: {
    get: uid =>
      `${serverPrefix}${managerPrefix}/user-management/${uid}/permissions`
  },
  apiKeys: {
    root: `${serverPrefix}${managerPrefix}/apiKeys`,
    edit: uuid => `${serverPrefix}${managerPrefix}/apiKeys/${uuid}`
  },
  docs: {
    company: { get: '/api/documents/company' },
    download: uuid => `/api/documents/${uuid}`,
    user: {
      upload: (uuid, usid) => `/api/documents/upload-docs/${uuid}?usid=${usid}`
    },
    updateStatus: uuid => `/api/documents/${uuid}`
  },
  contactUs: `${serverPrefix}${managerPrefix}/contact-us`,
  companyActions: {
    trafficSources: `${serverPrefix}${managerPrefix}${companyPrefix}/traffic-sources`,
    advertisers: `${serverPrefix}${managerPrefix}${companyPrefix}/adv`,
    currency: `${serverPrefix}${managerPrefix}${companyPrefix}/currency`,
    affiliates: `${serverPrefix}${managerPrefix}${companyPrefix}/affiliates`,
    affiliatesNeedActivation: `${serverPrefix}${managerPrefix}${companyPrefix}/settings/does-affiliate-need-activation`,
    smtp: `${serverPrefix}${managerPrefix}${companyPrefix}/smtp`,
    branding: `${serverPrefix}${managerPrefix}${companyPrefix}/branding`,
    trackingDomains: `${serverPrefix}${managerPrefix}${companyPrefix}/branding/custom-domain/tracking`,
    termsAndConditions: `${serverPrefix}${managerPrefix}${companyPrefix}/terms-and-conditions`,
    privacyPolicy: `${serverPrefix}${managerPrefix}${companyPrefix}/privacy-policy`,
    emailSettings: `${serverPrefix}${managerPrefix}${companyPrefix}/email-settings`,
    settings: `${serverPrefix}${managerPrefix}${companyPrefix}/settings`,
    mlmSettings: mlmSettingsUrl,
    customFields: `${serverPrefix}${managerPrefix}${companyPrefix}/custom-fields`,
    customStatuses: `${customStatusesUrl}`,
    customStatusesAllActive: `${customStatusesUrl}all-active`,
    customDocs: `${serverPrefix}${managerPrefix}${companyPrefix}/custom-docs`,
    paymentMethods: `${serverPrefix}${managerPrefix}${companyPrefix}/payment-methods`,
    membership: {
      root: `${serverPrefix}${managerPrefix}${companyPrefix}/membership`,
      paymentMethod: `${serverPrefix}${managerPrefix}${companyPrefix}/membership/payment-method`
    },
    campaigns: {
      categories: {
        get: `${campaignCategories}`,
        options: `${campaignCategories}/options`,
        toggle: categoryId => `${campaignCategories}/${categoryId}/toggle`
      }
    },
    statistics: {
      dailyStats: {
        all: `${serverPrefix}${managerPrefix}${companyPrefix}/campaign-daily-stats`,
        financial: `${serverPrefix}${managerPrefix}${companyPrefix}/financial-daily-stats/filtered`,
        lastWeek: `${serverPrefix}${managerPrefix}${companyPrefix}/campaign-daily-stats/last-week`,
        filtered: `${serverPrefix}${managerPrefix}${companyPrefix}/campaign-daily-stats/filtered`,
        iGaming: `${serverPrefix}${managerPrefix}${companyPrefix}/igaming-daily-stats/filtered`
      },
      conversions: {
        all: `${serverPrefix}${managerPrefix}${companyPrefix}/conversion-stats`,
        lastWeek: `${serverPrefix}${managerPrefix}${companyPrefix}/conversion-stats/last-week`,
        lastYear: `${serverPrefix}${managerPrefix}${companyPrefix}/conversion-stats/last-year`,
        bulkUpload: `${serverPrefix}${managerPrefix}${companyPrefix}/conversion-stats/bulk-upload`,
        bulkUpdate: `${serverPrefix}${managerPrefix}${companyPrefix}/conversion-stats/bulk-update`,
        filtered: `${serverPrefix}${managerPrefix}${companyPrefix}/conversion-stats/filtered`
      },
      impressions: {
        get: `${serverPrefix}${managerPrefix}${companyPrefix}/impressions`,
        updateStatus: `${serverPrefix}${managerPrefix}${companyPrefix}/impressions/updateStatus`
      },
      leads: {
        get: `${serverPrefix}${managerPrefix}${companyPrefix}/leads`,
        updateStatus: `${serverPrefix}${managerPrefix}${companyPrefix}/leads/updateStatus`
      },
      clicks: {
        get: `${serverPrefix}${managerPrefix}${companyPrefix}/clicks`,
        updateStatus: `${serverPrefix}${managerPrefix}${companyPrefix}/clicks/updateStatus`
      },
      postback: {
        get: `${serverPrefix}${managerPrefix}${companyPrefix}/postback`
      },
      campaignLogs: {
        get: `${serverPrefix}${managerPrefix}${companyPrefix}/campaignLogs`
      }
    }
  },
  newsActions: {
    get: `${serverPrefix}${managerPrefix}/news`,
    macros: `${serverPrefix}${managerPrefix}/news/macros`
  },
  webhooks: {
    get: `${serverPrefix}${managerPrefix}/webhook`,
    send: `${serverPrefix}${managerPrefix}/webhook/send`,
    logs: `${serverPrefix}${managerPrefix}/webhook/logs`,
    logsExcel: `${serverPrefix}${managerPrefix}/webhook/logs/excel`
  },
  userActions: {
    categories: {
      get: `${userCategories}`,
      options: `${userCategories}/options`,
      toggle: categoryId => `${userCategories}/${categoryId}/toggle`
    },
    emailPreferences: {
      get: `${serverPrefix}${managerPrefix}/campaigns/settings/`
    },
    manager: {
      default: `${serverPrefix}${managerPrefix}`,
      campaigns: `${serverPrefix}${managerPrefix}/campaigns`,
      campaignsSettings: {
        customMlm: campaignOid =>
          `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/mlm`,

        caps: {
          root: campaignOid =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/caps`,
          byId: (campaignOid, payoutOid) =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/caps/${payoutOid}`
        },
        payout: {
          root: campaignOid =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/payout`,
          symbol: campaignOid =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/payout/symbol`,
          delete: (campaignOid, payoutOid) =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/payout/${payoutOid}`,
          affiliates: (campaignOid, payoutOid) =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/payout/${payoutOid}/affiliates`,

          payoutSttingsItem: {
            root: (campaignOid, payoutOid) =>
              `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/payout/${payoutOid}`,
            delete: (campaignOid, payoutOid, itemUid) =>
              `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/payout/${payoutOid}/${itemUid}`
          },
          groupings: {
            root: campaignOid =>
              `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/payout/grouping`,
            available: campaignOid =>
              `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/payout/grouping/available`,
            options: campaignOid =>
              `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/payout/grouping/options`,
            byId: (campaignOid, payoutOid) =>
              `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/payout/grouping/${payoutOid}`
          }
        },

        automation: {
          root: campaignOid =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/automation`,
          options: campaignOid =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/automation/options`,
          delete: (campaignOid, payoutOid) =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/automation/${payoutOid}`
        },

        general: {
          root: campaignOid =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/general`
        },
        tracking: {
          root: campaignOid =>
            `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/tracking`,
          advanced: {
            root: campaignOid =>
              `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/tracking/advanced`,
            delete: (campaignOid, trackingId) =>
              `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/tracking/advanced/${trackingId}`
          },
          social: {
            root: campaignOid =>
              `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/tracking/social`,
            delete: (campaignOid, trackingId) =>
              `${serverPrefix}${managerPrefix}/campaigns/settings/${campaignOid}/tracking/social/${trackingId}`
          }
        }
      },
      advertisers: `${serverPrefix}${managerPrefix}/advertisers`,
      updateUserInfo: `${serverPrefix}${managerPrefix}/update-user-info`,
      companies: `${serverPrefix}${managerPrefix}/companies`,
      countries: `${serverPrefix}${managerPrefix}/countries`,
      affiliates: {
        requests: {
          campaigns: `${serverPrefix}${managerPrefix}/affiliates-requests/campaigns`,
          payouts: `${serverPrefix}${managerPrefix}/affiliates-requests/payouts`
        },
        affiliateManagement: `${serverPrefix}${managerPrefix}/affiliate-management`
      },
      merchants: `${serverPrefix}${managerPrefix}/user-management`
    },
    settings: {
      personalization: `${serverPrefix}/settings/personalization`,
      updateGeneralSettings: `${serverPrefix}/settings/general`,
      updateMerchantGeneralSettings: `${serverPrefix}/settings/current-merchant/general`,
      updateMerchantTheme: `${serverPrefix}/settings/current-merchant/theme`,
      updatePasswordSettings: `${serverPrefix}/settings/password`,
      updateEmailSettings: `${serverPrefix}/settings/email`,
      customMenuShortcuts: `${serverPrefix}/settings/user-menu-shortcut`,
      allowedIps: `${serverPrefix}${managerPrefix}/security/ips`
    },
    registerAffiliate: '/register-affiliate',
    registerAffiliates: '/register-affiliates',
    registerMerchant: '/register-manager',
    registerLead: '/register-lead',
    login: {
      root: 'login',
      adv: '/adv/login'
    },
    loginWithToken: '/login-with-token',
    forgotPassword: '/forgot-password',
    changeForgottenPassword: '/change-forgotten-password',
    resetPassword: '/reset',
    activateUser: '/activate',
    updateUserInfo: `${serverPrefix}update-user-info`,
    updatePassword: `${serverPrefix}change-user-password`,
    updateEmail: `${serverPrefix}change-user-email`,
    uploadUserPic: `${serverPrefix}upload-user-profile-pic`,
    removeUserPic: `${serverPrefix}remove-user-profile-pic`,
    downloadProfileImage: `${serverPrefix}download-user-profile-pic`,
    validateOwnerShip: '/login',
    logout: '/logout',
    refreshToken: '/refresh-token'
  },
  inboxActions: {
    add: `${serverPrefix}add-inbox`,
    edit: `${serverPrefix}update-inbox`,
    remove: `${serverPrefix}remove-inbox`,
    updateFollowing: `${serverPrefix}update-following`,
    getDefaultFilters: `${serverPrefix}get-default-filters`,
    getContactsAddresses: `${serverPrefix}get-contacts-addresses`,
    getInboxesCounters: `${serverPrefix}/get-inboxes-counter`,
    getTags: `${serverPrefix}get-tags`,
    addTag: `${serverPrefix}add-tag`,
    updateTag: `${serverPrefix}update-tag`,
    updateUserInbox: `${serverPrefix}update-user-inbox`
  },
  mailActions: {
    sentMail: `${serverPrefix}send-mail`,
    cancelSent: `${serverPrefix}cancel-mail-sent`,
    saveDraft: `${serverPrefix}save-draft`,
    removeDraft: `${serverPrefix}remove-draft`,
    getInboxEmails: `${serverPrefix}get-emails`,
    getStatuses: `${serverPrefix}get-statuses`,
    updateDetails: `${serverPrefix}update-details`,
    tagEmails: `${serverPrefix}tag-emails`,
    search: `${serverPrefix}search-emails`
  },
  attachmentActions: {
    uploadAttachment: `${serverPrefix}upload-attachment`,
    uploadInlineAttachment: `${serverPrefix}upload-inline-attachment`,
    getAttachment: `${serverPrefix}attachment`,
    removeAttachment: `${serverPrefix}remove-attachment`,
    downloadAttachment: `${serverPrefix}download-attachment`,
    downloadDoc: uuid => `${serverPrefix}/download/${uuid}`
  },
  signatureActions: {
    add: `${serverPrefix}add-signature`,
    update: `${serverPrefix}update-signature`,
    updateName: `${serverPrefix}update-signature-name`,
    delete: `${serverPrefix}delete-signature`,
    get: `${serverPrefix}get-signatures`
  },
  payouts: `${serverPrefix}${managerPrefix}/payouts`,
  dashboard: `${serverPrefix}${managerPrefix}/dashboard`,
  forex: {
    registration: {
      get: `${forexPrefix}/registration`,
      edit: id => `${forexPrefix}/registration/${id}`,
      overview: `${forexPrefix}/registration/overview`
    },
    accounts: {
      get: `${forexPrefix}/accounts`,
      getByID: id => `${forexPrefix}/account/${id}`,
      edit: (uid, id) => `${forexPrefix}/${uid}/edit-account/${id}`,
      create: uid => `${forexPrefix}/${uid}/account`
    },
    transactions: {
      get: `${forexPrefix}/transactions`,
      create: `${forexPrefix}/transaction`,
      getByID: id => `${forexPrefix}/transaction/${id}`
    }
  },
  groupings: {
    countries: {
      options: `${serverPrefix}${countriesGroupingPrefix}/options`,
      getAvailable: `${serverPrefix}${countriesGroupingPrefix}/availableCountries`,
      get: `${serverPrefix}${countriesGroupingPrefix}`,
      save: `${serverPrefix}${countriesGroupingPrefix}`,
      update: uuid => `${serverPrefix}${countriesGroupingPrefix}/${uuid}`
    },
    symbol: {
      getAvailable: `${serverPrefix}${symbolGroupingPrefix}/availableSymbols`,
      getAll: `${serverPrefix}${symbolGroupingPrefix}/allSymbols`,
      get: `${serverPrefix}${symbolGroupingPrefix}`,
      options: `${serverPrefix}${symbolGroupingPrefix}/options`,
      save: `${serverPrefix}${symbolGroupingPrefix}`,
      saveFreeHand: `${serverPrefix}${symbolGroupingPrefix}/symbol/group`,
      updateAutomation: `${serverPrefix}${symbolGroupingPrefix}/symbol/group`,
      symbol: { save: `${serverPrefix}${symbolGroupingPrefix}/symbol` },

      update: uuid => `${serverPrefix}${symbolGroupingPrefix}/${uuid}`
    }
  },
  social: {
    stats: {
      root: `${socialPrefix}/stats`
    },
    refresh: {
      affiliate: oid => `${socialPrefix}/refresh/${oid}`
    },
    posts: {
      root: `${socialPrefix}/posts`
    },
    profile: {
      root: `${serverPrefix}/social/profile`,
      byId: oid => `${socialPrefix}/profile/${oid}`,

      list: `${serverPrefix}/social/profiles`
    }
  }
};

export default ApiPaths;
