import { alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getComplementaryColorIfBright, stringToColor } from 'src/utils/utils';
import Iconify from './iconify';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 2,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    textTransform: 'uppercase'
  },
  primary: {
    color: getComplementaryColorIfBright(theme.palette.primary.main),
    backgroundColor: alpha(theme.palette.primary.main, 0.08)
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.08)
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.08)
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.08)
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.08)
  },
  info: {
    color: theme.palette.info.main,
    backgroundColor: alpha(theme.palette.info.main, 0.08)
  },
  teal: {
    color: '#257180',
    backgroundColor: alpha('#257180', 0.08)
  },
  black: {
    color: '#fff',
    backgroundColor: '#000'
  },
  red: {
    color: '#fff',
    backgroundColor: '#c93e14'
  },
  green: {
    color: '#fff',
    backgroundColor: '#26a743'
  },
  purple: {
    color: '#fff',
    backgroundColor: '#0c0cd5'
  },
  beige: {
    color: '#F2E5BF',
    backgroundColor: alpha('#F2E5BF', 0.08)
  }
}));

function Label({
  className,
  color,
  children,
  icon,
  isPointer = false,
  style = {},
  random = false,
  ...rest
}) {
  const classes = useStyles();
  const randColor = random ? stringToColor(children || '') : undefined;
  const backgroundColor = random ? alpha(randColor, 0.08) : undefined;

  return (
    <span
      className={clsx(
        classes.root,
        {
          [classes[color]]: color && !random // Apply defined color class if not random
        },
        className
      )}
      style={{
        cursor: isPointer ? 'pointer' : 'default',
        color: random ? randColor : undefined, // Apply random color if true
        backgroundColor: random ? backgroundColor : undefined, // Apply random color if true
        ...style
      }}
      {...rest}
    >
      {icon && <Iconify width={25} icon={icon} sx={{ pr: 1 }} />} {children}
    </span>
  );
}
Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success'
  ])
};

Label.defaultProps = {
  className: '',
  color: 'secondary'
};

export default Label;
