/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { colors, createTheme, responsiveFontSizes } from '@mui/material';
import { enUS, heIL } from '@mui/material/locale';
import { adaptV4Theme } from '@mui/material/styles';
import {
  enUS as dataGridenUS,
  heIL as dataGridheIL
} from '@mui/x-data-grid-premium';
import {
  enUS as dateenUS,
  heIL as dateheIL
} from '@mui/x-date-pickers/locales';
import _ from 'lodash';
import {
  getComplementaryColorIfBright,
  isWhiteOrBright
} from 'src/utils/utils';
import { THEMES } from '../constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
const defaultTheme = createTheme();
const baseConfigRTL = {
  direction: 'rtl',
  typography: {
    ...typography,
    fontFamily: '"Open Sans Hebrew", serif;'
  },

  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiDialogTitle: {
      root: {
        fontSize: 20
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const baseConfigLTR = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiDialogTitle: {
      root: {
        fontSize: 20
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themeOverrides = {
  overrides: {
    MuiContainer: {
      root: {
        padding: defaultTheme.spacing(3),
        [defaultTheme.breakpoints.up('lg')]: {
          paddingLeft: 40,
          paddingRight: 40
        }
      }
    },
    MuiBreadcrumbs: {
      root: {
        paddingBottom: defaultTheme.spacing(3)
      }
    },
    MuiCard: {
      root: {
        borderRadius: 12
      }
    },
    MuiCardContent: {
      root: {
        padding: 24
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    }
  }
};

const themeConfigs = [
  {
    name: THEMES.LIGHT.name,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#22223f'
      },
      secondary: {
        main: '#22223f'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: '#33373e'
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK.name,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN.name,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
];

export const getTheme = settings =>
  themeConfigs.find(
    theme => theme.name === settings?.theme || theme.name === settings?.name
  );

export function createCustomTheme(
  settings = {},
  primaryColor,
  secondaryColor,
  primaryDarkColor,
  secondaryDarkColor
) {
  let themeConfig = _.cloneDeep(getTheme(settings));
  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }
  let baseConfig;
  let local;
  // eslint-disable-next-line eqeqeq
  if (settings.direction == 'ltr') {
    baseConfig = baseConfigLTR; // language without region code
    local = [enUS, dateenUS, dataGridenUS];
  } else {
    baseConfig = baseConfigRTL; // language without region code
    local = [heIL, dateheIL, dataGridheIL];
  }
  if (settings?.theme == 'LIGHT') {
    if (primaryColor) {
      themeConfig.palette.primary = { main: primaryColor };
    }
    if (secondaryColor) {
      themeConfig.palette.secondary = {
        main: secondaryColor
      };
    }
  } else {
    if (primaryDarkColor) {
      themeConfig.palette.primary = { main: primaryDarkColor };
    }
    if (secondaryDarkColor) {
      themeConfig.palette.secondary = {
        main: secondaryDarkColor
      };
    }
  }
  let theme = createTheme(
    adaptV4Theme(
      _.merge(themeOverrides, baseConfig, themeConfig, {
        direction: settings.direction
      })
    ),
    ...local
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  if (isWhiteOrBright(theme.palette.primary.main)) {
    theme.components = {
      ...theme.components,
      ...getStyleOverridesForBrightPrimaryColor(theme)
    };
  }

  return theme;
}

const getStyleOverridesForBrightPrimaryColor = theme => {
  const complimentaryColor = getComplementaryColorIfBright(
    theme.palette.primary.main
  );
  return {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: complimentaryColor,
            '& + .MuiSwitch-track': {
              backgroundColor: complimentaryColor
            }
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: complimentaryColor
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked, &.MuiCheckbox-indeterminate': {
            color: complimentaryColor
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-toolbarContainer .MuiButton-root': {
            color: complimentaryColor
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar1Determinate: {
          backgroundColor: complimentaryColor
        },
        bar2Determinate: {
          backgroundColor: complimentaryColor
        },
        bar1Indeterminate: {
          backgroundColor: complimentaryColor
        },
        bar2Indeterminate: {
          backgroundColor: complimentaryColor
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: complimentaryColor
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: complimentaryColor
          }
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          '& .MuiButton-root': {
            borderColor: complimentaryColor,
            color: complimentaryColor
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          color: complimentaryColor,
          borderColor: complimentaryColor,
          '&:hover': {
            borderColor: complimentaryColor
          }
        },
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '& .MuiButton-text': {
            color: complimentaryColor
          }
        }
      }
    }
  };
};

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: { xs: '85%', md: 400 },
  maxWidth: '95%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  maxHeight: '80%',
  overflowY: 'auto',
  p: 2,
  display: 'flex',
  flexDirection: 'column'
};
export const forceLTRStyle = {
  direction: 'ltr /* @noflip */',
  textAlign: 'left /* @noflip */'
};
