export default {
  sign_up: 'Регистрация',
  create_x_account: ' Создать учетную запись {name}.',
  have_an_account: 'Уже есть аккаунт?',
  back_to_home: 'На главную',
  terms: 'условия',
  back: 'Назад',
  disagree: 'не согласен',
  i_agree: 'я согласен',
  i_have_read_the: 'Я прочитал',
  terms_and_conditions: 'Условия и положения',
  create_account: 'Создать аккаунт',
  general_error: 'Общая ошибка',
  sign_in: 'Войти',
  sign_in_on_the_internal_platform: 'Войти в систему управления партнерами',
  create_new_account: 'Создать новый аккаунт',
  forgot_password: 'Забыли пароль',
  advertisers: 'Рекламодатели',
  advertiser: 'Рекламодатель',
  home: 'Домой',
  dashboard: 'Панель управления',
  management: 'Управление',
  export: 'Экспорт',
  everything_in: 'Все в плане {previousPlan} и:',
  includes: 'Включает:',
  import: 'Импорт',
  all_advertisers: 'Все рекламодатели',
  new_advertiser: 'Новый рекламодатель',
  add_advertiser: 'Добавить рекламодателя',
  settings_updated_successfully: 'Настройки успешно обновлены',
  save_changes: 'Сохранить изменения',
  save: 'Сохранить',
  change_email: ' Изменить email',
  email_address: 'Адрес электронной почты',
  confirm_email_address: 'подтвердить адрес электронной почты',
  last_name: 'Фамилия',
  first_name: 'Имя',
  profile: 'Профиль',
  join_requests: 'Запросы на вступление',
  pending_approvals: 'Ожидающие утверждения',
  change_password: 'Изменить пароль',
  password_confirmation: 'Подтверждение пароля',
  password: 'Пароль',
  min_pass_length_message:
    'Вы ввели неверный пароль. Пожалуйста, используйте от 7 до 20 символов.',
  password_required: 'Требуется пароль',
  enter_valid_email: 'Должен быть действительный адрес электронной почты',
  email_required: 'Требуется адрес электронной почты',
  invalid_password_message:
    'Неверный пароль, пожалуйста, введите действительный пароль',
  invalid_format: 'Неверный формат, пожалуйста, следуйте примеру',
  invalid_email_message: 'Неверный адрес электронной почты',
  log_in: 'Войти',
  first_name_validation:
    'Имя может содержать только буквы (a-z), цифры (0-9) и точки (.) [с символами после] и быть длиной от 2 до 20 символов',
  last_name_validation:
    'Фамилия может содержать только буквы (a-z), цифры (0-9) и точки (.) [с символами после] и быть длиной до 20 символов',
  submit: 'отправить',
  emails_must_match: 'Оба адреса электронной почты должны быть одинаковыми',
  campaign: 'Кампания',
  campaigns: 'Кампании',
  all_campaigns: 'Все кампании',
  new_campaign: 'Новая кампания',
  add_campaign: 'Добавить кампанию',
  all: 'Все',
  title: 'Заголовок',
  description: 'Описание',
  status: 'Статус',
  fileStatus: 'Статус файла',
  privacy: 'Конфиденциальность',
  privacy_policy: 'Политика конфиденциальности',
  active: 'Активно',
  disable: 'Отключить',
  disabled: 'Отключено',
  paused: 'Приостановлено',
  public: 'Публично',
  pre_moderation: 'Предварительная модерация',
  private: 'Частно',
  no_results_found: 'Результатов не найдено',
  this_field_is_required: 'это поле обязательно для заполнения',
  actions: 'Действия',
  general: 'Общее',
  tracking: 'Отслеживание',
  traffic_source: 'Источник трафика',
  traffic_sources: 'Источники трафика',
  currency: 'Валюта',
  features: 'Особенности',
  featured: 'Рекомендуемые',
  start_date: 'Дата начала',
  end_date: 'Дата окончания',
  hold_time: 'Время удержания',
  successfully_updated: 'Успешно обновлено',
  successfully_created: 'Успешно создано',
  last_update_newest_first: 'Последнее обновление (сначала новые)',
  last_update: 'Последнее обновление',
  last_update_oldest_first: 'Последнее обновление (сначала старые)',
  total_orders_high_to_low: 'Всего заказов (от большего к меньшему)',
  total_orders_low_to_high: 'Всего заказов (от меньшего к большему)',
  delete: 'Удалить',
  edit: 'Редактировать',
  name: 'Имя',
  website: 'Веб-сайт',
  contact_person: 'Контактное лицо',
  im_or_skype: 'Im / Skype',
  full_name: 'Полное имя',
  address1: 'Адрес 1',
  address2: 'Адрес 2',
  allowed_ips: 'Разрешенные IP',
  note: 'Примечание',
  secure_postback_code: 'Безопасный код обратного вызова',
  vat_code: 'Код НДС',
  zipcode: 'Почтовый индекс',
  true: 'Верно',
  false: 'Ложь',
  update_advertiser: 'Обновить рекламодателя',
  create_advertiser: 'Создать рекламодателя',
  edit_advertiser: 'Редактировать рекламодателя',
  update_campaign: 'Обновить кампанию',
  create_campaign: 'Создать кампанию',
  settings: 'Настройки',
  create_traffic_source: 'Создать источник трафика',
  update_traffic_source: 'Обновить источник трафика',
  edit_traffic_source: 'Редактировать источник трафика',
  add_traffic_source: 'Добавить источник трафика',
  create: 'Создать',
  update: 'Обновить',
  compare_plans: 'Сравнить планы',
  traffic_source_created: 'Источник трафика создан',
  new_company_traffic_source: 'Новый источник трафика компании',
  new_traffic_source: 'Новый источник трафика',
  edit_campaign: 'Редактировать кампанию',
  tracking_url: 'URL отслеживания',
  preview_url: 'URL превью',
  redirect_type: 'Тип перенаправления',
  traffic_back_url: 'URL возврата трафика',
  no_advertisers_found: 'Рекламодатели не найдены',
  no_campaign_create_advertiser:
    'Для создания кампании, пожалуйста, сначала создайте рекламодателя',
  create_traffic_source_first:
    'Для создания кампании сначала нужен источник трафика',
  discounted_prices: 'Сниженные цены',
  email_verified: 'Электронная почта подтверждена',
  unique_ip_only: 'Требуется уникальный IP',
  one_of_ips_invalid:
    'Неверный формат IP-адреса: пожалуйста, введите действительный IP-адрес в правильном формате (например, 192.168.1.1). Убедитесь, что каждый раздел - это число от 0 до 255 и разделено точками.',
  s2s_postback: 'S2S (Обратный вызов)',
  probabilistic_attribution: 'Вероятностная Атрибуция',
  image: 'Изображение',
  js: 'JavaScript',
  iframe: 'Iframe',
  next: 'Далее',
  ip_is_invalid: 'IP недействителен.',
  payout: 'Выплата',
  payouts: 'Выплаты',
  revenue: 'Доход',
  goal_value: 'Цель Значение',
  goal_title: 'Цель Название',
  payment_type: 'Тип Платежа',
  countries: 'Страны',
  invalid: 'Недействительно',
  general_settings_title: 'Общие Настройки Название',
  general_settings_start_date: 'Общие Настройки Дата Начала',
  general_settings_end_date: 'Общие Настройки Дата Окончания',
  general_settings_hold_time: 'Общие Настройки Время Задержки',
  tracking_settings_integration_s2s_postback:
    'Настройки Отслеживания Интеграция S2s Postback',
  tracking_settings_traffic_back_url:
    'Настройки Отслеживания URL Возврата Трафика',
  tracking_settings_preview_url: 'Настройки Отслеживания Предпросмотр URL',
  tracking_settings_tracking_url: 'Настройки Отслеживания URL Отслеживания',
  payouts_settings_goal_title: 'Настройки Выплат Цель Название',
  payouts_settings_goal_value: 'Настройки Выплат Цель Значение',
  payouts_settings_revenue: 'Настройки Выплат Доход',
  payouts_settings_payouts: 'Настройки Выплат',
  field: 'Поле',
  ip_should_be_unique: 'IP должен быть уникальным',
  required: 'Требуется',
  field_invalid: 'Поле Недействительно',
  general_payout: 'Общая Выплата',
  personal_payout: 'Личная Выплата',
  valid: 'Действительно',
  resource_name_unavailable: 'Имя Ресурса Недоступно',
  cancel: 'Отмена',
  end_date_is_invalid: 'Дата Окончания Недействительна',
  affiliates: 'Партнеры',
  affiliate: 'Партнер',
  click_session_life_span: 'Продолжительность Жизни Сессии Клика',
  security: 'Безопасность',
  password_confirm: 'Подтверждение Пароля',
  current_password: 'Текущий Пароль',
  name_unavailable: 'Имя Недоступно',
  statistics: 'Статистика',
  payouts_soon: 'Выплаты (Скоро)',
  conversions_soon: 'Конверсии (Скоро)',
  affiliates_soon: 'Партнеры (Скоро)',
  campaign_stats: 'Отчеты',
  campaign_id: 'ID Кампании',
  campaign_name: 'Название Кампании',
  hosts: 'Хосты',
  hits: 'Хиты',
  approved: 'Одобрено',
  approve_affiliate: 'Одобрить Партнера',
  reject_affiliate: 'Отклонить Партнера',
  disapproved: 'Отклонено',
  pending: 'В Ожидании',
  declined: 'Отказано',
  decline: 'Отказать',
  cr: 'CR',
  epc: 'EPC',
  traffic: 'Трафик',
  conversions: 'Конверсии',
  finances: 'Финансы',
  filters: 'Фильтры',
  hold: 'Задержка',
  total: 'Всего',
  qty: 'Кол-во',
  null: 'NULL',
  trafficback: 'Возврат Трафика',
  earnings: 'Доход',
  ecpm: 'ECPM',
  clear_filters: 'Очистить Фильтры',
  not_selected: 'Не Выбрано',
  filter: 'Фильтр',
  conversion_id: 'ID Конверсии',
  comment: 'Комментарий',
  user_agent: 'Пользовательский Агент',
  sub_id: 'sub ID',
  sub_id_text: 'sub ID Text',
  sub_1: 'sub1',
  sub_2: 'sub2',
  sub_3: 'sub3',
  sub_4: 'sub4',
  sub_5: 'sub5',
  sub_6: 'sub6',
  sub_7: 'sub7',
  sub_8: 'sub8',
  sub1: 'sub 1',
  sub2: 'sub 2',
  sub3: 'sub 3',
  sub4: 'sub 4',
  sub5: 'sub 5',
  sub6: 'sub 6',
  sub7: 'sub 7',
  sub8: 'sub 8',
  goal_name: 'Название Цели',
  time_period: 'Временной Период',
  income: 'Доход',
  device: 'Устройство',
  geography_ip: 'География / IP',
  amount: 'Сумма',
  date: 'Дата',
  today: 'СЕГОДНЯ',
  yesterday: 'ВЧЕРА',
  last_week: 'ПРОШЛАЯ НЕДЕЛЯ',
  last_two_weeks: 'ПОСЛЕДНИЕ 2 НЕДЕЛИ',
  last_month: 'ПРОШЛЫЙ МЕСЯЦ',
  last_three_months: 'ПОСЛЕДНИЕ 3 МЕСЯЦА',
  last_six_months: 'ПОСЛЕДНИЕ 6 МЕСЯЦЕВ',
  last_year: 'ПРОШЛЫЙ ГОД',
  custom: 'НАСТРОЙКА',
  conversion_stats: 'Статистика Конверсий',
  create_conversion: 'Создать Конверсию',
  update_conversion: 'Обновить Конверсию',
  edit_conversion: 'Редактировать Конверсию',
  all_conversions: 'Все Конверсии',
  add_conversion: 'Добавить Конверсию',
  conversion_created: 'Конверсия Создана',
  conversion_updated: 'Конверсия Обновлена',
  currently_unavailable: 'Временно Недоступно',
  creation_date: 'Дата Создания',
  all_payouts: 'Все Выплаты',
  add_payout: 'Добавить Выплату',
  add: 'Добавить',
  create_payout: 'Создать Выплату',
  denied: 'Отказано',
  number: 'Номер',
  created_at: 'Создано В',
  approved_and_paid: 'Одобрено И Оплачено',
  order_id: 'ID Заказа',
  include_affiliate: 'Включить Партнера',
  amount_to_be_approved: 'Сумма К Одобрению',
  commission_to_be_approved: 'Комиссия К Одобрению',
  edit_payout: 'Редактировать Выплату',
  advertiser_name: 'Имя Рекламодателя',
  total_amount: 'Общая Сумма',
  campaign_start_date: 'Дата Начала Кампании',
  campaign_end_date: 'Дата Окончания Кампании',
  update_payout: 'Обновить Выплату',
  total_commission: 'Общая Комиссия',
  download_report: 'Скачать Отчет',
  file_uploaded_successfully: 'файл успешно загружен',
  download_latest_report: 'Скачать Загруженный Отчет',
  download_original_report: 'Скачать Оригинальный Отчет',
  payout_updated: 'Выплата Обновлена',
  include_commission: 'Включить Комиссию',
  no_report_attached: 'отчет не прикреплен',
  report_is_valid: 'Отчет Действителен',
  view_payout: 'Просмотреть Выплату',
  affiliates_requests: 'Запросы Партнеров',
  requests: 'Запросы',
  approve: 'Одобрить',
  affiliate_payouts: 'Выплаты Партнеров',
  save_and_next: 'Сохранить и Далее',
  available_to_payout: 'Доступно к Выплате',
  request_payout: 'Запросить Выплату',
  commission: 'Комиссия',
  affiliate_commission: 'Комиссия Партнера',
  see_all: 'Смотреть Все',
  clicks: 'Клики',
  more_details: 'Подробнее',
  email: 'Электронная Почта',
  affiliate_website: 'Партнерский веб-сайт',
  disapprove: 'Не одобрять',
  change_to_approved: 'Изменить на Одобрено',
  change_to_declined: 'Изменить на Отклонено',
  contact_details: 'Контактная информация',
  login_as_affiliate: 'Войти как партнер',
  login: 'Вход',
  add_affiliate: 'Добавить партнера',
  create_affiliate: 'Создать партнера',
  edit_affiliate: 'Редактировать партнера',
  try_again_later: 'Попробуйте позже',
  added_affiliate: 'Партнер добавлен',
  affiliate_management: 'Управление партнерами',
  merchant_commission: 'Комиссия',
  phone_number: 'Номер телефона',
  affiliate_updated: 'Партнер обновлен',
  affiliate_created: 'Партнер создан',
  merchant_management: 'Управление',
  user_management: 'Управление пользователями',
  identity: 'Идентичность',
  smtp: 'SMTP',
  edit_merchant: 'Редактировать Управление Пользователями',
  merchant_updated: 'Управление Пользователями Обновлено',
  add_merchant: 'Добавить Управление Пользователями',
  create_merchant: 'Создать Управление Пользователями',
  company_name: 'Партнер',
  company_name_for_invoice: 'Название компании',
  update_merchant: 'Обновить продавца',
  campaign_logo: 'Логотип кампании',
  io_document: 'Документ IO',
  select_logo: 'Выбрать логотип',
  select_io_document: 'Выбрать документ IO',
  img: 'Изображение',
  picture_unavailable: 'Изображение недоступно',
  logo: 'Логотип',
  id: 'ID',
  select_campaign: 'Выбрать кампанию',
  js_landing_page: 'Javascript Целевая Страница',
  js_thank_you: 'Js Спасибо',
  js_landing_page_text:
    'Рекламодатель должен разместить этот код после открывающего тега <body> на целевой странице. Необходимо добавить параметр &click_id={click_id} в URL отслеживания',
  js_thank_you_text:
    'Рекламодатель должен разместить этот код после открывающего тега <body> на странице успеха "Спасибо"',
  end_date_before_start_date: 'Дата окончания раньше даты начала',
  login_email: 'Электронная почта для входа',
  login_password: 'Пароль для входа',
  server: 'Сервер',
  port: 'Порт',
  protocol: 'Протокол',
  smtp_and_ssl: 'SMTP + SSL',
  smtp_and_tls: 'SMTP + TLS',
  created: 'Создано',
  updated: 'Обновлено',
  forgot_password_message:
    'Введите ваш адрес электронной почты ниже, чтобы сбросить пароль.',
  search_affiliate: 'Поиск партнера',
  search_advertisers: 'Поиск рекламодателей',
  search_campaigns: 'Поиск кампаний',
  search_payouts: 'Поиск выплат',
  search_conversions: 'Поиск конверсий',
  search_campaign_request: 'Поиск запросов на кампанию',
  custom_domain: 'Пользовательский домен SSL',
  branding: 'Брендинг',
  request_details: 'Детали запроса',
  search_traffic_source: 'Поиск источника трафика',
  performance_chart: 'Производительность',
  last_conversions: 'Последние конверсии',
  last_30_days: 'ПОСЛЕДНИЕ 30 ДНЕЙ',
  reports: 'Отчеты',
  documents: 'Документы',
  search: 'Поиск',
  SUB1: 'SUB1',
  SUB2: 'SUB2',
  SUB3: 'SUB3',
  SUB4: 'SUB4',
  SUB5: 'SUB5',
  SUB6: 'SUB6',
  SUB7: 'SUB7',
  SUB8: 'SUB8',
  top_campaigns: 'Лучшие Кампании',
  Paused: 'Приостановлено',
  Disabled: 'Отключено',
  logout: 'Выход',
  Active: 'Активно',
  click_session_days: 'Дни сессии клика',
  click_session_hours: 'Часы сессии клика',
  statuses: 'Статусы',
  enter_new_password: 'Введите новый пароль',
  download: 'Скачать',
  not_active: 'Неактивно',
  activate: 'Активировать',
  test_email: 'Отправить тестовое письмо',
  alias: 'Псевдоним',
  smtp_connection_failed_change_details:
    'Не удалось подключиться к SMTP, рассмотрите возможность изменения настроек.',
  send_test_mail: 'Отправить тестовое письмо',
  smtp_connected_successfully: 'SMTP успешно подключен',
  mail: 'Почта',
  header: 'Заголовок',
  footer: 'Нижний колонтитул',
  attribution: 'Присвоение',
  first_click: 'Первый клик',
  last_click: 'Последний клик',
  earn_per_click: 'Заработок за клик',
  click_rate: 'Коэффициент кликов',
  campaign_details: 'Детали кампании',
  disallowed: 'Не разрешено',
  preview: 'Предпросмотр',
  hint: 'Подсказка',
  coupon: 'Купон',
  cname_missing_add_to_dns_manager:
    'CNAME отсутствует, добавьте CNAME в ваш DNS-менеджер.',
  new_affiliate_needs_activation:
    'Новый партнерский Email требует подтверждения',
  new_affiliate_needs_manual_activation:
    'Новому партнеру требуется ручная активация',
  change_manager: 'Сменить менеджера',
  manager: 'Менеджер',
  default_merchant: 'Стандартный продавец',
  merchant: 'Продавец',
  need_help: 'Нужна помощь',
  check_our_docs: 'Посмотрите нашу документацию',
  documentation: 'Документация',
  activation: 'Активация',
  reset_password: 'Сброс пароля',
  invitation: 'Приглашение',
  password_changed: 'Пароль изменен',
  enter_email: 'Введите email',
  and: 'и',
  available_macros: 'Доступные макросы',
  custom_fields: 'Пользовательские поля',
  add_custom_field: 'Добавить пользовательское поле',
  search_custom_field: 'Поиск пользовательского поля',
  edit_custom_field: 'Редактировать пользовательское поле',
  create_custom_field: 'Создать пользовательское поле',
  update_custom_field: 'Обновить пользовательское поле',
  add_custom_doc: 'Добавить пользовательский документ',
  search_custom_doc: 'Поиск пользовательского документа',
  edit_custom_doc: 'Редактировать пользовательский документ',
  create_custom_doc: 'Создать пользовательский документ',
  update_custom_doc: 'Обновить пользовательский документ',
  custom_field_name: 'Название пользовательского поля',
  not_required: 'Не требуется',
  field_name_is_already_used: 'Имя поля уже используется',
  are_you_sure: 'Вы уверены?',
  are_you_sure_you_want_to_delete: 'Вы уверены, что хотите удалить?',
  text_area: 'Текстовое поле',
  check_box: 'Чекбокс',
  input: 'Ввод',
  type: 'Тип',
  field_type: 'Тип поля',
  default_manager: 'Стандартный менеджер',
  brand_logo: 'Логотип бренда',
  reached_max_campaigns_limit: 'Достигнут максимальный лимит кампаний',
  billing: 'Биллинг',
  payments: 'Платежи',
  plan: 'План',
  paypal: 'Paypal',
  wire_transfer: 'Банковский перевод',
  payout_source: 'Источник выплаты',
  price: 'Цена',
  transaction_id: 'ID транзакции',
  view_invoice: 'Просмотр счета',
  upgrade: 'Обновить',
  work_email: 'Рабочий Email',
  message: 'Сообщение',
  contact_us: 'Свяжитесь с нами',
  we_will_contact_u_soon: 'Мы скоро свяжемся с вами!',
  terms_and_conditions_and_privacy_policy:
    'Условия и положения, и Политика конфиденциальности',
  current_email: 'Текущий Email',
  please_update_your_plan: 'пожалуйста, обновите ваш план',
  here: 'здесь',
  custom_domain_updated: 'Пользовательский домен обновлен',
  your_email: 'Ваш Email',
  allowed: 'Разрешено',
  created_merchant: 'Созданный продавец',
  affiliate_company: 'Партнерская компания',
  linked_to_payout: 'Связано с выплатой',
  subject: 'Тема',
  has_payout: 'Есть выплата',
  yes: 'Да',
  no: 'Нет',
  select: 'Выбрать',
  view_conversions: 'Просмотр конверсий',
  search_conversions_by_campaign_affiliate_or_payout:
    'Поиск по названию кампании или названию партнерской компании.',
  traffic_back_explanation:
    'Клиент будет перенаправлен на "URL возврата трафика" в случае неудачи или если кампания отключена.',
  goal_value_explanation_row_1:
    "Значение 'Цели' дает возможность оплачивать события или категории.",
  goal_value_explanation_row_2:
    "Значение 'Цели' должно возвращаться вашим кодом/постбэком.",
  test_smtp_email: 'Тестовый Smtp Email',
  campaign_terms: 'Условия кампании',
  new_email: 'Новый Email',
  payment_details: 'Детали платежа',
  request_date: 'Дата запроса',
  reason: 'Причина',
  affiliate_request: 'Запрос партнера',
  the_reason_will_be_visible_to_the_affiliate: 'Причина будет видна партнеру',
  affiliate_registration_url: 'URL регистрации партнера',
  copy: 'Копировать',
  copied_to_clipboard: 'Скопировано в буфер обмена',
  minimum_withdrawal_amount: 'Минимальная сумма для вывода',
  payment_methods: 'Способы оплаты',
  add_payment_method: 'Добавить способ оплаты',
  search_payment_method: 'Поиск способа оплаты',
  create_payment_method: 'Создать способ оплаты',
  edit_payment_method: 'Редактировать способ оплаты',
  payment_method_created: 'Способ оплаты создан',
  payment_method: 'Способ оплаты',
  powered_by_tracknow: 'На платформе Tracknow',
  change_email_confirmation_email_sent:
    'Подтверждающее письмо отправлено на ваш текущий email',
  check_your_data: 'Ошибка',
  are_you_sure_you_want_to_create_a_payout:
    'Вы уверены, что хотите создать выплату',
  create_payout_confirmation: 'Подтверждение создания выплаты',
  payout_created: 'Выплата создана',
  system_id: 'Системный ID',
  tax: 'Налог',
  number_must_be_between: 'Число должно быть между {min} и {max}',
  ready: 'Готово',
  edit_statuses: 'Редактировать статусы',
  apply_changes_to: 'Применить изменения к ({count})',
  failed: 'Не удалось',
  processing: 'Обработка',
  affiliate_payout: 'Выплата партнера',
  permissions: 'Разрешения',
  enter_new_password_reset:
    'Введите ваш новый пароль ниже, чтобы сбросить ваш пароль.',
  referer: 'Реферер',
  coupon_code: 'Код купона',
  country: 'Страна',
  please_enable_smtp_first: 'Пожалуйста, сначала включите SMTP',
  scale: 'Масштаб',
  rotate: 'Вращать',
  crop: 'Обрезать',
  time_format: 'Формат времени',
  time_zone: 'Часовой пояс',
  billing_description:
    'Откройте для себя страницу счетов платформы партнерского маркетинга Tracknow. Начните свое партнерское путешествие бесплатно с нашего бесплатного плана. Переходите на наши планы Team или Enterprise по мере роста, разблокируя продвинутые функции. Доступные гибкие варианты оплаты. Присоединяйтесь к нам сегодня и раскройте мощь Tracknow!',
  personal_settings: 'Личные настройки',
  company_settings: 'Настройки компании',
  day: 'День',
  year: 'Год',
  month: 'Месяц',
  group_by: 'Группировать по',
  deep_link: 'Прямая ссылка',
  tracking_params: 'Параметры отслеживания',
  order_amount: 'Сумма заказа',
  unlimited_sales: 'Неограниченные продажи',
  up_to_x_affiliates: 'До {0} партнеров',
  ssl_setup: 'Настройка SSL',
  dedicated_smtp: 'Выделенный SMTP',
  custom_commissions: 'Индивидуальные комиссии',
  custom_commission_affiliate: 'Индивидуальные партнерские комиссии',
  email_support: 'Поддержка по электронной почте',
  no_limit_campaigns: 'Неограниченное количество кампаний',
  no_limit_affiliates: 'Неограниченное количество партнеров',
  email_and_chat_support: 'Поддержка по электронной почте и в чате',
  dedicated_account_manager: 'Персональный менеджер аккаунта',
  coupon_offline: 'Отслеживание купонов',
  invoices: 'Счета',
  next_subscription_fee_due: 'Следующий платеж за подписку:',
  you_wiil_return_to_the_free_tier_at:
    'Ваша подписка отменена. Пожалуйста, свяжитесь с нашей поддержкой по адресу support@tracknow.io',
  are_you_sure_you_want_to_reset_cname:
    'Вы уверены, что хотите сбросить настраиваемый домен?',
  reset_cname: 'Сброс настраиваемого домена',
  subscription_details: 'Детали подписки:',
  max_x_capmaigns: '{0} Доменов/Предложений',
  unlimited_campaigns: 'Неограниченное количество доменов/предложений',
  tax_not_included: 'Налог не включен',
  email_for_invoice: 'Электронная почта для счета-фактуры:',
  plans_and_pricing: 'Тарифные планы и цены',
  reset: 'Сброс',
  your_membership_tier: 'Ваш уровень членства:',
  click_here: 'Нажмите здесь',
  advertiser_should_add_the:
    'Рекламодатель должен добавить следующие параметры в URL: order_id, amount, goal, для большего количества вариантов отслеживания, пожалуйста',
  for_a_tutorial_on_how_to_add_postback:
    'Инструкция по добавлению обратной связи, пожалуйста',
  payout_settings: 'Настройки выплат',
  brand_colors: 'Цвета бренда',
  custom_script: 'Пользовательский скрипт',
  getting_started: 'Начало работы',
  done: 'Готово',
  deleted_successfully: 'Успешно удалено',
  creatives: 'Творческие элементы',
  add_new: 'Добавить новое',
  create_a_campaign: 'Создать кампанию',
  adding_an_affiliate: 'Добавление партнера',
  affiliate_dashboard: 'Панель управления партнером',
  feel_free_to_contact_us:
    'Для дополнительной помощи, пожалуйста, свяжитесь с нами',
  campaign_management: 'Управление кампаниями',
  add_new_crative: 'Добавить новый творческий элемент',
  are_you_sure_you_want_to_delete_this_creative: 'Удалить',
  delete_notify: 'Вы уверены, что хотите удалить этот творческий элемент?',
  creative: 'Творческий элемент',
  mlm_commission: 'Комиссия за привлечение',
  mlm_level2_commission: 'Комиссия за привлечение второго уровня',
  allow_mlm: 'Разрешить MLM (многоуровневый маркетинг)',
  mlm: 'Многоуровневый маркетинг (MLM)',
  mlm_percent: 'Процент MLM',
  allow_mlm_level2: 'Разрешить MLM второго уровня',
  allow_mlm_level_x: 'Разрешить MLM {x} уровня',
  mlm_level2_percent: 'Процент MLM второго уровня',
  mlm_level_x_percent: 'Процент MLM {x} уровня',
  referrer: 'Привлекатель',
  default_campaign: 'Основная кампания',
  conversion_tracking_setup: 'Настройка отслеживания конверсий',
  content: 'Контент',
  inactive: 'Неактивно',
  draft: 'Черновик',
  none: 'Нет',
  invalid_url: 'Неверный URL',
  categories_tracking: 'Отслеживание категорий',
  phone_support: 'Телефонная поддержка',
  chat_support: 'поддержка в чате',
  unlimited_banners: 'Неограниченное количество творческих элементов',
  impressions: 'Показы (CPM)',
  cpm_commissions: 'Комиссии CPM',
  conversion_commissions: 'Комиссии за конверсию',
  ppc_commissions: 'Комиссии PPC',
  support_app_conversions: 'Поддержка конверсий APP (Android/IOS)',
  support_app_helper:
    '* В случае, если клик и пиксель конверсии приходят с разных браузеров.',
  requested_commission: 'Запрошенная комиссия',
  manage: 'Управление',
  select_a_payout: 'Выбрать выплату',
  select_a_campaign: 'Выбрать кампанию',
  use_general_capmaign_settings: 'Использовать общие настройки кампании',
  add_user_to_campaign: 'Назначить партнера на кампанию',
  payout_groups: 'Группы выплат',
  commissions: 'Комиссии',
  affiliate_details: 'Детали партнера',
  creative_empty_desc: `Нет творческих элементов.`,
  country_code_required: 'Требуется указать страну при регистрации',
  custom_docs: 'Пользовательские документы',
  APPROVED: 'Одобрено',
  REJECTED: 'Отклонено',
  PENDING: 'Ожидает рассмотрения',
  NEEDS_ATTENTION: 'Требует внимания',
  INCOMPLETE: 'Не завершено',
  EXPIRED: 'Истекло',
  update_status: 'Обновить статус',
  options: 'Опции',
  select_helper: 'Разделите значения выбора запятой. Пример: Мужской,Женский.',
  select_files: 'Выбрать файлы',
  drop_files_here_or_click_browse_through_your_machine:
    'Перетащите файлы сюда или нажмите, чтобы просмотреть на вашем компьютере',
  remove_all: 'Удалить все',
  upload_files: 'Загрузить файлы',
  no_documents_required: 'Документы не требуются',
  admin_status_changed_email: 'Изменение статуса администратора',
  admin_status_approved_email: 'Статус администратора одобрен',
  when_company_requires_documents_upload_here:
    'Когда компания требует документы, вам нужно будет загрузить их здесь',
  single_campaign_mode: 'Режим одиночной кампании',
  single_campaign_mode_off: 'Доступны все кампании.',
  single_campaign_mode_on:
    'Доступ ограничен основными кампаниями (установленными компанией или пользователем).',
  country_grouping: 'Группировка по странам',
  grouping: 'Группировка',
  create_grouping: 'Создать группировку',
  symbols: 'Символы',
  symbol_grouping: 'Группировка символов',
  create_symbol: 'Создать символ',
  default_status: 'Стандартный статус',
  payout_type: 'Тип выплаты',
  confirmation: 'Подтверждение',
  copy_short_url: 'Скопировать короткую ссылку',
  generate_short_url: 'Создать короткую ссылку',
  short_url: 'Короткая ссылка',
  after_activation:
    'Добро пожаловать (письмо будет отправлено только если текст существует)',
  users: 'Пользователи',
  forex: 'Финансы',
  add_user: 'Добавить пользователя',
  edit_user: 'Редактировать пользователя',
  first_deposit_date: 'Дата первого депозита',
  user_id: 'ID пользователя',
  lead_id: 'ID лида',
  balance: 'Баланс',
  volume: 'Объем',
  lastAffiliateGroupChangeDate: 'Дата последнего изменения группы партнеров',
  firstDepositDate: 'Дата первого депозита',
  account_id: 'ID аккаунта',
  account_currency: 'Валюта аккаунта',
  add_account: 'Добавить аккаунт',
  edit_account: 'Редактировать аккаунт',
  add_transaction: 'Добавить транзакцию',
  edit_transaction: 'Редактировать транзакцию',
  accounts: 'Аккаунты',
  account: 'Аккаунт',
  forex_account: 'Форекс-аккаунт',
  profit: 'Прибыль',
  symbol: 'Символ',
  transactions: 'Транзакции',
  transaction_date: 'Дата транзакции',
  open_price: 'Цена открытия',
  close_price: 'Цена закрытия',
  show_symbols: 'Показать символы',
  qualified: 'Квалифицированный',
  leverage: 'Рычаг (Леверидж)',
  user: 'Пользователь',
  symbol_payout_modal_title: 'Добавить Цену для Символа',
  symbol_payout_modal_desc: 'Добавить Цену для Символа',
  position_count: 'Количество Позиций',
  net_deposit: 'Чистый Депозит',
  ask_price: 'Цена Предложения',
  bid_price: 'Цена Запроса',
  transaction_type: 'Тип Транзакции',
  broker_commission: 'Комиссия Брокера',
  revshare_type: 'Тип Ревшаринга',
  currency_price: 'Цена Валюты',
  converted_volume: 'Конвертированный Объем',
  more_options: 'Больше Опций',
  financial_users: 'Финансовые Пользователи',
  financial_accounts: 'Финансовые Аккаунты',
  first_time_deposit: 'Первый Депозит',
  new_country_grouping: 'Новая Группа Стран',
  new_symbol_grouping: 'Новая Группа Символов',
  currency_change_title: 'Оповещение: Подтверждение Изменения Валюты',
  currency_change_desc:
    'Вы собираетесь изменить валюту вашей компании. Обратите внимание, что это изменит вашу валюту конверсии и может повлиять на настройки вашего аккаунта.',
  tax_id: 'Идентификационный Номер Налогоплательщика',
  affiliate_settings: 'Настройки Аффилиата',
  hideOrderId: 'Скрыть Id Заказа (Аффилиат)',
  hideAmount: 'Скрыть Сумму (Аффилиат)',
  allow_ip_explain:
    'Разрешить постбэки только с этих IP-адресов (Белый список IP). IP-адреса должны быть разделены запятой. Например: 127.0.0.1, 192.17.17.1. Оставьте пустую запись, чтобы разрешить все IP-адреса.',
  setup_included: 'Установка Включена',
  affiliate_platform_name: 'Название Платформы Аффилиата',
  click_id: 'Идентификатор Клика',
  logs: 'Logs',
  ip: 'IP-адрес',
  parameters: 'Параметры',
  become_an_advertiser: 'Стать Рекламодателем',
  become_an_advertiser_desc:
    'Пожалуйста, заполните форму ниже, чтобы запросить присоединение к нашей платформе в качестве рекламодателя',
  become_an_advertiser_res:
    'Спасибо за отправку вашего запроса на становление рекламодателем. После одобрения вы получите учетные данные для входа по электронной почте',
  advertiser_registrations: 'Регистрации Рекламодателей',
  allow_affiliate_register: 'Разрешить Регистрацию Аффилиатов',
  allow_advertiser_register: 'Разрешить Регистрацию Рекламодателей',
  advertiser_user_management: 'Управление Пользователями-Рекламодателями',
  advertiser_login: 'Вход для Рекламодателей',
  advertiser_user_login_desc:
    'Обратите внимание, что каждое ваше действие после входа в систему является вашей личной ответственностью и повлияет на аккаунт рекламодателя.',
  edit_advertiser_user: 'Редактировать Пользователя-Рекламодателя',
  advertiser_user_updated: 'Пользователь-Рекламодатель Обновлен',
  add_advertiser_user: 'Добавить Пользователя-Рекламодателя',
  create_advertiser_user: 'Создать Пользователя-Рекламодателя',
  custom_merchant_domain:
    'Пользовательский Домен Управляющего SSL (для рекламодателей)',
  login_as_advertiser: 'Войти как Рекламодатель',
  login_as_admin: 'Войти как Администратор',
  adv_registration_url: 'URL Регистрации Рекламодателя',
  free_trial_ending: 'Пробный период заканчивается {0}',
  advertiser_status_approved: 'Статус рекламодателя утвержден',
  email_config_activation: 'Активация',
  email_config_reset_password: 'Сброс пароля',
  email_config_changed_email: 'Смена электронной почты',
  email_config_welcome:
    'Добро пожаловать (Электронное письмо будет отправлено только в случае наличия текста в теле)',
  email_config_request_to_join_campaign_approved:
    'Запрос на участие в кампании одобрен',
  email_config_request_to_join_campaign_declined:
    'Запрос на участие в кампании отклонен',
  email_config_admin_status_approved: 'Статус администратора одобрен',
  email_config_admin_status_changed: 'Статус администратора изменен',
  email_config_payment_request_approved: 'Запрос на оплату одобрен',
  email_config_payment_request_declined: 'Запрос на оплату отклонен',
  profile_pic: 'Фотография профиля',
  ip_cooldown: 'Период ожидания IP',
  ip_cooldown_helper:
    'Время ожидания между каждой конверсией для данного IP-адреса.',
  instant: 'Мгновенный',
  one_hour: 'Один час',
  one_day: 'Один день',
  one_week: 'Одна неделя',
  one_month: 'Один месяц',
  one_year: 'Один год',
  primary_color: 'Основной цвет',
  secondary_color: 'Вторичный цвет',
  dark_mode: 'Тёмный режим',
  light_mode: 'Светлый режим',
  custom_statuses: 'Пользовательские статусы',
  add_custom_status: 'Добавить пользовательский статус',
  edit_custom_status: 'Редактировать пользовательский статус',
  free_mode: 'Свободный режим',
  use_a_semicolon:
    'Используйте точку с запятой для добавления нескольких валютных пар, например: AUDCAD;EURUSD',
  new_symbols_will_be_added:
    'Новые символы будут добавлены автоматически с шагом 4 пунктов (можно изменить позже)',
  group_name: 'Название группы',
  send_as_newsletter: 'Отказ от ответственности за новостную рассылку',
  send_as_newsletter_desc: `Этот информационный бюллетень будет отправлен как рассылка всем активным партнёрам (если выбрана кампания, письмо получат только партнёры, участвующие в кампании). Это действие не может быть отменено и будет работать только при наличии настроенной SMTP или Amazon SES Alias у компании.`,
  invoice_details: 'Детали Счета',
  display_deposits_and_withdrawals: 'Отображать депозиты и снятия по умолчанию',
  display_accessibility_menu: 'Показать меню доступности',
  lifetime_affiliates: 'Партнёры на всю жизнь',
  customer_id: 'Идентификатор клиента',
  leads: 'Лиды',
  lead_created: 'Лид создан',
  lead_updated: 'Лид обновлён',
  add_lead: 'Добавить лид',
  create_lead: 'Создать лид',
  edit_lead: 'Редактировать лид',
  NEW: 'Новый',
  CONTACTED: 'Контактирован',
  QUALIFIED: 'Квалифицирован',
  CONVERTED: 'Конвертирован',
  CLOSED: 'Закрыт',
  mlm_type: 'Тип MLM',
  brand_banner: 'Баннер бренда',
  add_to_dashboard: 'Добавить на панель',
  remove_from_dashboard: 'Удалить с панели',
  edit_new_creative: 'Редактировать новое креатив',
  remove_affiliate_withdrawal_request:
    'Удалить запрос на вывод средств аффилиата',
  remove_withdrawal_info_helper:
    'Когда вы включаете эту опцию, кнопка запроса на вывод средств будет удалена из портала аффилиатов',
  campaign_name_clone: 'Подтвердите клонирование {campaignTitle}',
  clone: 'Клон',
  campaign_clone_message:
    'Вы собираетесь клонировать эту кампанию. Обратите внимание на следующее: \n \u200B \n1. Партнеры не будут клонированы. \n2. Будут скопированы только настройки, связанные с отслеживанием, общей конфигурацией и выплатами.\n3. Клонированная кампания будет приостановлена. \n \u200B \n Вы уверены, что хотите продолжить клонирование?',
  automatically: 'Автоматически',
  by_affiliate_request: 'По запросу аффилиата',
  choose_how_to_handle_payouts:
    'Выберите, как вы хотите обрабатывать выплаты. Автоматически или по запросу аффилиата.',
  period_type: 'Тип периода',
  day_of_month: 'День месяца',
  day_of_week: 'День недели',
  allow_custom_amount_helper:
    'Включите эту опцию, чтобы разрешить аффилиатам вручную вводить сумму выплаты.',
  allow_attachment_helper:
    'Включите эту опцию, чтобы разрешить прикрепление заявки на выплату.',
  allow_enter_amount: 'Разрешить ввод суммы',
  allow_an_attachment: 'Разрешить прикрепление',
  form_editor: 'Редактор Формы',
  form_preview: 'Предпросмотр Формы',
  lifetime_lifecycle: 'Жизненный цикл',
  lifetime_lifecycle_helper:
    'Добавляет дату истечения для постоянных аффилиатов. Применяется только к новым постоянным аффилиатам, созданным после изменения',
  has_expiration_date: 'Имеет срок годности',
  tiers_migration: 'Уровни',
  bonus: 'Бонус',
  deposits_count: 'Депозиты',
  registrations_count: 'Регистрации',
  deposits_amount: 'Сумма депозитов',
  threshold_reward_type: 'Тип вознаграждения за порог',
  campaign_payout_automation_bonuses:
    'Автоматизация выплат по бонусам кампании',
  recurring_bonus: 'Повторяющийся бонус',
  recurring_bonus_helper_text:
    'При включении бонус рассчитывается по временным рамкам. При выключении это одноразовый бонус на каждого партнёра.',
  allow_api_keys_by_default: 'Разрешить API-ключи по умолчанию',
  allow_api_keys_helper: 'Это изменение не повлияет на существующих партнеров',
  campaign_payout_automation_title:
    'Автоматическая корректировка выплат аффилиатов',
  campaign_payout_automation_subtitle:
    'Мы ввели улучшенную функцию для оптимизации выплат аффилиатам на основе их результатов. Вот как это работает:',
  campaign_payout_automation_desc_evaluation:
    '<strong>Оценка производительности:</strong> В начале каждого месяца система рассчитывает производительность аффилиатов с использованием ключевых метрик, таких как количество конверсий, объем и количество конверсий.',
  campaign_payout_automation_desc_performance_tiers:
    '<strong>Уровни производительности с пороговыми значениями:</strong> Аффилиаты распределяются по определенным уровням на основе их производительности относительно заданных порогов.',
  campaign_payout_automation_desc_rewarding_performance:
    '<strong>Корректировка выплат:</strong> Аффилиаты, которые превзошли свои цели по сравнению с предыдущим месяцем, автоматически переводятся на более высокий уровень выплат.',
  campaign_payout_automation_desc_personalized_payouts:
    '<strong>Бонусы для аффилиатов:</strong> Аффилиаты зарабатывают бонусы, достигая определенных порогов. Если в рамках одной автоматизации доступны несколько бонусов, система начисляет бонус, связанный с наивысшим достигнутым порогом. Сумма бонуса определяется в настройках бонусных выплат.',
  estimated_original_price: 'Оценочная первоначальная цена',
  conversion_rate: 'Коэффициент конверсии',
  igaming_reports: 'Отчёты по iGaming',
  clicks_to_registrations: 'Клики к Регистрациям',
  registrations_to_deposits: 'Регистрации к Депозитам',
  clicks_to_deposits: 'Клики к Депозитам',
  fraud_score: 'Шанс мошенничества',
  import_conversions: 'Импорт конверсий',
  update_conversions: 'Обновить конверсии',
  if_commission_not_specified:
    'Если не указано, комиссии будут автоматически скорректированы в зависимости от суммы.',
  should_affiliates_change:
    'Должно ли изменение партнера применяться ко всем аккаунтам под пользователем, которые в настоящее время имеют того же бывшего партнера?',
  min_amount: 'Минимальная сумма',
  view_more: 'Посмотреть больше',
  campaign_tracking_tools: 'Инструменты отслеживания',
  campaign_setup: 'Настройка/Интеграция',
  affiliate_assignment: 'Назначение партнеров'
};
