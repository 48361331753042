/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  COMPANY_ADVERTISERS,
  COMPANY_BRANDING,
  COMPANY_CAMPAIGN_NEXT_AVAILABLE_SERIAL_OID,
  COMPANY_CURRENCY,
  COMPANY_CUSTOM_CURRENCY,
  COMPANY_HAS_BRANDING,
  COMPANY_INITIAL_DATA,
  COMPANY_MINIMUM_WITHDRAWAL_AMOUNT,
  COMPANY_MLM_SETTINGS,
  COMPANY_NOTI,
  COMPANY_PAYMENT_METHOD,
  COMPANY_REACHED_MAX_CAMPAIGNS,
  COMPANY_TOGGLE_ONBOARDING,
  COMPANY_TRAFFIC_SOURCE
} from 'src/actions/companyActions';
import { SiteTypeEnum } from 'src/components/guards/BrokerGuard';

const initialState = {
  trafficSources: [],
  advertisers: [],
  currency: null,
  companyHasBranding: false,
  branding: null,
  reachedMaxCampaigns: true,
  campaignNextAvailableSerialOid: null,
  minimumWithdrawalAmount: 0,
  membership: 'Free',
  mlmActive: false,
  mlmPercent: null,
  mlmLevel2Active: false,
  affiliateNeedsManualActivation: false,
  mlmLevel2Percent: null,
  singleCampaignMode: false,
  brokerMode: false,
  siteType: SiteTypeEnum.GENERAL,
  socialMode: false,
  onboardingDone: false,
  requireAffiliateLegalStatus: false,
  allowAdvertiserRegister: false,
  allowAffiliateRegister: true,
  displayAccessibilityToolbox: true,
  requireCompanyName: true,
  sendManagerAlertOnNewAffiliatePayout: true,
  sendManagerAlertOnNewAffiliate: true,
  hideMerchantCommission: false,
  hideAmountAdmin: false,
  notification: {},
  hideFooter: false,
  customFooter: null,
  enableFraudDetection: false,
  hidePPCCommission: false,
  hideCPMCommission: false,
  hideAdvancedTrackingLogSettings: true,
  oid: null,
  paymentMethod: null,
  membershipPlans: null,
  customCurrencyPlacement: null,
  customCurrencySymbol: null
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_TRAFFIC_SOURCE: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.trafficSources = action.payload;
      });
    }
    case COMPANY_NOTI: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.notification = action.payload;
      });
    }
    case COMPANY_ADVERTISERS: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.advertisers = action.payload ?? [];
      });
    }
    case COMPANY_CURRENCY: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.currency = action.payload;
      });
    }
    case COMPANY_BRANDING: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.branding = action.payload;
      });
    }
    case COMPANY_HAS_BRANDING: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.companyHasBranding = action.payload;
      });
    }
    case COMPANY_REACHED_MAX_CAMPAIGNS: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.reachedMaxCampaigns = action.payload;
      });
    }
    case COMPANY_TOGGLE_ONBOARDING: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.onboardingDone = action.payload;
      });
    }
    case COMPANY_CAMPAIGN_NEXT_AVAILABLE_SERIAL_OID: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.campaignNextAvailableSerialOid = action.payload;
      });
    }
    case COMPANY_PAYMENT_METHOD: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.paymentMethod = action.payload;
      });
    }
    case COMPANY_MINIMUM_WITHDRAWAL_AMOUNT: {
      return produce(state, draft => {
        draft.minimumWithdrawalAmount = action.payload.minimumWithdrawalAmount;
        draft.affiliateNeedsManualActivation =
          action.payload.affiliateNeedsManualActivation;
        draft.singleCampaignMode = action.payload.singleCampaignMode;
        draft.allowAffiliateRegister = action.payload.allowAffiliateRegister;
        draft.allowAdvertiserRegister = action.payload.allowAdvertiserRegister;
        draft.displayAccessibilityToolbox =
          action.payload.displayAccessibilityToolbox;
        draft.requireAffiliateLegalStatus =
          action.payload.requireAffiliateLegalStatus;
        draft.requireCompanyName = action.payload.requireCompanyName;
      });
    }
    case COMPANY_CUSTOM_CURRENCY: {
      return produce(state, draft => {
        draft.customCurrencySymbol = action.payload.customCurrencySymbol;
        draft.customCurrencyPlacement = action.payload.customCurrencyPlacement;
      });
    }
    case COMPANY_MLM_SETTINGS: {
      return produce(state, draft => {
        draft.mlmActive = action.payload.mlmActive;
        draft.mlmPercent = action.payload.mlmPercent;
        draft.mlmLevel2Active = action.payload.mlmLevel2Active;
        draft.mlmLevel3Active = action.payload.mlmLevel3Active;
        draft.mlmLevel4Active = action.payload.mlmLevel4Active;
        draft.mlmLevel5Active = action.payload.mlmLevel5Active;
        draft.mlmLevel2Percent = action.payload.mlmLevel2Percent;
        draft.mlmLevel3Percent = action.payload.mlmLevel3Percent;
        draft.mlmLevel4Percent = action.payload.mlmLevel4Percent;
        draft.mlmLevel5Percent = action.payload.mlmLevel5Percent;
      });
    }
    case COMPANY_INITIAL_DATA: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.currency = action.payload.currency;
        draft.trafficSources = action.payload.companyTrafficSources;
        draft.advertisers = action.payload.companyAdvertisers ?? [];
        draft.minimumWithdrawalAmount = action.payload.minimumWithdrawalAmount;
        draft.membership = action.payload.membership;
        draft.mlmActive = action.payload.mlmActive;
        draft.mlmPercent = action.payload.mlmPercent;
        draft.mlmLevel2Active = action.payload.mlmLevel2Active;
        draft.mlmLevel2Percent = action.payload.mlmLevel2Percent;
        draft.affiliateNeedsManualActivation =
          action.payload.affiliateNeedsManualActivation;
        draft.singleCampaignMode = action.payload.singleCampaignMode;
        draft.allowAffiliateRegister = action.payload.allowAffiliateRegister;
        draft.allowAdvertiserRegister = action.payload.allowAdvertiserRegister;
        draft.displayAccessibilityToolbox =
          action.payload.displayAccessibilityToolbox;
        draft.siteType = action.payload.siteType;
        draft.brokerMode = action.payload.brokerMode;
        draft.socialMode = action.payload.socialMode;
        draft.requireAffiliateLegalStatus =
          action.payload.requireAffiliateLegalStatus;
        draft.onboardingDone = action.payload?.onboardingDone;
        draft.requireCompanyName = action.payload.requireCompanyName;
        draft.sendManagerAlertOnNewAffiliatePayout =
          action.payload.sendManagerAlertOnNewAffiliatePayout;
        draft.sendManagerAlertOnNewAffiliate =
          action.payload.sendManagerAlertOnNewAffiliate;
        draft.hideAmountAdmin = action.payload.hideAmountAdmin;
        draft.hideMerchantCommission = action.payload.hideMerchantCommission;
        draft.hideFooter = action.payload.hideFooter;
        draft.customFooter = action.payload.customFooter;
        draft.enableFraudDetection = action.payload.enableFraudDetection;
        draft.hideCPMCommission = action.payload.hideCPMCommission;
        draft.hidePPCCommission = action.payload.hidePPCCommission;
        draft.hideAdvancedTrackingLogSettings =
          action.payload.hideAdvancedTrackingLogSettings;
        draft.oid = action.payload.oid;
        draft.membershipPlans = action.payload.membershipPlans;
        draft.customCurrencySymbol = action.payload.customCurrencySymbol;
        draft.customCurrencyPlacement = action.payload.customCurrencyPlacement;
      });
    }
    default: {
      return state;
    }
  }
};

export default companyReducer;
