export default {
  sign_up: 'Sign Up',
  create_x_account: ' Create {name} account.',
  have_an_account: 'Have an account?',
  back_to_home: 'Back to home',
  terms: 'Terms',
  back: 'Back',
  disagree: 'Disagree',
  i_agree: 'I Agree',
  i_have_read_the: 'I have read the',
  terms_and_conditions: 'Terms and Conditions',
  create_account: 'Create Account',
  general_error: 'General Error',
  sign_in: 'Sign In',
  sign_in_on_the_internal_platform: 'Sign in to the affiliate management',
  create_new_account: 'Create New Account',
  forgot_password: 'Forgot Password',
  advertisers: 'Advertisers',
  advertiser: 'Advertiser',
  home: 'Home',
  dashboard: 'Dashboard',
  management: 'Management',
  export: 'Export',
  import: 'Import',
  all_advertisers: 'All Advertisers',
  new_advertiser: 'New Advertiser',
  add_advertiser: 'Add Advertiser',
  settings_updated_successfully: 'Settings Updated Successfully',
  save_changes: 'Save Changes',
  save: 'Save',
  change_email: ' Change Email',
  email_address: 'Email Address',
  confirm_email_address: 'Confirm Email Address',
  last_name: 'Last Name',
  first_name: 'First Name',
  profile: 'Profile',
  change_password: 'Change Password',
  password_confirmation: 'Password Confirmation',
  password: 'Password',
  min_pass_length_message:
    'You have entered an invalid password. Please use 10-20 characters.',
  password_required: 'Password is required',
  enter_valid_email: 'Must be a valid email',
  email_required: 'Email is required',
  invalid_password_message: 'Invalid password, Please enter valid password',
  invalid_format: 'Invalid format, please follow the example',
  invalid_email_message: 'Invalid Email',
  log_in: 'Log In',
  first_name_validation:
    'First name can contain only letters (a-z) and must be 2-20 characters long.',
  last_name_validation:
    'Last name can contain only letters (a-z) and must be 2-20 characters long.',
  submit: 'Submit',
  emails_must_match: 'Both emails must be the same',
  campaign: 'Campaign/Offer',
  campaigns: 'Campaigns/Offers',
  all_campaigns: 'All Campaigns',
  new_campaign: 'New Campaign',
  add_campaign: 'Add Campaign',
  all: 'All',
  title: 'Title',
  description: 'Description',
  status: 'Status',
  fileStatus: 'File Status',
  privacy: 'Privacy',
  privacy_policy: 'Privacy Policy',
  active: 'Active',
  disable: 'Disable',
  disabled: 'Disabled',
  paused: 'Paused',
  public: 'Public',
  pre_moderation: 'Pre Moderation',
  public_auto_approve: 'Public Auto Approve',
  private: 'Private',
  no_results_found: 'No Results found',
  display_registration_popup: 'Display Activation Message In Popup',
  display_registration_popup_helper:
    'Enable this option to display a popup for activation instead of the regular message.',
  this_field_is_required: 'this field is required',
  actions: 'Actions',
  general: 'General',
  tracking: 'Tracking',
  traffic_source: 'Traffic Source',
  traffic_sources: 'Traffic Sources',
  currency: 'Currency',
  features: 'Features',
  featured: 'Featured',
  start_date: 'Start Date',
  end_date: 'End Date',
  hold_time: 'Hold Time',
  successfully_updated: 'Successfully Updated',
  successfully_created: 'Successfully Created',
  last_update_newest_first: 'Last update (newest first)',
  last_update: 'Last Update',
  last_update_oldest_first: 'Last update (oldest first)',
  total_orders_high_to_low: 'Total orders (high to low)',
  total_orders_low_to_high: 'Total orders (low to high)',
  delete: 'Delete',
  edit: 'Edit',
  name: 'Name',
  website: 'Website',
  contact_person: 'Contact Person',
  im_or_skype: 'Im / Skype',
  full_name: 'Full Name',
  address: 'Address',
  address1: 'Address1',
  address2: 'Address2',
  postcode: 'Postcode',
  allowed_ips: 'Allowed Ips',
  disallowed_ips: 'Disallowed Ips',
  api_allowed_ips: 'API Allowed Ips',
  manager_allowed_ips: 'Manager Allowed Ips',
  note: 'Note',
  secure_postback_code: 'Secure Post Back Code',
  vat_code: 'Vat Code',
  zipcode: 'Zip Code',
  true: 'True',
  false: 'False',
  update_advertiser: 'Update Advertiser',
  create_advertiser: 'Create Advertiser',
  edit_advertiser: 'Edit Advertiser',
  update_campaign: 'Update Campaign',
  create_campaign: 'Create Campaign',
  settings: 'Settings',
  create_traffic_source: 'Create Traffic Source',
  update_traffic_source: 'Update Traffic Source',
  edit_traffic_source: 'Edit Traffic Source',
  add_traffic_source: 'Add Traffic Source',
  create: 'Create',
  update: 'Update',
  traffic_source_created: 'Traffic Source Created',
  new_company_traffic_source: 'New Company Traffic Source',
  new_traffic_source: 'New Traffic Source',
  edit_campaign: 'Edit Campaign',
  tracking_url: 'Tracking URL',
  preview_url: 'Preview URL',
  redirect_type: 'Redirect Type',
  traffic_back_url: 'Traffic Back URL',
  no_advertisers_found: 'No Advertisers Found',
  no_campaign_create_advertiser:
    'In order to create a campaign, Please create an advertiser first',
  create_traffic_source_first:
    'In order to create a campaign you need a traffic source first',
  discounted_prices: 'Discounted Prices',
  email_verified: 'Email Verified',
  unique_ip_only: 'Unique IP is Required',
  one_of_ips_invalid:
    'Invalid IP Address Format: Please enter a valid IP address in the correct format (e.g., 192.168.1.1). Ensure each section is a number between 0 and 255 and separated by periods.',
  s2s_postback: 'S2S (Postback)',
  probabilistic_attribution: 'Probabilistic Attribution',
  image: 'Image',
  js: 'JavaScript',
  iframe: 'Iframe',
  next: 'Next',
  ip_is_invalid: 'IP is invalid.',
  payout: 'Payout',
  payouts: 'Payouts',
  revenue: 'Revenue',
  goal_value: 'Goal Value',
  goal_title: 'Goal Title',
  payment_type: 'Payment Type',
  countries: 'Countries',
  invalid: 'Invalid',
  general_settings_title: 'General Setting Title',
  general_settings_start_date: 'General Setting Start Date',
  general_settings_end_date: 'General Setting End Date',
  general_settings_hold_time: 'General Setting Hold Time',
  tracking_settings_integration_s2s_postback:
    'Tracking Settings Integration S2s Postback',
  tracking_settings_traffic_back_url: 'Tracking Settings Traffic Back URL',
  tracking_settings_preview_url: 'Tracking Settings Preview URL',
  tracking_settings_tracking_url: 'Tracking Settings Tracking URL',
  payouts_settings_goal_title: 'Payouts Settings Goal Title',
  payouts_settings_goal_value: 'Payouts Settings Goal Value',
  payouts_settings_revenue: 'Payouts Settings Revenue',
  payouts_settings_payouts: 'Payouts Settings',
  field: 'Field',
  ip_should_be_unique: 'IP Should Be Unique',
  required: 'Required',
  field_invalid: 'Field Invalid',
  general_payout: 'General Payout',
  personal_payout: 'Personal Payout',
  valid: 'Valid',
  resource_name_unavailable: 'Resources name unavailable',
  cancel: 'Cancel',
  end_date_is_invalid: 'End date is invalid',
  affiliates: 'Affiliates',
  affiliate: 'Affiliate',
  click_session_life_span: 'Click Session Life Span',
  security: 'Security',
  password_confirm: 'Password Confirm',
  current_password: 'Current Password',
  name_unavailable: 'Name Unavailable',
  statistics: 'Performance',
  payouts_soon: 'Payouts (Soon)',
  conversions_soon: 'Conversions (Soon)',
  affiliates_soon: 'Affiliates (Soon)',
  campaign_stats: 'Reports',
  campaign_id: 'Campaign Id',
  campaign_name: 'Campaign Name',
  hosts: 'Hosts',
  hits: 'Hits',
  approved: 'Approved',
  approve_affiliate: 'Approve Affiliate',
  reject_affiliate: 'Reject Affiliate',
  disapproved: 'Disapprove',
  pending: 'Pending',
  declined: 'Declined',
  decline: 'Decline',
  cr: 'CR',
  epc: 'EPC',
  traffic: 'Traffic',
  conversions: 'Conversions',
  finances: 'Finances',
  filters: 'Filters',
  hold: 'Hold',
  total: 'Total',
  qty: 'QTY',
  null: 'NULL',
  trafficback: 'Traffic Back',
  earnings: 'Earnings',
  ecpm: 'ECPM',
  ecpc: 'ECPC',
  clear_filters: 'Clear Filters',
  not_selected: 'Not Selected',
  filter: 'Filter',
  conversion_id: 'Conversion ID',
  comment: 'Comment',
  user_agent: 'User Agent',
  utm_source: 'UTM Source',
  utm_medium: 'UTM Medium',
  utm_content: 'UTM Content',
  sub_id: 'Sub ID',
  sub_id_text: 'Sub ID Text',
  sub_1: 'sub1',
  sub_2: 'sub2',
  sub_3: 'sub3',
  sub_4: 'sub4',
  sub_5: 'sub5',
  sub_6: 'sub6',
  sub_7: 'sub7',
  sub_8: 'sub8',
  sub1: 'Sub 1',
  sub2: 'Sub 2',
  sub3: 'Sub 3',
  sub4: 'Sub 4',
  sub5: 'Sub 5',
  sub6: 'Sub 6',
  sub7: 'Sub 7',
  sub8: 'Sub 8',
  goal_name: 'Goal Name',
  time_period: 'Time Period',
  income: 'Income',
  device: 'Device',
  geography_ip: 'Geography / IP',
  amount: 'Amount',
  date: 'Date',
  today: 'TODAY',
  yesterday: 'YESTERDAY',
  last_week: 'LAST WEEK',
  last_two_weeks: 'LAST 2 WEEKS',
  last_month: 'LAST MONTH',
  this_month: 'THIS MONTH',
  this_year: 'THIS YEAR',
  all_time: 'ALL TIME',
  last_three_months: 'LAST 3 MONTHS',
  last_six_months: 'LAST 6 MONTHS',
  last_year: 'LAST YEAR',
  custom: 'CUSTOM',
  conversion_stats: 'Conversion Stats',
  create_conversion: 'Create Conversion',
  update_conversion: 'Update Conversion',
  edit_conversion: 'Edit Conversion',
  all_conversions: 'All Conversions',
  add_conversion: 'Add',
  conversion_created: 'Conversion Created',
  conversion_updated: 'Conversion Updated',
  currently_unavailable: 'Currently Unavailable',
  creation_date: 'Creation Date',
  all_payouts: 'All Payouts',
  add_payout: 'Add Payout',
  add: 'Add',
  create_payout: 'Create Payout',
  denied: 'Denied',
  number: 'Number',
  created_at: 'Created At',
  approved_and_paid: 'Approved And Paid',
  order_id: 'Order ID',
  include_affiliate: 'Include Affiliate',
  amount_to_be_approved: 'Amount To Approve',
  commission_to_be_approved: 'Commission To Approve',
  edit_payout: 'Edit Payout',
  advertiser_name: 'Advertiser Name',
  total_amount: 'Total Amount',
  campaign_start_date: 'Campaign Start Date',
  campaign_end_date: 'Campaign End Date',
  update_payout: 'Update Payout',
  total_commission: 'Total Commission',
  download_report: 'Download Report',
  file_uploaded_successfully: 'file uploaded successfully',
  download_latest_report: 'Download Uploaded Report',
  download_original_report: 'Download Original Report',
  payout_updated: 'Payout Updated',
  include_commission: 'Include Commission',
  no_report_attached: 'no report attached',
  report_is_valid: 'Report Is Valid',
  view_payout: 'View Payout',
  affiliates_requests: 'Affiliates Requests',
  requests: 'Requests',
  approve: 'Approve',
  affiliate_payouts: 'Affiliate Payouts',
  save_and_next: 'Save & Next',
  available_to_payout: 'Available To Payout',
  request_payout: 'Request Payout',
  commission: 'Commission',
  affiliate_commission: 'Affiliate Commission',
  see_all: 'See All',
  clicks: 'Clicks',
  more_details: 'More Details',
  email: 'Email',
  emails: 'Emails',
  affiliate_website: 'Affiliate Website',
  disapprove: 'Disapprove',
  change_to_approved: 'Change To Approved',
  change_to_declined: 'Change To Declined',
  contact_details: 'Contact Details',
  login_as_affiliate: 'Login As Affiliate',
  login: 'Login',
  add_affiliate: 'Add Affiliate',
  create_affiliate: 'Create Affiliate',
  edit_affiliate: 'Edit Affiliate',
  try_again_later: 'Try Again Later',
  added_affiliate: 'Added Affiliate',
  affiliate_management: 'Affiliate Management',
  merchant_commission: 'Commission',
  phone_number: 'Phone Number',
  affiliate_updated: 'Affiliate Updated',
  affiliate_created: 'Affiliate Created',
  merchant_management: 'Management',
  user_management: 'User Management',
  identity: 'identity',
  smtp: 'SMTP',
  edit_merchant: 'Edit User Manager',
  merchant_updated: 'User Manager Updated',
  add_merchant: 'Add User Manager',
  create_merchant: 'Create User Manager',
  company_name: 'Company Name',
  company_name_for_invoice: 'Company Name',
  update_merchant: 'Manager Updated',
  campaign_logo: 'Campaign Logo',
  io_document: 'IO Document',
  select_logo: 'Select Logo',
  select_io_document: 'Select IO Document',
  img: 'Img',
  picture_unavailable: 'Picture Unavailable',
  logo: 'Logo',
  id: 'ID',
  select_campaign: 'Select Campaign',
  js_landing_page: 'Javascript Landing Page',
  js_thank_you: 'Js Thank You',
  js_landing_page_text:
    "An advertiser should place this code after the opening <body> tag on the landing page. It's required to add &click_id={click_id} parameter to the Tracking URL",
  js_thank_you_text:
    'An advertiser should place this code after the opening <body> tag on the success page "Thank you"',
  end_date_before_start_date: 'End date is before start date',
  login_email: 'Login Email',
  login_password: 'Login Password',
  server: 'Server',
  port: 'Port',
  protocol: 'Protocol',
  smtp_and_ssl: 'SMTP + SSL',
  smtp_and_tls: 'SMTP + TLS',
  created: 'Created',
  updated: 'Updated',
  forgot_password_message:
    'Enter your e-mail address below to reset your password.',
  search_affiliate: 'Search Affiliate',
  search_advertisers: 'Search Advertisers',
  search_campaigns: 'Search campaigns',
  search_payouts: 'Search Payouts',
  search_conversions: 'Search Conversions',
  search_campaign_request: 'Search Campaign Requests',
  custom_domain: 'SSL Custom Domain (for affiliates)',
  branding: 'Branding',
  request_details: 'Request Details',
  search_traffic_source: 'Search Traffic Source',
  performance_chart: 'Performance',
  last_conversions: 'Latest Conversions',
  users_key: 'Users',
  last_30_days: 'LAST 30 DAYS',
  reports: 'Reports',
  no_name: 'No Name',
  mlm_tree: 'MLM Tree',
  documents: 'Documents',
  search: 'Search',
  SUB1: 'SUB1',
  SUB2: 'SUB2',
  SUB3: 'SUB3',
  SUB4: 'SUB4',
  SUB5: 'SUB5',
  SUB6: 'SUB6',
  SUB7: 'SUB7',
  SUB8: 'SUB8',
  top_campaigns: 'Top Campaigns',
  Paused: 'Paused',
  Disabled: 'Disabled',
  logout: 'Logout',
  Active: 'Active',
  click_session_days: 'Click Session Days',
  click_session_hours: 'Click Session Hours',
  statuses: 'Statuses',
  enter_new_password: 'Enter New Password',
  download: 'Download',
  not_active: 'Not Active',
  activate: 'Activate',
  test_email: 'Send Test Email',
  alias: 'Alias',
  smtp_connection_failed_change_details:
    'SMTP connection failed, consider changing the settings.',
  send_test_mail: 'Test Email Sent',
  smtp_connected_successfully: 'SMTP connected Successfully',
  mail: 'Mail',
  header: 'Header',
  footer: 'Footer',
  attribution: 'Attribution',
  first_click: 'First Click',
  last_click: 'Last Click',
  earn_per_click: 'Earn Per Click',
  click_rate: 'Click Rate',
  campaign_details: 'Campaign Details',
  disallowed: 'Disallowed',
  preview: 'Preview',
  hint: 'hint',
  coupon: 'Coupon',
  coupons: 'Coupons Management',
  add_coupon: 'Add Coupon',
  cname_missing_add_to_dns_manager:
    'CNAME is missing, Add CNAME to your DNS manager.',
  new_affiliate_needs_activation: 'New Affiliate Email Verification',
  new_affiliate_needs_manual_activation:
    'New Affiliate Needs Manual Activation',
  change_manager: 'Change Manager',
  manager: 'Manager',
  default_merchant: 'Default Manager',
  merchant: 'Manager',
  need_help: 'Need help',
  check_our_docs: 'Check our docs',
  documentation: 'Documentation',
  reset_password: 'Reset Password',
  invitation: 'Invitation',
  password_changed: 'Password has been changed',
  enter_email: 'Enter Email',
  industry: 'Industry',
  and: 'and',
  available_macros: 'Available Macros',
  custom_fields: 'Registration Custom Fields',
  add_custom_field: 'Add Custom Field',
  search_custom_field: 'Search Custom Field',
  edit_custom_field: 'Edit Custom Field',
  create_custom_field: 'Create Custom Field',
  update_custom_field: 'Update Custom Field',
  add_custom_doc: 'Add Custom Document',
  search_custom_doc: 'Search Custom Document',
  edit_custom_doc: 'Edit Custom Document',
  create_custom_doc: 'Create Custom Document',
  update_custom_doc: 'Update Custom Document',
  custom_field_name: 'Custom Field Name',
  not_required: 'Not Required',
  field_name_is_already_used: 'Field name is already in use',
  are_you_sure: 'Are you sure?',
  are_you_sure_masspay:
    'Are you sure you would like to proceed with MAS PAY? \nPlease note that the payment will be made via the API and is unchangeable.',
  are_you_sure_you_want_to_delete: 'Are you sure you want to delete?',
  are_you_sure_you_want_to_delete_hard:
    'Are you sure you want to permanently delete this user? All private information will be permanently removed',
  are_you_sure_you_want_to_restore:
    'Are you sure you want to restore the client?',
  are_you_sure_you_want_to_delete_all:
    'Are you sure you want to remove all items?',
  text_area: 'Text Area',
  check_box: 'Check Box',
  input: 'Input',
  type: 'Type',
  field_type: 'Field Type',
  default_manager: 'Default Manager',
  brand_logo: 'Brand Logo',
  reached_max_campaigns_limit: 'The maximum campaign has been reached',
  billing: 'Billing',
  payments: 'Payments',
  plan: 'Plan',
  paypal: 'Paypal',
  wire_transfer: 'Wire Transfer',
  payout_source: 'Payout Source',
  price: 'Price',
  transaction_id: 'Transaction Id',
  view_invoice: 'View Invoice',
  upgrade: 'Upgrade',
  work_email: 'Work Email',
  message: 'Message',
  contact_us: 'Contact Us',
  we_will_contact_u_soon: 'We will contact you soon!',
  terms_and_conditions_and_privacy_policy:
    'Terms and Conditions, and Privacy Policy',
  current_email: 'Current Email',
  please_update_your_plan: 'please update your plan',
  here: 'here',
  custom_domain_updated: 'Custom domain updated',
  your_email: 'Your Email',
  allowed: 'Allowed',
  created_merchant: 'Manager Created',
  affiliate_company: 'Affiliate Company',
  linked_to_payout: 'Linked to payout',
  subject: 'Subject',
  has_payout: 'Has Payout',
  yes: 'Yes',
  no: 'No',
  select: 'Select',
  view_conversions: 'View Conversions',
  search_conversions_by_campaign_affiliate_or_payout_or_click_id:
    'Search by Click Id, campaign name or affiliate company name.',
  search_conversions_by_campaign_affiliate_or_payout:
    'Search by campaign name or affiliate company name.',
  traffic_back_explanation:
    'The client will be redirected to "Traffic Back URL" in case of failure or if the campaign is disabled.',
  tracking_url_explanation:
    'Note: If your URL contains a deep URL macro, it will be replaced with the deep URL value. No redirection will occur, and the macro will be substituted in the URL directly.',
  goal_value_explanation_row_1:
    "The 'Goal Value' provides the option of paying for events or categories.",
  goal_value_explanation_row_2:
    "The 'Goal Value' must be returned by your code/postback.",
  goal_value_explanation_row_3: `For more information <a href="https://help.tracknow.io/knowledge-base/create-goals/">Click Here</a>`,
  test_smtp_email: 'Test Smtp Email',
  campaign_terms: 'Campaign Terms',
  new_email: 'New Email',
  payment_details: 'Payment Details',
  test: 'Test',
  test_conversion: 'Test',
  advertiser_details: 'Advertiser Details',
  request_date: 'Request Date',
  reason: 'Reason',
  affiliate_request: 'Affiliate Request',
  the_reason_will_be_visible_to_the_affiliate:
    'The reason will be visible to the affiliate',
  affiliate_registration_url: 'Affiliate Registration URL',
  copy: 'Copy',
  copied_to_clipboard: 'Copied to clipboard',
  minimum_withdrawal_amount: 'Minimum Withdrawal Amount',
  payment_methods: 'Payment Methods',
  search_payment_method: 'Search Payment Method',
  create_payment_method: 'Create Payment Method',
  edit_payment_method: 'Edit Payment Method',
  payment_method_created: 'Payment Method Created',
  payment_method: 'Payment Method',
  conversion_statuses_explanation:
    "To create a payout to excel and change conversion statuses with the bulk of updates, select the advertiser and dates and click on 'CREATE PAYOUT.'",
  powered_by_tracknow: 'Powered by Tracknow',
  change_email_confirmation_email_sent:
    'A confirmation email has been sent to your current email',
  check_your_data: 'Error',
  are_you_sure_you_want_to_create_a_payout:
    'Are you sure you want to create a payout',
  create_payout_confirmation: 'Create payout confirmation',
  payout_statuses_explanation:
    'Payout Statuses: Approved- payout available for payment. Pending - the payout process still needs to be completed. Denied - payout has been declined for a particular reason.',
  payout_created: 'Payout Created',
  excel_statuses_explanation:
    'Excel Statuses: Change the statuses inside the excel file and upload the excel to the server. \n\n' +
    '  Please use the following statues inside the excel:\n' +
    '  APPROVED - the commission will be available for the affiliate. \n' +
    '  PENDING - the conversion will be changed to pending status. \n' +
    '  DENIED - the conversion will be removed from pending balance/available balance.\n' +
    '\n' +
    '\n' +
    'Please note that payout status is not effect on conversion statuses, if you would like to change the conversion statuses please create a new payout from the conversion screen.\n' +
    '\n' +
    '* Please be advised that conversion statuses will only be changed when the payout status is changed to Approved and excel is uploaded.\n' +
    '\n' +
    '* To change the conversion statuses, please upload the excel file.',
  system_id: 'System ID',
  conversion_sys_id: 'Conversion System ID',
  tax: 'Tax',
  number_must_be_between: 'The number must be between {min} and {max}',
  ready: 'Ready',
  edit_statuses: 'Edit Statuses',
  apply_changes_to: 'Apply Changes Status To ({count})',
  failed: 'Failed',
  processing: 'Processing',
  affiliate_payout: 'Affiliate Payout',
  permissions: 'Permissions',
  read_permissions: 'Read Permissions',
  write_permissions: 'Write Permissions',
  contact_us_email: 'Contact Us Email',
  core_affiliates_only: 'Display MLM (root) only',
  contact_us_email_helper:
    'The affiliate "Contact Us" form will send an email to this address. If this field is empty, the email will be sent to the manager.',
  both_permissions: 'Read/Write Permissions',
  enter_new_password_reset:
    'Enter your new password below to reset your password.',
  referer: 'Referer',
  coupon_code: 'Coupon Code',
  country: 'Country',
  please_enable_smtp_first: 'Please Enable SMTP first',
  scale: 'Scale',
  rotate: 'Rotate',
  crop: 'Crop',
  time_format: 'Time Format',
  time_zone: 'Time Zone',
  billing_description:
    "Discover the Tracknow affiliate platform's billing page. Begin your affiliate marketing journey for free with our Free plan. Upgrade to our Team or Enterprise plans as you grow, unlocking advanced features. Flexible payment options available. Join us today and unleash the power of Tracknow!",
  personal_settings: 'Personal Settings',
  personal: 'Personal',
  company_settings: 'Company Settings',
  day: 'Day',
  year: 'Year',
  month: 'Month',
  group_by: 'Group by',
  deep_link: 'Deep Link',
  tracking_params: 'Tracking Params',
  order_amount: 'Order Amount',
  unlimited_sales: 'Unlimited Sales',
  sales: 'Sales',
  up_to_x_affiliates: 'Up to {0} Affiliates',
  ssl_setup: 'SSL Setup',
  dedicated_smtp: 'Dedicated SMTP',
  custom_commissions: 'Custom Commissions',
  custom_commission_affiliate: 'Custom Affiliate Commissions',
  email_support: 'Email Support',
  no_limit_campaigns: 'Unlimited Campaigns',
  no_limit_affiliates: 'Unlimited Affiliates',
  email_and_chat_support: 'Email and Chat Support',
  dedicated_account_manager: 'Dedicated Account Manager',
  coupon_offline: 'Coupon Offline Tracking',
  invoices: 'Invoices',
  next_subscription_fee_due: 'Next Subscription fee due:',
  you_wiil_return_to_the_free_tier_at:
    'Your subscription was canceled. Please contact our support at support@tracknow.io',
  are_you_sure_you_want_to_reset_cname:
    'Are you sure you want to reset custom domain?',
  reset_cname: 'Reset custom domain',
  subscription_details: 'Subscription details:',
  max_x_capmaigns: '{0} Domains/Offers',
  unlimited_campaigns: 'Unlimited Domains/Offers',
  tax_not_included: 'Excluding VAT',
  email_for_invoice: 'Email for invoice:',
  plans_and_pricing: 'Plans and Pricing',
  reset: 'Reset',
  your_membership_tier: 'Your membership tier:',
  click_here: 'Click here',
  advertiser_should_add_the:
    'Advertiser should add the following parameters to the URL: order_id, amount, goal, for more tracking option please',
  for_a_tutorial_on_how_to_add_postback:
    'For a tutorial on how to add postback please',
  payout_settings: 'Payout settings',
  brand_colors: 'Brand Colors',
  custom_script: 'Custom Script',
  getting_started: 'Getting Started',
  done: 'Done',
  deleted_successfully: 'Deleted successfully',
  creatives: 'Creatives',
  add_new: 'Add New',
  create_a_campaign: 'Create a Campaign',
  adding_an_affiliate: 'Adding an Affiliate',
  affiliate_dashboard: 'Affiliate Dashboard',
  feel_free_to_contact_us: 'For further assistance please contact us',
  campaign_management: 'Campaign Management',
  add_new_crative: 'Add New Creative',
  are_you_sure_you_want_to_delete_this_creative: 'Delete',
  delete_notify: 'Are you sure you want to delete this creative?',
  delete_all: 'Delete All',
  delete_all_creatives_notify:
    'Are you sure you want to delete all of the campaign creatives?',
  creative: 'Creative',
  mlm_commission: 'Referrer Commission',
  mlm_level2_commission: '2nd Referrer Commission',
  mlm_level3_commission: '3nd Referrer Commission',
  mlm_level4_commission: '4nd Referrer Commission',
  mlm_level5_commission: '5nd Referrer Commission',
  allow_mlm: 'Allow MLM (Multi Level Marketing)',
  apply_mlm: 'Apply MLM (Multi Level Marketing)',
  mlm: 'Multi Level Marketing (MLM)',
  mlm_percent: 'MLM Percentage',
  allow_mlm_level_x: 'Allow MLM Level {x}',
  mlm_level_x_percent: 'MLM Level {x} Percent',
  mlm_level_x_commission: '{x}nd Referrer Commission',
  referrer: 'Referrer',
  default_campaign: 'Default Campaign',
  conversion_tracking_setup: 'Conversion Tracking Setup',
  news: 'News & Newsletter',
  content: 'Content',
  add_news: 'Add news',
  edit_news: 'Edit news',
  inactive: 'Inactive',
  draft: 'Draft',
  none: 'None',
  left: 'Left',
  right: 'Right',
  custom_currency_placement: 'Custom Currency Placement',
  custom_currency_symbol: 'Custom Currency Symbol',
  invalid_url: 'Invalid URL',
  categories_tracking: 'Categories tracking',
  phone_support: 'Phone Support',
  chat_support: 'Chat Support',
  unlimited_banners: 'Unlimited Creatives',
  include_in_automation: 'Include In Automation',
  exclude_in_automation: 'Exclude In Automation',
  impressions: 'Impressions (CPM)',
  cpm_commissions: 'CPM Commissions',
  conversion_commissions: 'Conversion Commissions',
  ppc_commissions: 'PPC Commissions',
  support_app_conversions: 'Support APP (Android/IOS) conversions',
  support_app_helper:
    'In case the click and the conversion pixel come from different agent browsers.',
  requested_commission: 'Requested Commission',
  manage: 'Manage',
  select_a_payout: 'Select a Payout',
  select_a_campaign: 'Select a Campaign',
  use_general_capmaign_settings: 'Use General Campaign Settings',
  add_user_to_campaign: 'Assign Affiliate To Campaign',
  payout_groups: 'Payout Groups',
  commissions: 'Commissions',
  affiliate_details: 'Affiliate Details',
  creative_empty_desc: 'No Creatives.',
  country_code_required: 'Country is required in registration',
  custom_docs: 'Custom Documents',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
  DECLINED: 'Declined',
  NEEDS_ATTENTION: 'Needs Attention',
  INCOMPLETE: 'Incomplete',
  EXPIRED: 'Expired',
  update_status: 'Update Status',
  options: 'Options',
  select_helper: 'Separate the select values by a comma. Example: Male,Female.',
  select_files: 'Select files',
  drop_files_here_or_click_browse_through_your_machine:
    'Drop files here or click browse thorough your machine',
  remove_all: 'Remove all',
  upload_files: 'Upload files',
  no_documents_required: 'No documents required',
  admin_status_changed_email: 'Admin status changed',
  admin_status_approved_email: 'Admin status approved',
  when_company_requires_documents_upload_here:
    'When a company requires documents you will need to upload them here',
  single_campaign_mode: 'Single Campaign Mode',
  single_campaign_mode_off: 'All campaigns are accessible.',
  single_campaign_mode_on:
    'Access is restricted to default campaigns (company or user-set).',
  country_grouping: 'Country Group',
  grouping: 'Grouping',
  create_grouping: 'Create Group',
  symbols: 'Symbols',
  symbol_grouping: 'Symbol Group',
  create_symbol: 'Create Symbol',
  default_status: 'Default Status',
  payout_type: 'Payout Type',
  confirmation: 'Confirmation',
  copy_short_url: 'Copy Short Link',
  generate_short_url: 'Generate Short Link',
  short_url: 'Short Link',
  after_activation:
    'Welcome (The email will be sent only when the body text exists)',
  users: 'Users',
  forex: 'Analytics Reports',
  add_user: 'Add User',
  edit_user: 'Edit User',
  first_deposit_date: 'First Deposit Date',
  user_id: 'User ID',
  lead_id: 'Lead ID',
  balance: 'Balance',
  volume: 'Volume',
  lastAffiliateGroupChangeDate: 'Last Affiliate Group Change Date',
  firstDepositDate: 'First Deposit Date',
  account_id: 'Account Id',
  account_currency: 'Account Currency',
  add_account: 'Add Account',
  edit_account: 'Edit Account',
  add_transaction: 'Add Transaction',
  edit_transaction: 'Edit Transaction',
  accounts: 'Accounts',
  account: 'Account',
  forex_account: 'Forex Account',
  profit: 'Profit',
  symbol: 'Symbol',
  transactions: 'Transactions',
  transaction_date: 'Transaction Date',
  open_price: 'Open Price',
  close_price: 'Close Price',
  show_symbols: 'Show Symbols',
  qualified: 'Qualified',
  leverage: 'Leverage',
  user: 'User',
  symbol_payout_modal_title: 'Add Price For Symbol',
  symbol_payout_modal_desc: 'Add Price For Symbol',
  position_count: 'Position Count',
  net_deposit: 'Net Deposit',
  ask_price: 'Ask Price',
  bid_price: 'Bid Price',
  transaction_type: 'Transaction Type',
  broker_commission: 'Broker Commission',
  revshare_type: 'Revshare Type',
  currency_price: 'Currency Price',
  converted_volume: 'Converted Volume',
  more_options: 'More Options',
  financial_users: 'Users',
  financial_accounts: 'Accounts',
  first_time_deposit: 'First Time Deposit',
  new_country_grouping: 'New Country Group',
  new_symbol_grouping: 'New Symbol Group',
  currency_change_title: 'Alert: Currency Change Confirmation',
  currency_change_desc:
    'You are about to change your company currency. Please note that this will change your conversion currency value and may impact on your account settings. ',
  tax_id: 'Tax Id',
  affiliate_settings: 'Affiliate Settings',
  hideOrderId: 'Hide Order Id (Affiliate)',
  hideAmount: 'Hide Amount (Affiliate)',
  allow_ip_explain:
    'Only postbacks from these IPs will be allowed unless added to Disallowed IPs (Whitelist IPs). The IPs should be split by a comma. For example: 127.0.0.1, 192.17.17.1. Keep the empty entry to allow all IP addresses.',
  disallow_ip_explain:
    'All postbacks from these IPs will be blocked (Blacklist IPs). The IPs should be split by a comma. For example: 127.0.0.1, 192.17.17.1. Keep the empty entry to allow all IP addresses.',
  api_allow_ip_explain:
    'Only API key requests from these IPs will be allowed (Whitelist IPs). The IPs should be split by a comma. For example: 127.0.0.1, 192.17.17.1. Keep the empty entry to allow all IP addresses.',
  managers_allow_ip_explain:
    'Only Managers logged in from these IPs will be allowed (Whitelist IPs). The IPs should be split by a comma. For example: 127.0.0.1, 192.17.17.1. Keep the empty entry to allow all IP addresses.',
  setup_included: 'Setup Included',
  affiliate_platform_name: 'Affiliate Platform Name',
  assign_personal_payouts: 'Assign Personal Payouts',
  add_notes: 'Add Notes',
  author: 'Author',
  new_note: 'New Note',
  net_deposits: 'Net Deposits',
  notes: 'Notes',
  referrals: 'Referrals',
  screens: 'Screens',
  fields: 'Fields',
  financialAccountId: 'Account Id',
  financialTransactionsProfit: 'Transactions Profit',
  financialTransactionsId: 'Transactions Id',
  financialUserBalance: 'User Balance',
  view: 'View',
  generate_qr_code: 'Generate QR Code',
  qr_code: 'QR Code',
  search_by_user_id_or_lead_id: 'Search by User ID or Lead ID',
  postback_stats: 'Postback',
  success: 'Success',
  fail_reason: 'Reason',
  click_id: 'Click Id',
  logs: 'Logs',
  ip: 'IP',
  parameters: 'Parameters',
  top_affiliates: 'Top Affiliates',
  disallowed_countries: 'Disallowed Countries',
  changes: 'Changes',
  compare: 'Compare',
  entity_id: 'Entity ID',
  entity_class: 'Entity Class',
  activity_type: 'Activity Type',
  audit: 'Audit',
  info: 'Info',
  company: 'Company',
  individual: 'Individual',
  requireAffiliateLegalStatus: 'Require Affiliate Legal Status',
  requireAffiliate2FA: 'Require Affiliate 2FA',
  requireCompanyName: 'Require Company Name',
  please_verify_you_are_not_a_robot: 'Please verify you are not a robot',
  please_fill_captcha: 'Please complete the captcha below:',
  two_factor_auth_dialog_title: 'Two-Factor Authentication Request',
  enable: 'Enable',
  two_factor_auth_dialog_description:
    'The current user has enabled Two-Factor Authentication (2FA) for added security. To proceed with the requested action, please enter the One-Time Password (OTP)',
  '2fa': 'Two Factor Authentication (2FA)',
  '2fa_active': '2FA Active',
  enter_6_digit_code: 'Enter 6 digit code',
  INVALID_2FA_TOKEN: 'Invalid 2FA Token',
  MISSING_2FA_TOKEN: 'Missing 2FA Token',
  payout_automation: 'Payouts Automation',
  add_payout_automation: 'Add Payout Automation',
  automation_type: 'Automation Type',
  original_payout_setting: 'Original Payout Setting',
  campaign_payout_automation_tiers: 'Campaign Payout Automation Tiers',
  payout_tiers: 'Payout Tiers',
  tier_threshold: 'Tier Threshold',
  threshold: 'Threshold',
  campaign_payout_automation_title: 'Automatic Affiliate Payout Adjustments',
  campaign_payout_automation_subtitle: `We've introduced an enhanced feature to optimize affiliate payouts
    based on performance. Here's how it works:`,
  campaign_payout_automation_desc_evaluation:
    '<strong>Performance Evaluation:</strong> At the start of each month, the system calculates affiliate performance using key metrics such as Conversion Amount, Volume, and Conversion Count.',
  campaign_payout_automation_desc_performance_tiers:
    '<strong>Performance Tiers with Thresholds:</strong> Affiliates are assigned to specific tiers based on their performance relative to predefined thresholds.',
  campaign_payout_automation_desc_rewarding_performance:
    '<strong>Payout Adjustments:</strong> Affiliates who surpass their goals from the previous month are automatically promoted to a higher payout tier.',
  campaign_payout_automation_desc_personalized_payouts:
    '<strong>Bonus Rewards for Affiliates:</strong> Affiliates earn bonuses by meeting specific thresholds. If multiple bonuses are available within a single automation, the system awards the bonus linked to the highest threshold achieved. The bonus amount is defined in the Bonus Payout settings.',
  are_you_sure_you_want_to_run_this_automation:
    'Are you sure you want to run automation?',
  run: 'Run',
  last_run_date: 'Last Run Date',
  financial_stats: 'Stats',
  deposits: 'Deposits',
  withdrawals: 'Withdrawals',
  withdrawals_amount: 'Withdrawals Amount',
  client_details: 'Client Details',
  latest_clients: 'Latest Clients',
  registrations: 'Registrations',
  advanced_tracking: 'Advanced Tracking',
  NO_PERMISSION:
    'You dont have permission to perform this operation, Please contact the administrator',
  financial_transactions: 'Transactions',
  click_logs: 'Click Logs',
  weight: 'Weight',

  weight_helper:
    'The Weight value represents the probability; the higher the value, the greater the chance it will be displayed. This function is used for A/B testing.',
  direct_link: 'Direct Link',
  direct_link_title: 'Direct Link (Setup required)',
  direct_link_subtitle:
    'An advertiser should place this code after the opening <body> tag on the landing page. ',
  direct_link_helper:
    'Enable this option to keep your Tracking URL unchanged for better SEO. Please note that with the Direct Link, advanced tracking features like macros will be unavailable. To ensure accurate data collection, a small script needs to be implemented on your website. Feel free to switch between Direct Link and traditional methods based on your tracking preferences. For any assistance, our support team is ready to help.',
  dont_show_again: "Skip & Don't Show Again",
  dont_show_again_desc:
    "By selecting this option, the Getting Started section will be hidden from the menu, providing a more streamlined user experience. It's essential to understand that you can easily adjust all settings by visiting the Settings page whenever you wish, allowing you to personalize your experience according to your preferences. Should you have any questions or need assistance, feel free to contact our dedicated support team. \nYour satisfaction is important to us, and we are here to assist you.",
  campaign_advanced_tracking_title: 'Introducing our enhanced features',
  campaign_advanced_tracking_subtitle: `Advanced Tracking and A/B Testing. Discover how these features can benefit you:`,
  campaign_advanced_tracking_geo: `<strong>Advanced tracking for geo targeting strategy</strong> - It allows you to automatically guide users from specific countries to the designated pages, thereby maximizing audience targeting. 
  Setup instructions can be found <a href="https://help.tracknow.io/knowledge-base/advanced-tracking/" target=“_blank”>here</a>.`,
  campaign_advanced_tracking_ab: `<strong>A/B testing </strong>- make data-driven decisions by comparing the performance of different webpages, different url, or elements through A/B testing to identify the top-performing variant. 
  Setup instructions can be found <a href="https://help.tracknow.io/knowledge-base/advanced-tracking-a-b-testing/" target=“_blank”>here</a>.`,
  yearly: 'Yearly',
  monthly: 'Monthly',
  x_off: '{discount}% Off',
  everything_in: 'Everything in {previousPlan} plan and:',
  includes: 'Includes:',
  compare_plans: 'Compare Plans',
  purchase_date: 'Purchase Date',
  invalidPasswordMessage:
    'The password must contain at least one alphabetic character, one numeric digit, one special character from the following set (!@#$%^&*()_+), and be between 10 and 20 characters in length.',
  download_sample_CSV: 'Download Sample CSV',
  import_affiliates: 'Import Affiliates',
  import_affiliates_desc:
    'Effortless Data Management: Upload CSV, Seamlessly Edit Fields (Double Click), and Submit with Error Handling for a Smooth Experience',
  payout_desc: 'Custom Payout Text',
  payout_desc_helper:
    'If the Custom Payout Text is empty, we will display the payout based on the setup',
  total_paid: 'Total Paid',
  payout_request_created: 'Payout Request Created',
  platform: 'Platform',
  username: 'Username',
  social: 'Social',
  social_profiles: 'Social Profiles',
  video: 'Video',
  carousel: 'Carousel',
  story: 'Story',
  refresh_social: 'Refresh Social',
  campaign_social_tracking_title:
    'Introducing our innovative unique Social Tracking Feature',
  campaign_social_tracking_subtitle: `Our latest feature offers a unique solution for businesses, that aim to work  with influencers, allowing them to:`,
  campaign_social_tracking_monitor: `Monitor and analyze the performance of all influencers associated with your brand across Instagram and TikTok, all in one centralized location.`,
  campaign_social_tracking_manage: `Easily manage influencer compensation by paying them per Post, Story, or Like, streamlining the process for both influencers and brands.`,
  followers: 'Followers',
  influencers: 'Influencers',
  posts: 'Posts',
  stories: 'Stories',
  comments: 'Comments',
  likes: 'Likes',
  views: 'Views',
  load_more: 'Load More',
  social_tracking: 'Social Tracking',
  manage_all_affiliates: 'Manage All Affiliates',
  http_method: 'Http Method',
  url: 'URL',
  webhook: 'Webhook',
  webhook_type: 'Webhook Type',
  webhooks: 'Webhooks',
  webhook_logs: 'Webhook Logs',
  please_add_a_payment_method:
    "Affiliate hasn't configured a payment method yet",
  balance_sheet: 'Balance Report',
  balance_sheet_dates: 'Balance Report (Dates)',
  paid_payouts: 'Paid Payouts',
  pending_payouts: 'Pending Payouts',
  pending_manual: 'Pending Adjusment',
  approved_manual: 'Approved Adjusment',
  assign_affiliates: 'Assign Affiliates',
  create_affiliate_url: 'Create Affiliate URL',
  brand_fav_icon: 'Brand Favicon',
  custom_id: 'Custom ID',
  custom_id_helper:
    'A custom user ID to uniquely identify users in external tracking and analytics systems. This ID is used as a macro in tracking parameters allowing for personalized user identification in links',
  become_an_advertiser: 'Become an Advertiser',
  become_an_advertiser_desc:
    'Please fill out the form below to request to join our platform as an advertiser',
  become_an_advertiser_res:
    'Thanks for submitting your query to become an advertiser. Once approved, you will receive login credentials by email',
  advertiser_registrations: 'Advertiser Registrations',
  allow_affiliate_register: 'Allow Affiliate Registration',
  allow_advertiser_register: 'Allow Advertiser Registration',
  advertiser_user_management: 'Advertiser User Management',
  advertiser_login: 'Advertiser Login',
  advertiser_user_login_desc:
    'Please note that every action taken upon login is solely your responsibility and will impact the advertiser account.',
  edit_advertiser_user: 'Edit User Advertiser',
  advertiser_user_updated: 'User Advertiser Updated',
  add_advertiser_user: 'Add User Advertiser',
  create_advertiser_user: 'Create User Advertiser',
  custom_merchant_domain: 'SSL Custom Manager Domain (for advertisers)',
  login_as_advertiser: 'Login as Advertiser',
  login_as_admin: 'Login as Admin',
  adv_registration_url: 'Advertiser Registration URL',
  admin: 'Admin',
  mlm_settings: 'Multi Level Marketing (MLM)',
  registration_settings: 'Registration Settings',
  edit_users: 'Edit Users',
  company_setting_alert_title: 'Confirm Settings Change',
  company_setting_alert_desc: `By clicking "OK", you acknowledge that the upcoming changes will affect the company settings`,
  block_user: 'Block User',
  blocked: 'Blocked',
  unblock_user: 'Unblock User',
  include_deleted_affiliates: 'Include Deleted Affiliates',
  additional_filters: 'Additional Filters',
  import_items: 'Import Items',
  quantity: 'Quantity',
  total_price: 'Total Price',
  cart: 'Cart',
  add_row: 'Add Row',
  custom_view: 'Custom View',
  save_view: 'Save Current View',
  update_view: 'Update View',
  create_view: 'Create View',
  deleted: 'Deleted',
  export_mass_pay_csv: 'Export mass pay CSV',
  bulk_payment_wise: 'Bulk payment Wise',
  mass_pay_paypal: 'Mass pay Paypal',
  mass_pay_paypal_action: 'Click here to pay with MASS PAY',
  trade_length: 'Trade Length',
  open_time: 'Open Time',
  trade_length_helper:
    'Trade Length represents the minimum duration required for eligible commissions.',
  import_creatives: 'Import Creatives',
  total_earned: 'Total Earned',
  customMlmEnabled: 'Enable Custom MLM',
  custom_mlm: 'Custom MLM',
  mlm_fixed: 'Use Fixed Commission',
  js_landing_page_head: 'Javascript Landing Page Head',
  js_stripe_payment_links: 'js stripe payment links location',
  js_stripe_link_page:
    'An advertiser should place the code on the payment links page. Change `initPaymentLink("stripePaymentLinkId")` to match your element\'s ID',
  add_stripe_webhook:
    'Add the webhook URL to Stripe webhooks and subscribe to the `checkout.session.completed` event.',
  stripe_webhook: 'Stripe Webhook',
  generate_api_key: 'Generate API Key',
  expiration_date: 'Expiration Date',
  admin_override: 'Admin Override',
  affiliate_doc: 'Affiliate Document',
  payment_approved_email: 'Payment Approved Email',
  payment_declined_email: 'Payment Declined Email',
  body: 'Body',
  blocked_only: 'Blocked Only',
  unblocked_only: 'Unblocked Only',
  deleted_only: 'Deleted Only',
  active_only: 'Active Only',
  display_all: 'Display All',
  advertiser_status_approved: 'Advertiser Status Approved',
  email_config_activation: 'Activation',
  email_config_reset_password: 'Reset Password',
  email_config_changed_email: 'Email Changed',
  email_config_welcome:
    'Welcome (The email will be sent only when the body text exists)',
  email_config_request_to_join_campaign_approved:
    'Request to Join Campaign Approved',
  email_config_request_to_join_campaign_declined:
    'Request to Join Campaign Declined',
  email_config_admin_status_approved: 'Admin Status Approved',
  email_config_admin_status_changed: 'Admin Status Changed',
  email_config_payment_request_approved: 'Payment Request Approved',
  email_config_payment_request_declined: 'Payment Request Declined',
  email_config_new_mlm_reg:
    'New MLM Registration (The email will be sent only when the body text exists)',
  email_config_new_reg_lead:
    'New Registration Lead (The email will be sent only when the body text exists)',
  email_config_validation_message: `Email must includes the following Macros: {0}`,
  hard_delete: 'Hard Delete',
  approved_mlm_level_x: 'Approved MLM Level {0}',
  pending_mlm_level_x: 'Pending MLM Level {0}',
  level: 'Level',
  approved_commission: 'Approved Commission',
  pending_commission: 'Pending Commission',
  restore: 'Restore',
  free_trial_ending: 'Free Trial Ending {0}',
  affiliate_promotional_material: 'Promotional Material Consent',
  agree_to_promotional_material: 'Promotional material',
  agree_to_receive_marketing_material: 'I agree to receive marketing material',
  privacy_page_message: `Please note that your consent to the following terms and conditions and privacy policy is mandatory in order to be GDPR compliant.
  You may withdraw your consent at any. However, you will be automatically logged out of the system.`,
  marketing_material: 'Marketing Material',
  i_have_read_and_agreed: 'I have read and agreed to',
  confirmation_required: 'Confirmation Required',
  by_unchecking_you_will_not_have_access_to_the_system: `By unchecking the Terms and Condition or the Privacy Policy box you will no longer have access to the system. To continue, please enter your password.`,
  delete_my_account: 'Delete My Account',
  delete_my_account_confirmation:
    'By clicking Confirm, you will permanently delete your account and all associated data. This action cannot be undone.',
  custom_status: 'Custom Status',
  integrations: 'Integrations',
  edit_tracking_domain: 'Edit Tracking Domain',
  add_tracking_domain: 'Add Tracking Domain',
  tracking_domain: 'Tracking Domain',
  tracking_domains: 'Tracking Domains',
  default_tracking_domain: 'Default Tracking Domain',
  set_up: 'Set Up',
  advertiser_features: 'Advertiser Features',
  automation: 'Automation',
  roles_and_permissions: 'Roles and Permissions',
  invoicing_system: 'Invoicing system',
  seo: 'SEO',
  seo_index: 'Indexed',
  affiliate_emails: 'Affiliate Emails',
  customize: 'Customize',
  send_manager_alert_on_new_affiliate_payout:
    'Send Manager Email Alert On New Affiliate Payout',
  send_manager_alert_on_new_affiliate:
    'Send Manager Email Alert On New Affiliate Registration',
  profile_pic: 'Profile Picture',
  skype: 'Skype',
  skype_username: 'Skype Username',
  linkedin: 'Linkedin',
  linkedin_profile: 'Linkedin Profile Link',
  whatsapp: 'Whatsapp',
  contact_info: 'Contact Information',
  contact_info_desc:
    'The contact details will be displayed to your affiliates. Empty fields will remain hidden.',
  count: 'Conversions',
  top_countries: 'Top Countries',
  custom_statuses: 'Custom Statuses',
  add_custom_status: 'Add Custom Status',
  add_category: 'Add Category',
  edit_category: 'Edit Category',
  edit_custom_status: 'Edit Custom Status',
  top_symbols: 'Top Symbols',
  display_conversion_personal_info: 'Display Conversion Personal Info',
  display_conversion_personal_info_helper:
    'Display the email, first name, last name, and phone number',
  primary_color: 'Primary color',
  secondary_color: 'Secondary color',
  dark_mode: 'Dark Mode',
  light_mode: 'Light Mode',
  free_mode: 'Free mode',
  use_a_semicolon:
    'Use a Semicolon to add multiple currency pairs, for instance: AUDCAD;EURUSD',
  new_symbols_will_be_added:
    'New symbols will be added automatically with pip 4 (can be changed later)',
  group_name: 'Group Name',
  send_as_newsletter: 'Send As Newsletter',
  translations: 'Translations',
  edit_translation: 'Edit Translation',
  platform_translation_management: 'Translation Management',
  platform_translation_desc: `Manage and customize translations. Double-click any cell to override translations for specific languages. Note that changes are your responsibility and may affect user experience. Ensure placeholders (e.g., {placeholder} or [1]) remain unchanged to display the data correctly.`,
  sent: 'Sent',
  send_test_email: 'Send Test Email',
  send_as_newsletter_desc: `This newsletter will be sent to all active affiliates (if a campaign is selected, only affiliates in the campaign will receive an email). This action cannot be undone and will only work if the company has SMTP or Amazon SES Alias setup.`,
  send_as_newsletter_desc_test: `This newsletter will be sent to your Email. This action cannot be undone and will only work if the company has SMTP or Amazon SES Alias setup.`,
  ip_cooldown: 'IP Cooldown Period',
  ip_cooldown_helper:
    'The waiting period between each conversion for a given IP address.',
  instant: 'No Cooldown',
  one_hour: '1 Hour',
  one_day: '1 Day',
  one_week: '1 Week',
  one_month: '1 Month',
  one_year: '1 Year',
  display_accessibility_menu: 'Display Accessibility Menu',
  display_deposits_and_withdrawals:
    'Display Deposits and Withdrawals by Default',
  show_deposits: 'Deposits',
  show_net_deposits: 'Net Deposits',
  show_withdrawals: 'Withdrawals',
  api_keys: 'API Keys',
  max_commission: 'Max Commission',
  affiliate_display_options: 'Affiliate Display Options',
  block_filter: 'Block Filter',
  min_deposit: 'Min Deposit',
  max_deposit: 'Max Deposit',
  ftd_only: 'FTD',
  contact_platform: 'Messenger',
  lifetime_affiliates: 'Lifetime',
  customer_id: 'Customer Id',
  new_payout_group: 'New Payout Group',
  payout_grouping: 'Payout Group',
  payout_grouping_desc:
    'Payout Groups are dedicated to providing quick and efficient handling of payout assignments',
  select_the_countries_informational:
    'Select the countries you would like to include or exclude in your affiliate search dashboard. This selection is for informational purposes only and will help the affiliate find the right offer.',
  mlm_type: 'MLM Type',
  brand_banner: 'Brand Banner',
  ftd_amount: 'FTD Amount',
  ftd_exclusive: 'FTD Only',
  ftd_exclude: 'FTD Exclude',
  displayCustomIdPicker: 'Display Custom ID Picker',
  add_to_dashboard: 'Add to Dashboard',
  remove_from_dashboard: 'Remove from Dashboard',
  edit_new_creative: 'Edit New Creative',
  click_funnel_webhook: 'Clickfunnel Webhook',
  add_clickfunnel_webhook:
    "Add the webhook URL to clickfunnel classic webhooks and subscribe to the `purchase_created` event. Please note! It's required to add ?aff_sub={click_id} parameter to the Tracking URL.",
  remove_affiliate_withdrawal_request: 'Remove Affiliate Withdrawal Request',
  remove_withdrawal_info_helper:
    'When you turn on the option, the associated withdrawal request button will be removed from the affiliate portal',
  leads: 'Leads',
  lead_created: 'Lead Created',
  lead_updated: 'Lead Updated',
  add_lead: 'Add Lead',
  create_lead: 'Create Lead',
  edit_lead: 'Edit Lead',
  NEW: 'New',
  CONTACTED: 'Contacted',
  QUALIFIED: 'Qualified',
  CONVERTED: 'Converted',
  CLOSED: 'Closed',
  UNQUALIFIED: 'Unqualified',
  holdPeriod: 'Hold Period',
  holdPeriod_helper:
    'Our system updates conversions based on your settings approximately every 15 minutes. Any conversions reach the hold period will be automatically updated to "APPROVED."',
  campaign_name_clone: 'Confirm {campaignTitle} Clone',
  clone: 'Clone',
  caps: 'Caps',
  cap_type: 'Cap Type',
  priority: 'Priority',
  priority_helper:
    'Higer Priority means higer place in cap filter when no campaign is sent',
  timeframe: 'Timeframe',
  value: 'Value',
  campaign_clone_message:
    'You are about to clone this campaign. Please note the following: \n \u200B \n1. Affiliates will not be cloned. \n2. Only settings related to tracking, general configuration, and payouts will be copied.\n3.The cloned campaign will be paused. \n \u200B \n Are you sure you want to proceed with the clone?',
  campaign_payout_clone_message:
    'You are about to clone this payout. \n Please confirm to proceed with the cloning process.',
  CONVERSION_COUNT_desc:
    'Limits the number of conversions (e.g., sales) an affiliate can generate within a specified period.',
  CONVERSION_COMMISSION_SUM_desc:
    'Sets a maximum total payout that an affiliate can earn in commissions.',
  LEAD_desc:
    'Controls the number of potential customer leads an affiliate can provide.',
  CONVERSION_COUNT: 'Conversions',
  CONVERSION_COMMISSION_SUM: 'Affiliate Commission Sum',
  LEAD: 'Leads',
  HOUR: 'Hour',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  ALL: 'All',
  CONVERSION_CREATED: 'Conversion Created',
  CONVERSION_UPDATED: 'Conversion Updated',
  LEAD_CREATED: 'Lead Created',
  LEAD_UPDATED: 'Lead Updated',
  unique_headerKey: 'Every Header must have a unique key',
  headers: 'Headers',
  header_key: 'Header Key',
  header_value: 'Header Value',
  status_code: 'Status Code',
  add_header: 'Add Header',
  campaign_cpas_subtitle: 'Cap Types Available:',
  campaign_cpas_subtitle2:
    'Affiliates with higher priority can send or provide more conversions, leads, or clicks until they reach their cap. Once their cap is reached, additional leads, conversions, or clicks will be denied. This ensures top-performing affiliates can maximize their potential while keeping costs and quality in check for clients.',
  campaign_cpas_disclamer:
    'Please note, that the reset and calculation of these caps is synchronized globally to UTC time, not adjusted to the local time zone of the client or affiliate',
  campaign_cpas_title: `The caps with priority feature allows you to manage conversions, leads, and clicks based on set caps (limits) and assigned priorities. This feature provides an effective way to attract prioritized conversions, clicks, and leads while preventing overspending.`,
  allow_api_keys_by_default: 'Allow API Keys By Default',
  allow_leads_by_default: 'Allow Leads By Default',
  allow_api_keys_helper: 'This change will not affect on existing affiliates',
  automatically: 'Automatically',
  by_affiliate_request: 'By Affiliate Request',
  choose_how_to_handle_payouts:
    'Choose how you want to handle the payouts. Automatically or by Affiliate Request.',
  period_type: 'Period Type',
  day_of_month: 'Day of Month',
  day_of_week: 'Day of Week',
  allow_custom_amount_helper:
    'Turn on this option to allow affiliates to enter a payout amount manually.',
  allow_attachment_helper:
    'Turn on this option to allow payout request attachment.',
  allow_enter_amount: 'Allow Enter Amount',
  allow_an_attachment: 'Allow Attachment',
  payme: 'Credit Card',
  update_payment_method: 'Update Payment Method',
  add_payment_method: 'Add Payment Method',
  form_editor: 'Form Editor',
  form_preview: 'Form Preview',
  lifetime_lifecycle: 'Lifetime Lifecycle',
  lifetime_lifecycle_helper:
    'Adds an expiration date for new lifetime affiliates. This change applies only to new lifetime affiliates created after the implementation of this policy.',
  has_expiration_date: 'Has Expiration Date',
  subscriptionMessage:
    'A recurring payment of {price} will be charged to your account.',
  subscriptionMessage2:
    '* This payment will continue until you choose to cancel your subscription.',
  invoice_details: 'Invoice Details',
  deepUrlParams: `Deep Url Query Params`,
  deepUrlParams_helper:
    'Query parameters to attach to the deep URL, added to the tracking link. For example: "is_deep_link=1&click_id={click_id}". Note that macros are allowed.',
  tiers_migration: 'Tiers',
  bonus: 'Bonus',
  threshold_reward_type: 'Reward Type',
  campaign_payout_automation_bonuses: 'Campaign Payout Automation Bonuses',
  recurring_bonus: 'Recurring Bonus',
  recurring_bonus_helper_text:
    "When on, the bonus is calculated by timeframe. When off, it's a one-time bonus per affiliate.",
  estimated_original_price: 'Estimated Original Price',
  conversion_rate: 'Conversion Rate',
  deposits_count: 'Dep.',
  registrations_count: 'Reg.',
  deposits_amount: 'Dep. Amount',
  igaming_reports: 'IGaming Reports',
  clicks_to_registrations: 'CTR',
  registrations_to_deposits: 'Reg. To Dep.',
  clicks_to_deposits: 'Clicks To Dep',
  fraud_score: 'Fraud Score',
  send_credentials_by_mail: 'Send Credentials by Email',
  import_conversions: 'Import',
  referer_id: 'Referer Id',
  sendWebhook: 'Send Webhook',
  resend_webhook: 'Resend Webhook',
  in_progress: 'In Progress',
  mass_pay_tipalti: 'Batch Pay Tipalti',
  calculate_tax: 'Calculate Tax',
  add_current_page: 'Add Current Page',
  add_shortcut: 'Add Shortcut',
  search_icons: 'Search Icons',
  edit_shortcut: 'Edit Shortcut',
  latest_leads: 'Latest Leads',
  shortcut_url_helper:
    'Use internal links (/app/...), external links, or keep the current URL.',
  shortcuts_helper: 'Add shortcuts for quick access',
  featured_helper:
    'This option will keep the campaign on top of other campaigns.',
  calculate_tax_helper: 'This option will apply a tax reduction to the amount.',
  postback: 'Postback',
  custom_ref_id: 'Custom Referer Id',
  import_symbols: 'Import Symbols',
  update_conversions: 'Update',
  if_commission_not_specified:
    'If not specified, the commissions will be automatically adjusted based on the amount.',
  copy_campaign_reg_link: 'Campaign Registration URL',
  campaign_reg_description:
    'Please note that affiliates registered under the campaign registration URL will automatically be assigned to the campaign in single campaign mode.',
  should_affiliates_change:
    'Would you like to apply the IB change to all accounts under the client?',
  reset_logo: 'Reset Logo',
  pending_approvals: 'Pending Approvals',
  what_is_your_business_type: 'What is your business type?',
  request_an_account: 'Request an account',
  join_requests: 'Join Requests',
  min_amount: 'Minimum Amount',
  min_amount_helper: 'The minimum amount required to activate the payment',
  view_more: 'View more',
  click_date: 'Click Date',
  email_username: 'Email (Username Optional)',
  email_userName_helper:
    'Only email can be used to log in to the affiliate portal',
  wechat: 'WeChat',
  wechat_id: 'WeChat Id',
  last_trade_date: 'Last Trade Date',
  positive_carry: 'Positive Carry',
  ngr: 'NGR',
  positive_carry_helper:
    'The positive carry will automatically adjust any negative balance to zero, the positive payout balance will remain unchanged.',
  stats: 'Overview',
  field_name_html_override: 'HTML Value Override',
  brand_profit: 'Brand Profit',
  hide_short_link: 'Hide Short Link (Affiliate)',
  save_all_pixels: 'Save All Pixels (Not Recommended)',
  save_all_pixels_helper:
    "(Not recommended) Allows to save conversion on every pixel or postback, regardless of whether there's an affiliate match. This means that conversions will be saved even if it cannot be attributed to a specific affiliate",
  campaignMustMatch: 'Campaign Match',
  campaignMustMatch_helper:
    "When this setting is enabled, the system will only attribute the conversion to the campaign after the client clicks on the campaign's affiliate URL.",
  useCouponOverTracking: 'Coupon Override',
  useCouponOverTracking_helper:
    'Coupon codes take priority over affiliate tracking. Conversions will be assigned to the affiliate linked to the coupon, not the tracking affiliate.',
  allowAffiliatesToSetGoal: 'Allow Override Goal',
  allowAffiliatesToSetGoal_helper:
    'When enabled, the goal value can be overridden by the tracking URL to assign a specific payout to it.',
  campaign_categories: 'Campaign Categories',
  categories: 'Categories',
  default_language_label: 'Default Language',
  select_default_language: 'Select Default Language',
  preferred_languages_label: 'Preferred Languages',
  affiliate_portal_languages: 'Affiliate Platform Languages',
  default_language_helper:
    'This will be the default language for new users when they first access the portal. It will also appear as the first option in the language selection dropdown',
  preferred_languages_helper:
    'Select the languages that will be available in your portal. If left empty, all supported languages will be available. Note: If only one language is selected, users will not have the option to change the language in the portal.',
  affiliate_group: 'Affiliate Group',
  add_affiliate_group: 'Add Affiliate Group',
  edit_affiliate_group: 'Edit Affiliate Group',
  custom_group_by: 'Custom',
  allow_balance_by_default: 'Allow Balance By Default',
  allow_leverage_by_default: 'Allow Leverage By Default',
  allow_profit_by_default: 'Allow Profit By Default',
  default_settings: 'Default Permission Settings',
  tax_percent: 'Tax Percent',
  confirm: 'Confirm',
  ok: 'OK',
  enable_revision: 'Enable Revision',
  revision: 'Revision',
  revision_helper:
    'The percentage of conversions that will be marked for revision. These conversions will not be visible to affiliates or reflected in their balances until reviewed and updated by a manager.',
  revision_percentage: 'Revision Percentage',
  landing_page: 'Landing Page',
  default_landing_page: 'Default Landing Page',
  total_commission_with_tax: 'Total Commission With Tax',
  affiliate_email_preferences: 'Affiliate Email Preferences',
  receive_new_registration_lead_email: 'New Registration Lead',
  receive_new_mlm_registration_email: 'New MLM Registration',
  affiliate_tax_percent_helper:
    'The tax could be either negative or positive. If positive, the tax amount will be added to the affiliate payment request; if negative, it will be deducted from the request.',
  receive_updates:
    'I agree to receive updates about the product, news and special offers',
  available_balance: 'Available Balance',
  affiliate_id: 'Affiliate ID',
  campaign_tracking_tools: 'Tracking Tools',
  campaign_setup: 'Setup/Integration',
  affiliate_assignment: 'Affiliate Assignment',
  min_revision_fraud_score: 'Min Revision Fraud Score',
  min_revision_fraud_score_helper:
    'Conversions with a fraud score equal to or above this status will be marked with the Revision status',
  min_denied_fraud_score: 'Min Denied Fraud Score',
  min_denied_fraud_score_helper:
    'Conversions with a fraud score equal to or above this status will be marked with the Denied status',
  disable_2fa: 'Disable 2FA',
  login_hero_title1: 'Tailored Solution',
  login_hero_subtitle1:
    'Our product adjusts to your brand needs, upgrade your affiliate solution today.',
  login_hero_title2: 'Advanced Commission Rules',
  login_hero_subtitle2:
    'Customised commissions by affiliate, groups, tiers and goals.',
  login_hero_title3: 'Great User Experience',
  login_hero_subtitle3: 'Designed & branded interface and highly customisable.',
  login_hero_title4: 'Innovative Functionality',
  login_hero_subtitle4:
    'Advanced features that set new standards, MLM, Anti Fraud, Payout Automation and more!',
  top_campaign_payouts: 'Top Campaign Payouts',
  campaign_default_currency: 'Campaign Default Currency',
  campaign_default_currency_helper:
    'Select the default currency for this campaign. Amounts received via postback will initially be interpreted in this currency and then converted to the company’s default currency unless a different currency is specified in the postback parameters. Leave empty to use the company’s default currency.',
  company_grid_control: 'Company-Wide Grid Control',
  company_grid_control_helper: `Enable this option to centralize the DataGrid layout for all affiliates.
Note: Disabling this option will restore the ability for affiliates to manage their own grid layouts.`,
  company_grid_control_helper2: `When enabled:
** The manager can log in as the default affiliate (Affiliate 1) to configure column visibility and order.
** This configuration will apply to all affiliates, ensuring a consistent grid layout across the platform.
** Affiliates will no longer be able to customize their own DataGrid settings.
Note: Disabling this option will restore the ability for affiliates to manage their own grid layouts.`,
  affiliate_managed_groups: 'Affiliate Managed Groups',
  affiliate_managed_groups_helper: `Controls the manager's access to affiliates:
Affiliate Managed Groups: Limits access to affiliates within the selected groups.
  * If groups are specified, the manager can only manage affiliates in those groups.
  * If left empty, access depends on the Manage All Affiliates setting.
Manage All Affiliates:
  * If enabled (true), the manager can access all affiliates within the specified groups. 
    If no groups are specified, the manager can access all affiliates in the system.
  * If disabled (false), the manager can only access affiliates they directly manage within the specified groups.
    If no groups are specified, access is limited to affiliates they directly manage`,
  balance_dates: 'Balance Dates',
  payout_dates: 'Payout Dates',
  total_pending: 'Total Pending',
  total_available: 'Total Available',
  requireInviteForRegistration: 'Require Invite For Registration',
  qftd_commission: 'QFTD Amount',
  commissions_amount: 'Commissions Amount',
  conversions_count: 'Conversions Count',
  allow_deep_link: 'Allow Deep Link',
  allow_deep_link_helper:
    ' When enabled, a deep link option will be available.',
  requireInviteForRegistration_helper:
    'Only affiliates who use the MLM Referral URL can sign up. The MLM feature must be activated for the MLM Referral URL to be displayed to affiliates.',
  add_items: 'Add Items',
  edit_items: 'Edit Items',
  allowAffiliateTrackingDomains: 'Allow Affiliate to add Parking Domains',
  thumbnail: 'Thumbnail',
  deposit_sum: 'Deposit Sum',
  deposit_count: 'Deposit Count',
  dateField: 'Date Field',
  qualification_date: 'Qualification Date',
  bright_color_confirmation_text:
    'One of the primary colors is quite bright, which may affect the UI. Please review the site with the new colors to ensure usability.',
  min_net_deposit: 'Net Deposit',
  date_picker: 'Date Picker',
  withdrawal_count: 'Withdrawal Count',
  withdrawal_sum: 'Withdrawal Sum',
  transfer_affiliates: 'Transfer Affiliates',
  choose_manager_to_migrate: 'Choose Manager To Transfer Affiliates'
};
