import { useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import useSettings from 'src/hooks/useSettings';
import { companySelector } from 'src/selectors/selectors';
// eslint-disable-next-line camelcase
import casino_en from '../lang/casino_en';
import messages_en from '../lang/en';
import finance_en from '../lang/finance_en';
// eslint-disable-next-line camelcase
import { SiteTypeEnum } from 'src/components/guards/BrokerGuard';
import { mergeTranslations, replaceTranslations } from 'src/utils/utils';
import messages_es from '../lang/es';
import messages_iw from '../lang/iw';
import messages_pl from '../lang/pl';
import messages_ru from '../lang/ru';
import messages_se from '../lang/se';

const defualtMessages = {
  iw: messages_iw,
  es: messages_es,
  se: messages_se,
  ru: messages_ru,
  pl: messages_pl
};
const casinoMessages = {
  en: casino_en
};
const financeMessages = {
  en: finance_en
};

function TranslationProvider({ children }) {
  const { settings } = useSettings();

  const { siteType } = useSelector(companySelector);

  const messages = useMemo(
    () =>
      replaceTranslations(
        mergeTranslations(messages_en, defualtMessages),
        siteType == SiteTypeEnum.CASINO
          ? casinoMessages
          : siteType == SiteTypeEnum.BROKER
          ? financeMessages
          : {}
      ),
    [siteType]
  );

  const langMessages = useMemo(() => messages[settings.lang], [
    messages.en,
    settings.lang
  ]);

  return (
    <IntlProvider locale={settings.lang} messages={langMessages}>
      {children}
    </IntlProvider>
  );
}
export default TranslationProvider;
